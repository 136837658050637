
import {VEHICLE_CATEGORY, VEHICLE_CLASS} from "../constants/enums";
import {getTaxiVehicleOptions, getVehicleColors} from "../constants/keyValueOptions";

export function parseVehicle(carClass, carCategory) {
    const taxiVehicleOptions = getTaxiVehicleOptions();

    const category = taxiVehicleOptions[VEHICLE_CATEGORY[carCategory]];
    if (!category) return; // return undefined if category does not exist

    const car = category.find(vehicle => vehicle.title === VEHICLE_CLASS[carClass]);
    if (!car) return; // return undefined if car does not exist in category

    return car;
}

export function getVehicleSeatingCapacity(carClass, carCategory) {
    return parseVehicle(carClass, carCategory)?.people;
}

export const getTranslatedTitle = (l10nTextCarTypes, title) => {
    return l10nTextCarTypes[title.toLowerCase()] || title;
};

export function getCarImage(carClass, carCategory, isSelected) {
    let car = parseVehicle(carClass, carCategory);
    return isSelected ? car?.imageSelected : car?.image;
}

export function getCarMapMarkerImage(carClass, carCategory, isSelected) {
    let car = parseVehicle(carClass, carCategory);
    return isSelected ? car?.imageSelected_small : car?.image_small;
}

export const getVehicleImageHeight = (vehicleType) => {
    switch (vehicleType) {
        case VEHICLE_CLASS.VAN:
            return 70;
        default:
            return 80;
    }
}

export const getVehicleMarginTop = (vehicleType) => {
    switch (vehicleType) {
        case VEHICLE_CLASS.VAN:
            return -12;
        default:
            return 0;
    }
}

export const isVehiclePremiumClass = (vehicle) => {
    return vehicle.category === VEHICLE_CATEGORY.PREMIUM
}

export const isVehicleStandardClass = (vehicle) => {
    return vehicle.category === VEHICLE_CATEGORY.STANDARD
}

export const getColorByValue = (value) => {
    const vehicleColors = getVehicleColors();
    const colorObject = vehicleColors.find(color => color.value === value);
    return colorObject ? colorObject.color : '#FFFFFF'; // Default to white if not found
};

export const isDriverObjectReady = (driver) => {
    if (!driver) {
        return false;
    }

    const {location, vehicles} = driver;
    const coordinates = location?.coordinates;

    if (!vehicles || vehicles.length === 0) {
        return false;
    }
    if (!coordinates || coordinates.latitude === null || coordinates.longitude === null) {
        return false;
    }
    return true;
}

