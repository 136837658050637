import React, { useState, useEffect } from 'react';
import '../css/PageTabs.css';
import {getLocalisedTexts} from "../../l10n/localisations/languages";

function PageTabs({ tabs, initialTab, onTabChange, pageContext }) {
    const [currentTab, setCurrentTab] = useState(initialTab || tabs[0]);

    useEffect(() => {
        if (onTabChange) {
            onTabChange(currentTab);
        }
    }, [currentTab, onTabChange]);

    const handleTabClick = (tab) => {
        setCurrentTab(tab);
    };

    // Correctly get the localized texts using your method
    const l10nText = getLocalisedTexts().PAGE_TABS[pageContext] || {};

    return (
        <div className="tabs-container">
            {tabs.map((tab) => (
                <div
                    key={tab}
                    className={`tab-item ${currentTab === tab ? 'tab-selected' : ''}`}
                    onClick={() => handleTabClick(tab)}
                >
                    {l10nText[tab] || tab}
                </div>
            ))}
        </div>
    );
}

export default PageTabs;
