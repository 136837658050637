import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import PageWrapper from '../components/js/PageWrapper';
import Header from "../components/js/Header";
import reviewsApi from "../apis/reviewsApi";
import Reviews from '../components/js/Reviews';
import useWindowDimensions from '../helpers/useWindowDimensions';
import PageTabs from '../components/js/PageTabs';
const ReviewsPage = () => {
    const [reviewsDrivers, setReviewsDrivers] = useState([]);
    const [reviewsCustomers, setReviewsCustomers] = useState([]);
    const tabs = ['DRIVERS', 'CUSTOMERS'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };
    console.log('reviews page')

    useEffect(() => {
        async function fetchData() {
            let reviews = await reviewsApi.getReviews()
            console.log("reviews", reviews)
            let reviewsDrivers = reviews.filter(r => r.author.user_role === 'PERSONAL')
            let reviewsCustomers = reviews.filter(r => r.author.user_role === 'DRIVER')
            setReviewsDrivers(reviewsDrivers)
            setReviewsCustomers(reviewsCustomers)
        }
        fetchData().then(r => () => {})
    }, []);

    return (
        <PageWrapper>
            <div className={'body'} style={{ paddingLeft: 0 }}>
                <Header title={`NADZORNA PLOŠČA / REVIEWS`} />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="FEEDBACKS"
                />
                {currentTab === 'DRIVERS' && (
                    
                        <Reviews reviews={reviewsDrivers} />
                    
                )}
                {currentTab === 'CUSTOMERS' && (
                    
                        <Reviews reviews={reviewsCustomers} />
                    
                )}
                
            </div>
        </PageWrapper>
    );
};

export default ReviewsPage;