import React from 'react';

const DriverBasicInfo = ({ driverData }) => {
    if (!driverData) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <h3>Basic Information</h3>
            <p><strong>Name:</strong> {driverData.user.first_name} {driverData.user.last_name}</p>
            <p><strong>Email:</strong> {driverData.user.email}</p>
            <p><strong>Phone:</strong> {driverData.user.phone_number}</p>
            <p><strong>Vehicle:</strong> {driverData.vehicles[0]?.make} {driverData.vehicles[0]?.model}</p>
            <p><strong>License Plate:</strong> {driverData.vehicles[0]?.license_plate}</p>
        </div>
    );
};

export default DriverBasicInfo;
