import React, { useState, useEffect} from 'react';
import { getLocalisedTexts } from "../../../l10n/localisations/languages";
import DynamicTable from "../DynamicTable";
import { renderDriversListDataSquares } from "../DataSquare";
import { Link, useParams } from "react-router-dom";
import driverApi from "../../../apis/driverApi";
import colors from "../../../constants/colors";
import taxiApi from "../../../apis/taxiApi";
import {TAXI_ORDER_STATUS} from "../../../constants/enums";

const DriversList = () => {
    const l10nText = getLocalisedTexts('DRIVER_LIST');
    const [drivers, setDrivers] = useState([]);
    const [filteredDrivers, setFilteredDrivers] = useState([]);
    const [onlineDrivers, setOnlineDrivers] = useState(0);
    const [registeredDrivers, setRegisteredDrivers] = useState(0);

    const [completedDrives, setCompletedDrives] = useState(0)
    const [amountEarnedToday, setAmountEarnedToday] = useState(0)

    const onlinePercentage = ((onlineDrivers / registeredDrivers) * 100).toFixed(0);

    const fetchTaxiOrders = async () => {
        try {
            const orders = await taxiApi.getTaxiOrders();
            if (orders && !orders.error) {
                const completedOrders = orders.filter(order => order.status === TAXI_ORDER_STATUS.COMPLETED)
                let todaysRides = 0
                let todaysEarnings = 0;

                const now = new Date();

                completedOrders.forEach(order => {
                    const timeline = order.timeline.find(item => item.status === TAXI_ORDER_STATUS.COMPLETED);
                    const timestamp = timeline?.location?.timestamp;

                    if (timestamp) {
                        const orderDate = new Date(timestamp);
                        const daysDiff = Math.floor((now - orderDate) / (1000 * 60 * 60 * 24));
                        const orderAmount = parseFloat(order.payment.price) || 0;
                        if (daysDiff === 0) {
                            todaysEarnings += orderAmount;
                            todaysRides += 1
                        }
                    }
                });
                setCompletedDrives(todaysRides);
                setAmountEarnedToday(todaysEarnings);
            } else {
                setAmountEarnedToday(0);
            }
        } catch (error) {
            console.error('Error fetching taxi orders:', error);
            setAmountEarnedToday(0);
        }
    };

    const fetchOnlineDrivers = async () => {
        try {
            const drivers = await driverApi.listOnlineDrivers();
            if (drivers && !drivers.error) {
                setFilteredDrivers(drivers);
                setOnlineDrivers(drivers.length);
            } else {
                setDrivers([]);
                setOnlineDrivers(0);
            }
        } catch (e) {
            console.error(e);
            setOnlineDrivers(0);
        }
    };

    const fetchRegisteredDrivers = async () => {
        try {
            const drivers = await driverApi.listDrivers();
            if (drivers && !drivers.error) {
                setDrivers(drivers);
                setRegisteredDrivers(drivers.length);
            } else {
                setDrivers([]);
                setRegisteredDrivers(0);
            }
        } catch (e) {
            console.error(e);
            setRegisteredDrivers(0);
        }
    };

    useEffect(() => {
        fetchOnlineDrivers().then(() => console.log("Online drivers fetched."));
        fetchRegisteredDrivers().then(() => console.log("All drivers fetched."));
        fetchTaxiOrders().then(() => console.log("Taxi orders fetched."));

        const intervalId = setInterval(() => {
            fetchRegisteredDrivers();
            fetchTaxiOrders()
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const columnsConfig = {
        online: l10nText.activity || 'Online',
        driver: l10nText.driver || 'Driver',
        vehicle: l10nText.vehicle_make_model || 'Vehicle Make/Model',
        vehicle_color: l10nText.vehicle_color || 'Vehicle Color',
        license_plate: l10nText.vehicle_license_plate || 'License Plate',
        status: l10nText.status || 'Status',
        vehicle_category: l10nText.class || 'Category of Driver',
        edit: l10nText.edit || 'Edit',
    };

    const parseDriverData = drivers.map(driver => ({
        online: (
            <span className={`status-dot ${driver?.online ? 'online' : 'offline'}`} />
        ),
        driver: `${driver?.user?.first_name} ${driver?.user?.last_name}`,
        vehicle: `${driver?.vehicles[0]?.make} ${driver?.vehicles[0]?.model}`,
        vehicle_color: driver?.vehicles[0]?.color,
        license_plate: driver?.vehicles[0]?.license_plate,
        status: (
            <div
                style={{
                    backgroundColor: !driver?.online ? colors.red : driver?.on_order ? '#28a745' : '#d5b715',
                    borderRadius: '8px',
                    textAlign: 'center',
                    padding: '2px 8px',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: "13px"
                }}
            >
                {!driver?.online ? 'Offline' : driver?.on_order && driver?.online ? "On Ride" : "Waiting"}
            </div>
        ),
        vehicle_category: driver?.vehicles[0]?.vehicle_category || 'Standard',
        edit: <Link to={`/driver/${driver.driver_id}`}>Edit</Link>,
    }));

    return (
        <div>
            <div className="list-header">
                <h3>{l10nText.title || 'Drivers List'}</h3>
                {renderDriversListDataSquares(l10nText, onlineDrivers, registeredDrivers, onlinePercentage, completedDrives, amountEarnedToday)}
            </div>
            <DynamicTable
                data={parseDriverData}
                columnsConfig={columnsConfig}
                rowsPerPage={8}
            />
        </div>
    );
};

export default DriversList;
