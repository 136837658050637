import axios from "../lib/axios";


const getDocumentsByDriverId = async (driver_id) => {
    try {
        const resp = await axios.get(`/documents/drivers/${driver_id}`);
        return resp.data;
    } catch (err) {
        return err;
    }
};


const DocumentApi = {
    getDocumentsByDriverId
}

export default DocumentApi;