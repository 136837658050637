export const setSessionStorageData = (onSetSessionAction) => {
    try {
        onSetSessionAction();
    } catch (error) {
        console.error("Error saving data!" + error);
    }
};

export const loadSessionStorageData = (key, onLoadSessionAction) => {
    try {
        const value = sessionStorage.getItem(key);
        console.log(`GET [SESSION STORAGE] ${key}:`, value);
        if (onLoadSessionAction) {
            onLoadSessionAction(value);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    } catch (error) {
        console.error("Error saving data!" + error);
        return null;
    }
};

export const loadLocalStorageData = (key, onLoadSessionAction) => {
    try {
        const value = localStorage.getItem(key);
        console.log(`GET [LOCAL STORAGE] ${key}:`, value);
        if (onLoadSessionAction) {
            onLoadSessionAction(value);
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    } catch (error) {
        console.error("Error getting data!" + error);
        return null;
    }
};