import {store} from "../../store/configureStore";
import { languageTexts } from "./texts";

export const getLocalisedTexts = (routeName) => {
    const langSelected = store.getState().user.language;
    const texts = languageTexts[langSelected ? langSelected?.toLowerCase() : 'en'];

    if (!texts) {
        console.error(`Localization texts not found for language: ${langSelected}`);
        return languageTexts['en']; // Fallback to English if the selected language texts are not found
    }
    return routeName ? texts[routeName] : texts;
};

export const getSelectedLanguage = () => {
    const langSelected = store.getState().user.language;
    return langSelected ? langSelected?.toLowerCase() : 'en';
};