import React from "react";

import '../css/Review.css';
import Card from "./Card";
import Avatar from '../../assets/img/avatar.jpg';
import RateFull from '../../assets/img/rate_full.png';
import RateHalf from '../../assets/img/rate_half.png';
import RateEmpty from '../../assets/img/rate_empty.png';

import moment from "moment";

function getStars(rating) {
    const maxStars = 5;
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = maxStars - fullStars - halfStars;

    return {
        full: fullStars,
        half: halfStars,
        empty: emptyStars
    };
}

function displayStars(rating) {
    const stars = getStars(rating);
    const starElements = [];

    for (let i = 0; i < stars.full; i++) {
        starElements.push(RateFull); // or you could use an icon/image
    }

    for (let i = 0; i < stars.half; i++) {
        starElements.push(RateHalf);
    }

    for (let i = 0; i < stars.empty; i++) {
        starElements.push(RateEmpty);
    }

    return starElements;
}
// {
//     "enjoyed": null,
//     "opinion": "",
//     "order_id": "16b430f8-e0d5-43f2-9218-324840ea15a7",
//     "punctuality": 0,
//     "drivingSkills": 0,
//     "customerService": 0,
//     "professionalism": 0,
//     "vehicleCondition": 0,
//     "knowledgeOfRoutes": 0
//   }

// {
//     "opinion": "",
//     "order_id": "f483918d-08f5-4012-8b61-6884ab98b373",
//     "punctuality": 5,
//     "customer_behaviour": 5,
//     "overall_experience": true
//   }
function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }
function Review(props) {
    const {review} = props;
    console.log(review);
    let isReviewingDriver = review.author.user_role === "PERSONAL";
    let startsObj;
    if (review.feedback === null) return null
    if (isReviewingDriver) {
        startsObj = {
            punctuality: displayStars(review.feedback.punctuality),
            drivingSkills: displayStars(review.feedback.drivingSkills),
            customerService: displayStars(review.feedback.customerService),
            professionalism: displayStars(review.feedback.professionalism),
            vehicleCondition: displayStars(review.feedback.vehicleCondition),
            knowledgeOfRoutes: displayStars(review.feedback.knowledgeOfRoutes)
        }
    } else {
        startsObj = {
            punctuality: displayStars(review.feedback.punctuality),
            customer_behaviour: displayStars(review.feedback.customer_behaviour),
            overall_experience: displayStars(review.feedback.overall_experience)
        }
    }

    const stars = displayStars(review.rating);
    return <Card>
        <div className={"review"} style={props.style}>
            <div className={"review-header"}>
                <h4>{isReviewingDriver ? "Customer reviewed" : "Driver reviewed"} {review.target.name ? review.target.name : `${review.target.first_name} ${review.target.last_name}`}</h4>
                <div className={"reviewer"}>
                    <img src={review.author.avatar || Avatar} alt={review.author.first_name + " " + review.author.last_name} className={"avatar"}/>
                    <div className={"reviewer-info"}>
                        <div className={"name"}>{review.author.first_name + " " + review.author.last_name}</div>
                        <div className={"date"}>{moment(review.created_at).format("DD MMM YYYY")}</div>
                    </div>
                    <div className={"ratings"}>
                        <div className={"rating"}>
                               <div className={"rating-title"}> 
                                    <p>General rating</p>
                               </div>
                               <div className={"rating-wrap"}>
                                    <div className={"rate"}>{review.rating || 0}/5</div>
                                    <div className={"stars"}>
                                        {stars.map((star, index) => {
                                            return <img key={index} src={star} alt={"star"}/>
                                        })}
                                    </div>
                               </div>
                              
                            </div>
                        {Object.keys(startsObj).map((key, index) => {
                            return <div key={index} className={"rating"}>
                               <div className={"rating-title"}> 
                                    <p>{titleCase(key.replace(/_/g, " "))}</p>
                               </div>
                               <div className={"rating-wrap"}>
                                    <div className={"rate"}>{review.feedback[key] !== null ? review.feedback[key] : 0}/5</div>
                                    <div className={"stars"}>
                                        {startsObj[key].map((star, index) => {
                                            return <img key={index} src={star} alt={"star"}/>
                                        })}
                                    </div>
                               </div>
                              
                            </div>
                        })}
                    </div>
                </div>
               
            </div>
            <div className={"review-content"}>{review.comment}</div>
            <div className={"review-footer"}>
                
            </div>
        </div>
    </Card>
}

export default Review;