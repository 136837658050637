import React from "react";
import {
    USER_ROLE,
    XLSX_BARS_FIELDS,
    XLSX_CATEGORIES_FIELDS,
    XLSX_PRODUCTS_FIELDS,
    XLSX_USERS_FIELDS
} from "../constants/enums";
import {L10N} from "../l10n/l10n";

import countryEN from "../assets/img/country_en.png";
import countrySI from "../assets/img/country_si.png";

export const saveToStorage = (storage, key, value) => {
    try {
        storage.setItem(key, value);
        console.log(`SAVE [STORAGE] ${key}:`, value);
    } catch (error) {
        console.error("Error saving data!", error);
    }
};

export const loadFromStorage = (storage, key) => {
    try {
        const value = storage.getItem(key);
        console.log(`LOAD [STORAGE] ${key}:`, value);
        return value;
    } catch (error) {
        console.error("Error loading data!", error);
        return null;
    }
};


export const validateUserInput = (params) => {
    for (let i = 0; i < params.length; i++) {
        console.log(JSON.stringify(params[i], null, 4))
        if (params[i] === null || params[i] === '') {
            return false;
        }
    }
    return true;
};

export function stringToNumber(s){
    if (!s) {
        return s;
    }
    if (!s?.includes(",")) {
        return s;
    }
    return +s.split(',').join('.');
}

export const validateUserInputNumerical = (params) => {
    for (let i = 0; i < params.length; i++) {
        let param = parsePostDecimalValue(params[i])
        console.log(JSON.stringify(param, null, 4))
        if (isNaN(param)) {
            return false;
        }
    }
    return true;
};

export const parseDateForDateInput = (timestamp) => {
    if (!timestamp) {
        return null
    }
    console.log(timestamp)

    let date = new Date(timestamp);
    let day = date.getDate();
    day = day.toString().length < 2 ? `0${day}` : day;

    let month = date.getMonth() + 1;
    month = month.toString().length < 2 ? `0${month}` : month;

    let year = date.getFullYear();

    //console.log(`${day}/${month}/${year}`);
    return `${day}/${month}/${year}`;
    // console.log(`${month}/${day}/${year}`);
    // return `${month}/${day}/${year}`;
};

export const displayDate = (timestamp) => {
    if (!timestamp) {
        return null
    }
    //console.log(timestamp)

    let date = new Date(timestamp);
    let day = date.getDate();
    day = day.toString().length < 2 ? `0${day}` : day;

    let month = date.getMonth() + 1;
    month = month.toString().length < 2 ? `0${month}` : month;

    let year = date.getFullYear();

    //console.log(`${day}.${month}.${year}`);
    return `${day}.${month}.${year}`;
};

export const displayDateUTC = (timestamp) => {
    if (!timestamp) {
        return null;
    }

    const dateParts = timestamp.split("T")[0].split("-");

    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    return `${day}.${month}.${year}`;
};

export const displayDateHour = (timestamp) => {
    if (!timestamp) {
        return null
    }
    //console.log(timestamp)

    let date = new Date(timestamp);
    let day = date.getDate();
    day = day.toString().length < 2 ? `0${day}` : day;

    let month = date.getMonth() + 1;
    month = month.toString().length < 2 ? `0${month}` : month;

    let year = date.getFullYear();

    let hour = date.getHours();
    let minutes = date.getUTCMinutes();
    let seconds = date.getUTCSeconds();

    hour = hour.toString().length < 2 ? `0${hour}` : hour;
    minutes = minutes.toString().length < 2 ? `0${minutes}` : minutes;
    seconds = seconds.toString().length < 2 ? `0${seconds}` : seconds;

    //console.log(`${day}.${month}.${year}`);
    return `${day}.${month}.${year} | ${hour}:${minutes}:${seconds}`;
};


export const parseRestApiResponse = (response, resData, dispatchAction, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    if (Number(response.status) >= 500) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 400) {
        console.log("GET DATA ERROR", response.status, resData.error);
        if (onErrorUnauthorized) {
            onErrorUnauthorized();
        } else if (onErrorErrorFetchingData) {
            onErrorErrorFetchingData();
        }
    } else if (Number(response.status) >= 200) {
        //console.log("GET DATA SUCCESS", response.status, resData.error);
        if (dispatchAction) {
            dispatchAction().then(() => {
                if (onSuccessAction) {
                    onSuccessAction();
                }
            });
        }
    }
};

export const hasItem = (field, returnField, value, array) => {
    if (!array) {
        return false
    }

    for (let i = 0; i < array.length; i++) {
        if (array[i][field]?.toString() === value?.toString()) {
            return array[i][returnField];
        }
    }


    return null;
};

export const findItem = (field, value, array) => {
    if (!array) {
        return null
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return array[i];
        }
    }
    return null;
};

export const getIndexOf = (field, value, array) => {
    if (!array) {
        return -1
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i][field] === value) {
            return i;
        }
    }
    return -1;
};

export const getIndexForNthCharOccurrence = (string, subString, index) => {
    if (!string) {
        return -1
    }
    return string.split(subString, index).join(subString).length;
}

export const parseSelectedObjectValues = (list, searchField) => {
    if (!list || list.length === 0) {
        return null;
    }
    let arr = []
    for (let i = 0; i < list.length; i++) {
        if (list[i][searchField]) {
            arr.push(list[i][searchField]);
        }
    }
    return arr;
}

export const parseSelectionInvertedObjectValues = (listAll, list, searchField) => {
    let parsed = parseSelectedObjectValues(list, searchField);

    if (!parsed || parsed.length === 0) {
        parsed = [];
    }
    let arr = []
    for (let i = 0; i < listAll.length; i++) {
        let found = false;
        for (let j = 0; j < parsed.length; j++) {
            console.log(parsed[j] === listAll[i][searchField], parsed[j], listAll[i][searchField]);
            if (parsed[j] === listAll[i][searchField]) {
                found = true
                break;
            }
        }
        if (!found) {
            arr.push(listAll[i][searchField]);
        }
    }

    return arr;
}

export const parseUserRole = (userRole) => {
    switch (userRole) {
        case 'ADMIN':
            return USER_ROLE.ADMIN;
        case 'DRIVER':
            return USER_ROLE.DRIVER;
        default:
            console.warn(`Error parsing user role... User role '${userRole}' is Ineligible!`);
            return null;
    }
};

export const isSessionValid = (token) => {
    if (!token) {
        console.log('redirect');
        return false;
    }
    return true;
}


export const isNewObjValidCheck = (obj, requiredFields, checkNumeric) => {
    let response = {
        isValid: true,
        invalidFields: [],
        errorMessages: []
    }
    // check for required fields
    for (const requiredField in requiredFields) {
        if (!obj[requiredField] || obj[requiredField] === '') {
            response.isValid = false;
            response.invalidFields.push(requiredField);
            response.errorMessages.push('Manjka vrednost v polju: ' + requiredFields[requiredField]);
        }
    }

    if (checkNumeric) {
        // check for correct numerical input
        if (!validateUserInputNumerical([obj.alcohol_percent])) {
            response.isValid = false;
            response.invalidFields.push('alcohol_percent');
            response.errorMessages.push('Neveljaven vnos podatkov! Stopnja alkohola mora biti decimalno število podano brez enote.');
        }
    }

    return response
}

export const parseFirstName = (fullName) => {
    if (!fullName) {
        return '';
    }
    return fullName.split(' ')[0]
}

export const displayDecimalValue = (price) => {
    if (price === null || price === undefined) {
        return '';
    }
    return price.toString().replace(".", ",");
}

export const parsePostDecimalValue = (price) => {
    return price.toString().replace(",", ".");
}

export const displayPrice = (price) => {
    return displayDecimalValue(Number(price).toFixed(2));
}


export const displayAlert = (message) => {
    return alert(message);
};

export const validatePriceUpdateAction = (price, updateProductPriceMenuAction) => {
    let pricePostFormat = parsePostDecimalValue(price);
    if (price && price !== '' && Number(pricePostFormat) >= 0) {
        updateProductPriceMenuAction();
    } else if (price !== '' && (!Number(parsePostDecimalValue(price)) || Number(pricePostFormat) < 0)) {
        displayAlert("Vpiši veljavno ceno v EUR (brez €)!");
    }
}
export const isUserCaretaker = (user) => {
    return user && parseUserRole(user.type) === USER_ROLE.CARETAKER;
}
export const isUserAdmin = (user) => {
    return user && parseUserRole(user.type) === USER_ROLE.ADMIN;
}
export const isUserOwner = (user) => {
    return user && parseUserRole(user.type) === USER_ROLE.OWNER;
}
export const isUserBarRepresentative = (user) => {
    return user && parseUserRole(user.type) === USER_ROLE.BAR_REPRESENTATIVE;
}
export const isUserSharepointAdmin = (user) => {
    return user && parseUserRole(user.type) === USER_ROLE.ADMIN;
}

export const displayValidFromMenuFormat = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    console.log("DATE:", date, `${day}. ${month}. ${year}`)
    return `${day}. ${month}. ${year}`;
};

export const setLocalStorageObject = (key, obj) => {
    if (!obj) return
    localStorage.setItem(key, JSON.stringify(obj))
}
export const getLocalStorageObject = (key) => {
    const obj = localStorage.getItem(key)
    if (!obj) return obj
    return JSON.parse(obj)
}


export const isPasswordValid = (password) => {
    const passwordRegex = /^[a-zA-Z0-9]+$/;
    return passwordRegex.test(password);
}


export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (input_str) => {
    let re = /^[a-zA-Z0-9\-().\s]{9,15}$/;

    return re.test(input_str);
}

export const parseSelectedCountryLabel = (country, selectAction, onActionCollapseL10NOptions) => {
    const action = () => {
        selectAction(country)
        onActionCollapseL10NOptions()
    }

    switch (country) {
        case L10N.en.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countryEN} alt="EN" width={'27px'}/>
            </div>
        case L10N.si.value:
            return <div onClick={() => action()}
                        className={'country-label-container'}
                        style={{alignSelf: 'center'}}>
                <img src={countrySI} alt="SI" width={'27px'}/>
            </div>
    }
}

export const parseFullName = (full_name) => {
    if (full_name) {
        if (full_name.indexOf(' ') <= 0) {
            return full_name;
        }
        return full_name.substring(0, full_name.indexOf(' ') + 2) + ".";
    } else {
        return full_name;
    }
}


export const scrollToDiv = (div) => {
    try {
        let id = div;
        const yOffset = -20;
        let element = document.getElementsByClassName(id);
        if (element.length > 0) {
            element = element[0];
        }

        console.log(element, id);

        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        console.log("Scroll to div", element, y + 'px');

        window.scrollTo({top: y, behavior: 'smooth'});
    } catch (e) {
        console.warn(e);
    }
}

export const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
};

/* EXCEL DATA PARSING */
export const parseUserDataExcel = (row) => {
    let user = {
        external_id: null,
        full_name: "",
        address: null,
        username: null,
        password: null,
        type: null,
        telephone: null
    };
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_USERS_FIELDS.EXTERNAL_ID:
                user.external_id = row[i];
                break;
            case XLSX_USERS_FIELDS.FULL_NAME:
                user.full_name = row[i];
                break;
            case XLSX_USERS_FIELDS.ADDRESS:
                user.address = row[i];
                break;
            case XLSX_USERS_FIELDS.TELEPHONE:
                user.telephone = row[i];
                break;
            case XLSX_USERS_FIELDS.EMAIL:
                user.username = row[i];
                break;
            case XLSX_USERS_FIELDS.USER_ROLE:
                user.type = row[i];
                break;
            default:
                console.warn('Something went wrong.. To many columns found!');
        }
    }
    return user;
};

export const parseBarDataExcel = (row, users, caretakers) => {
    let bar = {
        external_id: null,
        name: null,
        address: null,
        bar_owner_id: null,
        caretaker_id: null,
        tax_number: null,
        user_id: null,
        qr_code: null,
        legal_entity: null
    };
    let street = '';
    let street_nmb = '';
    let post_name = '';
    let post_nmb = '';
    let region = '';
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_BARS_FIELDS.EXTERNAL_ID:
                bar.external_id = row[i] ? row[i].toString() : '';
                break;
            case XLSX_BARS_FIELDS.NAME:
                bar.name = row[i] ? row[i].toString() : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_STREET:
                street = row[i] ? row[i] : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_STREET_NMB:
                street_nmb = row[i] ? row[i].toString() : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_POST_NUMBER:
                post_nmb = row[i] ? row[i].toString() : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_POST:
                post_name = row[i] ? row[i] : '';
                break;
            case XLSX_BARS_FIELDS.ADDRESS_REGION:
                region = row[i] ? row[i] : '';
                break;
            case XLSX_BARS_FIELDS.TAX_NUMBER:
                bar.tax_number = row[i] ? row[i].toString() : '';
                if (bar.tax_number === "") {
                    throw new Error('Napaka, prazno polje: davčna št.');
                }
                break;
            case XLSX_BARS_FIELDS.LEGAL_ENTITY:
                bar.legal_entity = row[i] ? row[i].toString() : '';
                break;
            case XLSX_BARS_FIELDS.EMAIL_CARETAKER:
                let user = findItem("username", row[i].toLowerCase(), users)
                try {
                    bar.caretaker_id = findItem("user_id", user.user_id, caretakers).caretaker_id;
                } catch (e) {
                    if (e.toString().includes('TypeError: Cannot read properties of null')) {
                        throw new Error('Napaka, potnik s tem e-mailom ne obstaja..');
                    }
                }
                break;
            case XLSX_BARS_FIELDS.EMAIL_PERSONNEL:
                try {
                    bar.user_id = row[i] && row[i] !== '' ? findItem("username", row[i], users).user_id : null;
                } catch (e) {
                    if (e.toString().includes('TypeError: Cannot read properties of null')) {
                        throw new Error('Napaka, vodja s tem e-mailom ne obstaja..');
                    }
                }
                break;
            case XLSX_BARS_FIELDS.EMAIL_OWNER:
                try {
                    if (!row[i] || row[i] === '' || row[i] === "") {
                        bar.bar_owner_id = null;
                    } else {
                        let user = findItem("username", row[i].toLowerCase(), users);
                        bar.bar_owner_id = row[i] && row[i] !== '' ? findItem("user_id", user.user_id, caretakers).caretaker_id : null;
                    }
                } catch (e) {
                    if (e.toString().includes('TypeError: Cannot read properties of null')) {
                        throw new Error('Napaka, lastnik s tem e-mailom ne obstaja..');
                    }
                }
                break;
            default:
                console.warn('Something went wrong.. To many columns found!');
        }
    }

    bar.address = street + ' ' + street_nmb + ', ' + post_name + ' ' + post_nmb;
    return bar;
};

export const parseProductDataExcel = (row) => {
    let product = {
        external_id: null,
        name: null,
        initial_price: 0,
        bar_id: null,
        picture: null,
        slo_descr: null,
        eng_descr: null,
        eng_name: null,
        de_descr: null,
        de_name: null,
        it_descr: null,
        it_name: null,
        hr_descr: null,
        hr_name: null,
        packaging: null,
        measure_unit: null,
        allergenes: null,
        alcohol_percent: null,
        combo_offer: null,
        category_label: null,
        own_product: null
    };
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_PRODUCTS_FIELDS.EXTERNAL_ID:
                product.external_id = row[i]; //.toString();
                break;
            case XLSX_PRODUCTS_FIELDS.NAME:
                product.name = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.CATEGORY_LABEL:
                product.category_label = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.PACKAGING:
                product.packaging = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.MEASURE_UNIT:
                product.measure_unit = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.ALERGENS:
                product.allergenes = row[i];
                console.log("ALGNS..", row[i])
                break;
            case XLSX_PRODUCTS_FIELDS.ALCO_PERCENTAGE:
                product.alcohol_percent = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.COMBO_OFFER:
                product.combo_offer = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.SLO_DESC:
                product.slo_descr = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.EN_NAME:
                product.eng_name = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.EN_DESC:
                product.eng_descr = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.DE_NAME:
                product.de_name = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.DE_DESC:
                product.de_descr = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.IT_NAME:
                product.it_name = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.IT_DESC:
                product.it_descr = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.HR_NAME:
                product.hr_name = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.HR_DESC:
                product.hr_descr = row[i];
                break;
            case XLSX_PRODUCTS_FIELDS.OWN_PRODUCT:
                product.own_product = row[i];
                break;
            default:
                console.warn(`Skipping parsing of column ${i}..`);
        }
    }
    return product;
};

export const parseCategoryDataExcel = (row) => {
    let category = {
        name: null,
        position: null,
        eng_name: null,
        de_name: null,
        it_name: null,
        hr_name: null
    };
    for (let i = 0; i < row.length; i++) {
        switch (i) {
            case XLSX_CATEGORIES_FIELDS.NAME:
                category.name = row[i];
                break;
            case XLSX_CATEGORIES_FIELDS.POSITION:
                category.position = row[i];
                break;
            case XLSX_CATEGORIES_FIELDS.EN_NAME:
                category.eng_name = row[i];
                break;
            case XLSX_CATEGORIES_FIELDS.DE_NAME:
                category.de_name = row[i];
                break;
            case XLSX_CATEGORIES_FIELDS.IT_NAME:
                category.it_name = row[i];
                break;
            case XLSX_CATEGORIES_FIELDS.HR_NAME:
                category.hr_name = row[i];
                break;
            default:
                console.warn('Something went wrong.. To many columns found!');
        }
    }
    return category;
};