import React, { useState, useEffect } from 'react';
import { getLocalisedTexts } from "../../../l10n/localisations/languages";
import DynamicTable from "../DynamicTable";
import { renderDriversListDataSquares } from "../DataSquare";
import deliveryDriverApi from "../../../apis/deliveryDriverApi";
import { getColorByValue } from "../../../lib/dataParsingHelpers";
import deliveryApi from "../../../apis/deliveryApi";
import {DELIVERY_ORDER_STATUS} from "../../../constants/enums";

const DeliveryDriversList = () => {
    const l10nText = getLocalisedTexts('DRIVER_LIST');
    const [driversList, setDriversList] = useState([]);
    const [filteredDrivers, setFilteredDrivers] = useState([]);
    const [onlineDrivers, setOnlineDrivers] = useState(0);
    const [registeredDrivers, setRegisteredDrivers] = useState(0);

    const [completedDrives, setCompletedDrives] = useState(0)
    const [amountEarnedToday, setAmountEarnedToday] = useState(0)
    const onlinePercentage = ((onlineDrivers / registeredDrivers) * 100).toFixed(0);

    const fetchDeliveryOrders = async () => {
        try {
            const orders = await deliveryApi.getDeliveryOrders()
            if (orders && !orders.error) {
                const completedOrders = orders.filter(order => order.status === DELIVERY_ORDER_STATUS.COMPLETED)

                const now = new Date();

                let todaysEarnings = 0;
                let completedRidesToday = 0;

                completedOrders.forEach(order => {
                    const timeline = order.timeline.find(item => item.status === DELIVERY_ORDER_STATUS.COMPLETED);
                    const timestamp = timeline?.location?.timestamp;

                    if (timestamp) {
                        const orderDate = new Date(timestamp);
                        const daysDiff = Math.floor((now - orderDate) / (1000 * 60 * 60 * 24));
                        const orderAmount = (parseFloat(order.details.delivery_cost) + (parseFloat(order.details.delivery_earnings) || 0)) || 0;

                        if (daysDiff === 0) {
                            completedRidesToday += 1;
                            todaysEarnings += orderAmount;
                        }
                    }
                });

                setAmountEarnedToday(todaysEarnings.toFixed(2));
                setCompletedDrives(completedRidesToday);
            }
        } catch (error) {
            console.error('Error fetching delivery orders:', error);
        }
    };


    const fetchOnlineDrivers = async () => {
        try {
            const drivers = await deliveryDriverApi.listOnlineDrivers();
            if (drivers && !drivers.error) {
                setFilteredDrivers(drivers);
                setOnlineDrivers(drivers.length);
            } else {
                setOnlineDrivers(0);
            }
        } catch (e) {
            console.error(e);
            setOnlineDrivers(0);
        }
    };

    const fetchRegisteredDrivers = async () => {
        try {
            const drivers = await deliveryDriverApi.listDeliveryDrivers();
            if (drivers && !drivers.error) {
                setDriversList(drivers);
                setRegisteredDrivers(drivers.length);
            } else {
                setDriversList([]);
                setRegisteredDrivers(0);
            }
        } catch (e) {
            console.error(e);
            setRegisteredDrivers(0);
        }
    };

    useEffect(() => {
        fetchOnlineDrivers();
        fetchRegisteredDrivers();
        fetchDeliveryOrders()

        const intervalId = setInterval(() => {
            fetchOnlineDrivers();
            fetchRegisteredDrivers();
            fetchDeliveryOrders()
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const columnsConfig = {
        online: l10nText.activity || 'Online',
        driver: l10nText.driver || 'Driver',
        vehicle: l10nText.vehicle_make_model || 'Vehicle Make/Model',
        vehicle_color: l10nText.vehicle_color || 'Vehicle Color',
        license_plate: l10nText.vehicle_license_plate || 'License plate',
        status: l10nText.status || 'Status',
    };

    const parseDriverData = driversList.map(driver => ({
        online: (
            <span className={`status-dot ${driver?.online ? 'online' : 'offline'}`} />
        ),
        driver: `${driver?.user?.first_name} ${driver?.user?.last_name}`,
        vehicle: `${driver?.vehicles[0]?.make} ${driver?.vehicles[0]?.model}`,
        vehicle_color: getColorByValue(driver?.vehicles[0]?.color),
        license_plate: driver?.vehicles[0]?.license_plate,
        status: driver?.on_order ? "On delivery ride" : "Waiting",
    }));

    return (
        <div>
            <div className="list-header">
                <h3>{l10nText.title || 'Delivery Drivers List'}</h3>
                {renderDriversListDataSquares(l10nText, onlineDrivers, registeredDrivers, onlinePercentage, completedDrives, amountEarnedToday)}
            </div>
            <DynamicTable
                data={parseDriverData}
                columnsConfig={columnsConfig}
                rowsPerPage={8}
            />
        </div>
    );
};

export default DeliveryDriversList;
