// PageWrapper.js
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { isSessionValid } from '../../helpers/helpers';
import { PAGES } from '../../constants/enums';
import Page, { SIDEBAR_WIDTH } from './Page';
import { setSidebarWidth } from "../../store/slices/adminSlice";

const PageWrapper = ({ children }) => {
    let dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const token = user && user.access_token ? user.access_token : null;
    const ref = useRef();
    const [mainHeight, setMainHeight] = useState(window.document.documentElement.scrollHeight);
   
    useEffect(() => {
        const updateHeight = () => {
            setMainHeight(window.document.documentElement.scrollHeight);
        };
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            ref.current?.scrollIntoView();
        }
    }, []);

    if (!isSessionValid(token)) {
        return <Navigate to={PAGES.LOGIN} replace />;
    }

    return (
        <div ref={ref}>
                <Page
                    relative
                    className={'main'}
                    style={{ height: `${mainHeight}px` }}
                    onToggleSidebar={(shown) => {
                        if (shown) {
                            dispatch(setSidebarWidth(SIDEBAR_WIDTH));
                        } else {
                            dispatch(setSidebarWidth(0));
                        }
                    }}
                >
                    {children}
                </Page>
        </div>
    );
};

export default PageWrapper;