import axios from "../lib/axios";

const getAvailableDrivers = async () => {
    try {
        const resp = await axios.get("/drivers/available");
        return resp.data;
    } catch (error) {
        return error;
    }
}

const getActiveTaxi = async (user_id) => {
    try {
        const resp = await axios.get(`/taxi/orders/active/${user_id}`)
        return resp.data;
    } catch (error) {
        return error;
    }
}

const getTaxiOrders = async () => {
    try {
        const resp = await axios.get("/taxi");
        return resp.data;
    } catch (error) {
        return error;
    }
}

const getTaxiOrder = async (orderId) => {
    try {
        const resp = await axios.get(`/taxi/order/${orderId}`);
        return resp.data;
    } catch (error) {
        return false;
    }
}

export const getCompletedTaxiOrdersByDriverId = async (driver_id) => {
    try {
        const resp = await axios.get(`/taxi/orders/completed/${driver_id}`)
        return resp.data;
    } catch (error) {
        return false;
    }
}

const updateTaxiOrderStatus = async (order) => {
    try {
        const resp = await axios.post("/taxi/order/status", order);
        return resp.data;
    } catch (error) {
        return false;
    }
}

const createTaxiOrder = async (order) => {
    try {
        const resp = await axios.post("/taxi/order", order);
        return resp.data;
    } catch (error) {
        return error;
    }
}

const createDispatchTaxiOrder = async (order) => {
    try {
        const resp = await axios.post("/taxi/dispatch-order", order);
        return resp.data;
    } catch (error) {
        return error;
    }
}


const getCompletedTaxiOrdersByUserId = async (user_id) => {
    try {
        const resp = await axios.get(`/taxi/orders/completed/user/${user_id}`)
        return resp.data;
    } catch (error) {
        return false;
    }
}

const updateCompleteTaxiRoute = async (orderId, route) => {
    try {
        const response = await axios.post("/taxi/order/complete_route", { order_id: orderId, route: route });
        return response.data;
    } catch (error) {
        console.error("Error updating order complete route:", error);
        return false;
    }
};

const updateTaxiOrderTimeline = async (orderId, timeline) => {
    try {
        const response = await axios.post("/taxi/order/timeline", { order_id: orderId, timeline: timeline });
        return response.data;
    } catch (error) {
        console.error("Error updating order timeline", error);
        return false;
    }
};

const cancelTaxiOrder = async (order_id, status, cancellation_reason) => {
    try {
        const resp = await axios.post(`/taxi/order/cancel`, { order_id, status, cancellation_reason });
        return resp.data;
    } catch (error) {
        return error;
    }
}


const TaxiApi = {
    getTaxiOrders,
    getCompletedTaxiOrdersByUserId,
    getCompletedTaxiOrdersByDriverId,
    getAvailableDrivers,
    getTaxiOrder,
    getActiveTaxi,
    createTaxiOrder,
    createDispatchTaxiOrder,
    cancelTaxiOrder,
    updateTaxiOrderStatus,
    updateCompleteTaxiRoute,
    updateTaxiOrderTimeline,
}
export default TaxiApi;