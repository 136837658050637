import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import { SocketProvider } from "./socket/SocketProvider";
import './App.css';
import Login from "./pages/Login";
import {PAGES} from "./constants/enums";
import Zones from "./pages/Zones";
import ResetPassword from "./pages/ResetPassword";
import { NavigationProvider } from "./context/NavigationContext";
import {store, persistor} from './store/configureStore'
import DashboardTaxi from "./pages/DashboardTaxi";
import Reviews from "./pages/ReviewsPage";
import DashboardDelivery from "./pages/DashboardDelivery";
import Driver from "./pages/Driver";
import Drivers from "./pages/Drivers";
function App() {
    return (
        <>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SocketProvider config={{}}>
                        <Router>
                            <NavigationProvider>
                                <Routes>
                                    <Route path="/" element={<Login />} index />
                                    <Route path={PAGES.LOGIN} element={<Login />} />
                                    <Route path={PAGES.RESET + '/:id'} element={<ResetPassword />} />
                                    <Route path={PAGES.DASHBOARD_TAXI} element={<DashboardTaxi />} />
                                    <Route path={PAGES.DASHBOARD_DELIVERY} element={<DashboardDelivery />} />
                                    <Route path={PAGES.DRIVERS_LIST} element={<Drivers />} />
                                    <Route path={PAGES.DRIVER + '/:driver_id'} element={<Driver />} />
                                    <Route path={PAGES.TAXI_ZONES} element={<Zones />} />
                                    <Route path={PAGES.REVIEWS} element={<Reviews />} />
                                    <Route path={PAGES.FEEDBACKS} element={<Reviews />} />
                                </Routes>
                            </NavigationProvider>
                        </Router>
                    </SocketProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
