import React, {useState, useCallback, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Redirect} from 'react-router'
import {TailSpin} from "react-loader-spinner";

import '../css/Login.css';

import {useDispatch, useSelector} from "react-redux";
import logo from "../assets/img/logo.png";
import background from "../assets/img/background.jpg";

import Input from "../components/js/Input";
import Button from "../components/js/Button";
import {
    isRememberMeEnabled,
    loadCredentials,

} from "../store/actions";
import Message from "../components/js/Message";

import {
    isPasswordValid,
} from "../helpers/helpers";
import colors from "../constants/colors";
import ModalInfo from "../components/js/ModalInfo";
import {ErrorType, PAGES} from "../constants/enums";
import {updateUserPassword} from "../store/rest";
import {L10N, L10N_APP_TEXTS} from "../l10n/l10n";


function ResetPassword(props) {
    let dispatch = useDispatch();
    const l10n = useSelector(state => state.reducer.l10n);

    const {id} = useParams();

    const [password, setPassword] = useState();

    const [resetSuccessful, setResetSuccessful] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [error, setError] = useState(null);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    const [selectedLanguage, setSelectedLanguage] = useState(l10n ? l10n : L10N.si.value);

    useEffect(() => {
        loadCredentials()
        isRememberMeEnabled()
    }, []);

    useEffect(() => {
        if (id) {
            dispatchLoadResetLink()
        }
    }, [id]);

    useEffect(() => {
        if (resetSuccessful) {
            console.log('redirect..');
            onNavigateTo(PAGES.LOGIN);
        }
    }, [resetSuccessful]);

    const navigate = useNavigate();
    const onNavigateTo = useCallback((path) => {
        navigate(path);
    }, [navigate]);


    const dispatchLoadResetLink = () => {

    }

    const onInputChange = (value, onInputAction) => {
        setError(null);
        onInputAction(value);
    }

    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text, action) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
                if (action) {
                    action()
                }
            }
        })
    };

    const renderErrorMessage = () => {
        switch (error) {
            case ErrorType.UNAUTHORIZED:
                return <Message error type={ErrorType.UNAUTHORIZED}/>
            /*case ErrorType.ERROR_FETCHING_DATA:
                return <Message error type={ErrorType.ERROR_FETCHING_DATA}/>

             */
            case ErrorType.USER_ACCESS_NOT_GRANTED:
                return <Message error type={ErrorType.USER_ACCESS_NOT_GRANTED}/>
            default:
                return null;
        }
    };

    const onResetPassword = () => {

        setLoadingSpinner(true);
        const onSuccessLogin = () => {

            setModalValues(L10N_APP_TEXTS[selectedLanguage].PASSWORD_SUCCESSFULLY_RESET, () => {
                console.log('redirect success');
                setResetSuccessful(true);
                setLoadingSpinner(false);
            })
        };
        const UserAccessNotGranted = () => {
            setModalValues(L10N_APP_TEXTS[selectedLanguage].PASSWORD_RESET_LINK_EXPIRED, () => {
                console.log("Error fetching data");
                setLoadingSpinner(false);
            })
        };
        const onErrorUnauthorized = () => {
            setModalValues(L10N_APP_TEXTS[selectedLanguage].PASSWORD_RESET_LINK_EXPIRED, () => {
                console.log("Unauthorized");
                //   setError(ErrorType.UNAUTHORIZED);
                setLoadingSpinner(false);
            })
        };

        let data = {
            reset_request_id: id,
            password: password
        }

        if (!isPasswordValid((password))) {
            const invalidPasswordAlert = L10N_APP_TEXTS[selectedLanguage].PASSWORD_INVALID;
            setModalValues(invalidPasswordAlert, () => {
                console.log("Unauthorized");
                //   setError(ErrorType.UNAUTHORIZED);
                setLoadingSpinner(false);
            })
            return;
        }

        dispatch(updateUserPassword(data, onSuccessLogin, UserAccessNotGranted, onErrorUnauthorized));
    };

    return (
        <div className={'page login'} style={{backgroundImage: `url(${background})`}}>
            <div className={'login-container'}>
                <div className={'logo'} style={{alignSelf: 'center'}}>
                    <img src={logo} alt="logo" height={'110px'}/>
                </div>
                <div className={'form-container'}>
                    <Input login password
                           onChange={(value) => onInputChange(value, setPassword)} onSubmit={onResetPassword}
                           placeholder={L10N_APP_TEXTS[selectedLanguage].PLACEHOLDER_PASSWORD} label={L10N_APP_TEXTS[selectedLanguage].NEW_PASSWORD}
                           style={{marginBottom: '0px'}}/>
                    {renderErrorMessage()}
                    {loadingSpinner ?
                        <TailSpin
                            type="TailSpin"
                            color={colors.primary}
                            height={36}
                            width={36}
                            style={{paddingTop: 52, paddingBottom: 45}}
                        /> :
                        <Button text={L10N_APP_TEXTS[selectedLanguage].RESET_PASSWORD_BUTTON} login action={onResetPassword}/>
                    }
                    <p className={'p-info'}>- - -</p>
                </div>
            </div>
            {renderModalInfo()}
        </div>
    )

}

export default ResetPassword;