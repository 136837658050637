import {store} from '../store/configureStore'
import {SESSION_STORAGE_KEYS} from "../constants/enums";

import {setLanguage as setLanguageStore} from '../store/slices/userSlice';
import {loadLocalStorageData, setSessionStorageData} from "./storageHelpers";

export const setLanguage = (language) => {
    try {
        saveL10N(language)
        store.dispatch(setLanguageStore(language));
    } catch (e) {
        throw Error(e)
    }
};

export const saveL10N = (l10n) => {
    try {
        const onSetSessionAction = () => {
            localStorage.setItem(SESSION_STORAGE_KEYS.L10N, l10n);
            console.log(`SET [LOCAL STORAGE] ${SESSION_STORAGE_KEYS.L10N}:`, l10n);
        };
        setSessionStorageData(onSetSessionAction);
    } catch (e) {
        throw Error(e)
    }
};

export const getL10N = (useDispatch) => {
    if (useDispatch) {
        const onLoadSessionAction = (value) => {
            console.log(value);
            if (value) {
                setLanguage(value);
            }
            return loadLocalStorageData(SESSION_STORAGE_KEYS.L10N, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadLocalStorageData(SESSION_STORAGE_KEYS.L10N);
    }
};


export default {
    setLanguage,
    saveL10N,
    getL10N
}