import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DynamicTable from "../DynamicTable";
import { getLocalisedTexts } from "../../../l10n/localisations/languages";
import DriverApi from "../../../apis/driverApi";

const DriversDocumentsList = () => {
    const l10nText = getLocalisedTexts('DRIVER_DOCUMENTS');
    const [documents, setDocuments] = useState([]);
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let driversApi = await DriverApi.getDrivers();
            setDrivers(driversApi);
            let driversDocs = [];
            for (let driver of driversApi) {
                driversDocs.push({
                    name: driver.user.first_name + " " + driver.user.last_name,
                    edit: <Link to={`/driver/${driver.driver_id}`}>Edit</Link>,
                });
                
            }
            setDocuments(driversDocs);
        }
        fetchData();
        
    }, []);

    const columnsConfig = {
        name: l10nText.name || 'Name',
        edit: l10nText.edit || 'Edit',
    };

    return (
        <>
            <h3>{'Driver Documents'}</h3>
            <DynamicTable data={documents} columnsConfig={columnsConfig} />
        </>
    );
};

export default DriversDocumentsList;
