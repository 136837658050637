import { useEffect, useState } from "react";
const useWindowDimensions = () => {
  const [windowSize, setWindowSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    });

  useEffect(() => {
    const windowSizeHandler = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
        });
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  return windowSize;
};

export default useWindowDimensions;
