import axios from "../lib/axios";

export const getReviews = async () => {
    try {
        const resp = await axios.get("/reviews");
        return resp.data;
    } catch (error) {
        throw error;
    }
}

export default {
    getReviews
}