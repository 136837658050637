import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import driverApi from '../apis/driverApi';
import DriverBasicInfo from "../components/js/DriverBasicInfo";
import DriverCompletedOrders from "../components/js/DriverCompletedOrders";
import DriversDocumentsExpirationList from "../components/js/lists/DriversDocumentsExpirationList";
import Documents from '../components/js/Documents';
import Reviews from '../components/js/Reviews';
import UserApi from '../apis/userApi';
const Driver = () => {
    const { driver_id } = useParams();
    const [driverData, setDriverData] = useState(null);
    const [currentTab, setCurrentTab] = useState('BASIC_INFO');
    const [reviews, setReviews] = useState([]);
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
        const fetchDriverData = async () => {
            try {
                const response = await driverApi.getDriverById(driver_id);
                let documentsData = [];
                if (response && !response.error) {
                    setDriverData(response);
                    console.log("driverData", response);

                    documentsData = [...documentsData, ...response.documents, ...response.user.documents];
                    if (response.vehicles.length > 0) {
                        documentsData = [...documentsData, ...response.vehicles[0].documents];
                    }
                    if (response.user.business) {
                        documentsData = [...documentsData, ...response.user.business.documents];
                    }

                    setDocuments(documentsData);
                    const resp = await UserApi.getReviewsByUserId(response.user.user_id);
                    console.log("user_id", response.user.user_id);
                    console.log(resp, 'reviews');
                    setReviews(resp);
                }
            } catch (error) {
                console.error('Error fetching driver data:', error);
            }
        };
        
        fetchDriverData();
    }, [driver_id]);
    const tabs = ['BASIC_INFO', 'COMPLETED_ORDERS', 'REVIEWS', 'DOCUMENTS', 'DOCUMENTS_EXPIRATION_DATES'];

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title={`Driver: ${driverData?.user?.first_name} ${driverData?.user?.last_name}`} />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="DRIVER"
                />

                <div className="custom-row">
                    {currentTab === 'BASIC_INFO' && <DriverBasicInfo driverData={driverData} />}
                    {currentTab === 'COMPLETED_ORDERS' && <DriverCompletedOrders driverId={driver_id} />}
                    {currentTab === 'REVIEWS'  && <Reviews reviews={reviews} /> }
                    {currentTab === 'DOCUMENTS' && <Documents documents={documents} /> }
                    {currentTab === 'DOCUMENTS_EXPIRATION_DATES' && <DriversDocumentsExpirationList driverId={driver_id} />}
                </div>
            </div>
        </PageWrapper>
    );
};

export default Driver;
