export const L10N = {
    si: {label: 'Slovenščina', value: 'SI', priceValue: '€', priceValueLabel: '€ (EUR)', country: 'Slovenija'},
    en: {label: 'English (US)', value: 'EN', priceValue: '$', priceValueLabel: '$ (USD)', country: 'Slovenia'},
}

export const L10N_APP_TEXTS = {

    EN: {
        EDIT: 'edit',
        SAVE: 'save',
        PLACEHOLDER_EMAIL: 'E-mail',
        PLACEHOLDER_PASSWORD: 'Password',
        COPIED: 'Link copied',
        LOGIN_EMAIL: 'Enter your email',
        LOGIN_PASSWORD: 'Enter password',
        LOGIN_REMEMBER: 'Remember me',
        LOGIN_BUTTON: 'SIGN IN',
        NEW_PASSWORD: 'Enter new password',
        RESET_PASSWORD_BUTTON: 'RESET PASSWORD',
        PASSWORD_SUCCESSFULLY_RESET: 'Password successfully reset',
        PASSWORD_RESET_LINK_EXPIRED: 'The password reset link has expired. Please request a new link.',
        PASSWORD_INVALID: 'Password is not valid. Please enter only letters and numbers, without special characters.'
    },
    SI: {
        EDIT: 'uredi',
        SAVE: 'shrani',
        PLACEHOLDER_EMAIL: 'E-pošta',
        PLACEHOLDER_PASSWORD: 'Geslo',
        COPIED: 'Povezava kopirana',
        LOGIN_EMAIL: 'Vnesite svoj e-poštni naslov',
        LOGIN_PASSWORD: 'Vnesite geslo',
        LOGIN_REMEMBER: 'Zapomni si me',
        LOGIN_BUTTON: 'PRIJAVA',
        NEW_PASSWORD: 'Vnesite novo geslo',
        RESET_PASSWORD_BUTTON: 'PONASTAVI GESLO',
        PASSWORD_SUCCESSFULLY_RESET: 'Geslo uspešno ponastavljeno',
        PASSWORD_RESET_LINK_EXPIRED: 'Povezava za ponastavitev gesla je potekla. Prosimo zahtevajte novo povezavo.',
        PASSWORD_INVALID: 'Geslo ni veljavno. Vnesite samo črke in številke, brez posebnih znakov.'
    },
};


