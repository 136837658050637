import React, {useEffect, useRef} from "react";

import "../css/Modal.css";

import Button from "../js/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";


function ModalInfo(props) {
    console.log("Open modal");

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);


    const renderHeader = (title, actionClose) => {
        return (
            <div className={'modal-header'}>
                <p>{title}</p>
                <div onClick={actionClose} style={{cursor: 'pointer'}}>
                    <FontAwesomeIcon icon={faTimes} className={'icon-close'}/>
                </div>
            </div>
        );
    };

    const renderBody = (text) => {
        return (
            <div className={'modal-info-body'}>
                <p>{text}</p>
            </div>
        );
    };

    const renderFooter = (text, actionCreate) => {
        return (
            <Button text={text} confirm action={actionCreate} style={{
                alignSelf: 'flex-end',
                marginTop: '16px'
            }}/>
        );
    };

    const renderModal = () => {
        return (
            <div ref={ref} className={'modal-overlay modal-info'}>
                <div className={'modal'}>
                    {renderHeader(props.title ? props.title : "", props.actionClose)}
                    {renderBody(props.text)}
                    {renderFooter(props.buttonText ? props.buttonText : 'OK', props.actionClose)}
                </div>
            </div>
        );
    };

    if (props.info) {
        return renderModal();
    } else {
        return <></>
    }
}

export default ModalInfo;