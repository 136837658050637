import React, { useState, useEffect } from 'react';
import DynamicTable from '../DynamicTable';
import { APP_SERVICES, TAXI_ORDER_STATUS } from '../../../constants/enums';
import taxiApi from '../../../apis/taxiApi';

const TaxiOrdersOverwatch = () => {
    const [pendingOrders, setPendingOrders] = useState([]);
    const [activeOrders, setActiveOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [canceledOrders, setCanceledOrders] = useState([]);

    console.log("COMPLETED ORDERS", completedOrders)

    const fetchTaxiOrders = async () => {
        try {
            const response = await taxiApi.getTaxiOrders();
            if (response && !response.error) {

                const orders = response.map(order => ({
                    ...order,
                    order_id: order.order_id,
                    driver_name: `${order.driver?.user?.first_name || ''} ${order.driver?.user?.last_name || ''}`,
                    vehicle: `${order.driver?.vehicles[0]?.make || ''} ${order.driver?.vehicles[0]?.model || ''}`,
                    status: order.status,
                    created_at: new Date(order.created_at).toLocaleString('sl-SI', { timeZone: 'Europe/Ljubljana' }),
                    price: order.payment ? `${order.payment.price}€` : 'N/A',
                    pickup_location: order.pickup_location?.address || 'N/A',
                    delivery_location: order.delivery_location?.address || 'N/A',
                }));

                setPendingOrders(orders.filter(order => order.status === TAXI_ORDER_STATUS.PENDING));
                setActiveOrders(orders.filter(order =>
                    order.status === TAXI_ORDER_STATUS.DRIVING ||
                    order.status === TAXI_ORDER_STATUS.WAITING
                ));
                setCompletedOrders(orders.filter(order => order.status === TAXI_ORDER_STATUS.COMPLETED));
                setCanceledOrders(orders.filter(order =>
                    order.status === TAXI_ORDER_STATUS.CANCELED ||
                    order.status === TAXI_ORDER_STATUS.TAXI_CANCELED ||
                    order.status === TAXI_ORDER_STATUS.REJECTED
                ));
            }
        } catch (error) {
            console.error('Error fetching taxi orders:', error);
        }
    };

    useEffect(() => {
        fetchTaxiOrders().then(() => console.log('Taxi orders fetched.'));

        const intervalId = setInterval(fetchTaxiOrders, 10000); // Fetch orders every 10 seconds

        return () => clearInterval(intervalId);
    }, []);

    const activeColumnsConfig = {
        order_id: 'Order ID',
        is_scheduled: 'Type',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        status: 'Status',
        created_at: 'Created',
        price: 'Price',
    };

    const completedColumnsConfig = {
        order_id: 'Order ID',
        is_scheduled: 'Type',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        status: 'Status',
        created_at: 'Created',
        price: 'Price',
    };

    const pendingColumnsConfig = {
        order_id: 'Order ID',
        is_scheduled: 'Type',
        status: 'Status',
        created_at: 'Created',
    };

    const canceledRejectedColumnsConfig = {
        order_id: 'Order ID',
        is_scheduled: 'Type',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        status: 'Status',
        created_at: 'Created',
    }

    return (
        <div>
            <h4>Pending Taxi Orders</h4>
            <DynamicTable
                data={pendingOrders}
                columnsConfig={pendingColumnsConfig}
                rowsPerPage={8}
                extendable={true}
            />

            <h4>Active Taxi Orders</h4>
            <DynamicTable
                data={activeOrders}
                columnsConfig={activeColumnsConfig}
                rowsPerPage={8}
                extendable={true}
            />

            <h4>Completed Taxi Orders</h4>
            <DynamicTable
                data={completedOrders}
                columnsConfig={completedColumnsConfig}
                rowsPerPage={8}
                extendable={true}
            />

            <h4>Canceled or Rejected Taxi Orders</h4>
            <DynamicTable
                data={canceledOrders}
                columnsConfig={canceledRejectedColumnsConfig}
                rowsPerPage={8}
                extendable={true}
            />
        </div>
    );
};

export default TaxiOrdersOverwatch;
