import React, { useState } from 'react';
import PageWrapper from '../components/js/PageWrapper';
import Header from '../components/js/Header';
import PageTabs from '../components/js/PageTabs';
import { useNavigation } from '../context/NavigationContext';
import DriversDocumentsExpirationList from "../components/js/lists/DriversDocumentsExpirationList";
import DriversEarningsList from "../components/js/lists/DriversEarningsList";
import TaxiDashboardTab from "./tabs/TaxiDashboardTab";
import TaxiOrdersOverwatch from "../components/js/overwatch/TaxiOrdersOverwatch";
import DriversList from "../components/js/lists/DriversList";

const DashboardTaxi = () => {
    const { onNavigateTo } = useNavigation();

    const tabs = ['DASHBOARD', 'DRIVERS_LIST', 'DRIVERS_EARNINGS', 'DRIVERS_DOCUMENTS', 'DRIVERS_DOCUMENTS_EXPIRATION'];
    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const handleTabChange = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <PageWrapper>
            <div className="body">
                <Header title="Dashboard" />
                <PageTabs
                    tabs={tabs}
                    initialTab={currentTab}
                    onTabChange={handleTabChange}
                    pageContext="DASHBOARD"
                />

                <div className="custom-row">
                    {currentTab === 'DASHBOARD' && (
                        <div>
                            <h3>Welcome back!</h3>
                            <TaxiDashboardTab />
                            <TaxiOrdersOverwatch />
                        </div>
                    )}
                    {currentTab === 'DRIVERS_LIST' && (
                        <div>
                            <DriversList />
                        </div>
                    )}
                    {currentTab === 'DRIVERS_EARNINGS' && (
                        <div>
                            <DriversEarningsList />
                        </div>
                    )}
                    {currentTab === 'DRIVERS_DOCUMENTS' && (
                        <div>
                            <p>Manage drivers' documents here. TODO:// display list of users where edit leads to DriverPage where you can display uploaded documents per each section</p>
                        </div>
                    )}
                    {currentTab === 'DRIVERS_DOCUMENTS_EXPIRATION' && (
                        <div>
                            <DriversDocumentsExpirationList />
                        </div>
                    )}
                </div>
            </div>
        </PageWrapper>
    );
};

export default DashboardTaxi;
