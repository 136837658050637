import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DynamicTable from "../DynamicTable";
import { getLocalisedTexts } from "../../../l10n/localisations/languages";
import TableLegend from "../TableLegend";
import documentApi from "../../../apis/documentApi";
import driverApi from "../../../apis/driverApi";

const DriversDocumentsExpirationList = ({ driverId }) => {
    const { id } = useParams();  // Get the driver ID from URL params if provided
    const l10nText = getLocalisedTexts('DRIVER_DOCUMENTS');
    const [documents, setDocuments] = useState([]);
    const [drivers, setDrivers] = useState([])

    useEffect(() => {
        fetchRegisteredDrivers()
    }, []);

    const fetchDriverDocuments = async (id) => {
        try {
            const response = await documentApi.getDocumentsByDriverId(id);
            if (response && !response.error) {
                return response;
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching driver documents:', error);
            return [];
        }
    };

    const fetchRegisteredDrivers = async () => {
        try {
            const drivers = await driverApi.listDrivers();
            if (drivers && !drivers.error) {
                setDrivers(drivers);
            } else {
                setDrivers([]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const transformDriverDocuments = (documents, driver, defaultValue = 'N/A') => {
        console.log(driver, 'lala')
        const transformedDocuments = {
            car_number_plate: driver?.vehicles[0]?.license_plate || defaultValue,
            courier: `${driver?.user?.first_name || ''} ${driver?.user?.last_name || ''}`,
            phone_number: driver?.user?.telephone || defaultValue,
            drivers_license_expiration_date: defaultValue,
            vehicle_registration_expiration_date: defaultValue,
            permit_expiration_date: defaultValue,
            technical_assignment_expiration_date: defaultValue
        };

        documents.forEach(doc => {
            switch (doc.document_type) {
                case 'DRIVING_LICENSE':
                    transformedDocuments.drivers_license_expiration_date = doc.expiration_date ? new Date(doc.expiration_date).toISOString().split('T')[0] : defaultValue;
                    break;
                case 'VEHICLE_REGISTRATION':
                    transformedDocuments.vehicle_registration_expiration_date = doc.expiration_date ? new Date(doc.expiration_date).toISOString().split('T')[0] : defaultValue;
                    break;
                case 'OPERATING_PERMIT':
                    transformedDocuments.permit_expiration_date = doc.expiration_date ? new Date(doc.expiration_date).toISOString().split('T')[0] : defaultValue;
                    break;
                case 'VEHICLE_TECHNICAL_INSPECTION':
                    transformedDocuments.technical_assignment_expiration_date = doc.expiration_date ? new Date(doc.expiration_date).toISOString().split('T')[0] : defaultValue;
                    break;
                default:
                    break;
            }
        });

        return transformedDocuments;
    };

    const fetchAllDriverDocuments = async () => {
        const allTransformedDocuments = [];

        for (const driver of drivers) {
            const driverDocs = await fetchDriverDocuments(driver.driver_id);
            const transformed = transformDriverDocuments(driverDocs, driver);
            allTransformedDocuments.push(transformed);
        }
        setDocuments(allTransformedDocuments);
    };

    useEffect(() => {
        if (driverId || id) {
            fetchDriverDocuments(driverId || id).then(response => {
                if (response) {
                    const transformed = transformDriverDocuments(response, drivers.find(driver => driver.driver_id === (driverId || id)));
                    setDocuments([transformed]);
                }
            });
        } else {
            fetchAllDriverDocuments();
        }
    }, [driverId, id, drivers]);

    const columnsConfig = {
        car_number_plate: l10nText.car_number_plate || 'Car Number Plate',
        courier: l10nText.courier || 'Courier',
        phone_number: l10nText.phone_number || 'Phone Number',
        drivers_license_expiration_date: l10nText.drivers_license || 'Driver\'s License',
        vehicle_registration_expiration_date: l10nText.vehicle_registration || 'Vehicle Registration',
        permit_expiration_date: l10nText.permit || 'Permit',
        technical_assignment_expiration_date: l10nText.technical_assignment || 'Technical Assignment',
    };

    return (
        <>
            <h3>{l10nText.title || 'Driver Document Expiration Dates'}</h3>
            <TableLegend />
            <DynamicTable data={documents} columnsConfig={columnsConfig} />
        </>
    );
};

export default DriversDocumentsExpirationList;
