// createSlice
import {createSlice} from '@reduxjs/toolkit';

const clearState = (state) => ({
    ...state,
    access_token: null,
    profilePicChosenOption: "",
    profile_picture: null,
    credentials: {
        email: "",
        password: ""
    },
    addresses: [],
    selectedAddress: null,
    loyaltyCard: null,
    invoices: null,
    payment_methods: [],
    wallet_balance: '',
    notification_preferences: null,
    push_notification_preferences: null,
    taxi_preferences: null,
    transfer_preferences: null,
    allergies_preferences: null,
    spicy_preferences: null,
    radio_preferences: null,
    payment_history: [],
    ride_history: []
});

const defaultState = {
    access_token: null,
    language: "EN",
    appFirstOpen: true,
    profilePicChosenOption: "",
    profile_picture: null,
    credentials: {
        email: "",
        password: ""
    },
    rememberMe: false,
    addresses: [],
    selectedAddress: null,
    loyaltyCard: null,
    invoices: null,
    payment_methods: [],
    wallet_balance: '',
    notification_preferences: null,
    push_notification_preferences: null,
    taxi_preferences: null,
    transfer_preferences: null,
    allergies_preferences: null,
    spicy_preferences: null,
    radio_preferences: null,
    payment_history: [],
    ride_history: []
};

const userSlice = createSlice({
    name: "user",
    initialState: defaultState,
    reducers: {
        loginUser: (state, param) => {

            const {payload} = param;
            return {
                ...state,
                ...payload
            };
        },
        clearUserState: (state) => {
            return clearState(state);
        },
        setLanguage: (state, param) => {
            console.log("LANGUAGE", param.payload);
            state.language = param.payload
        },
        setUserData: (state, param) => {
            console.log("SET USER DATA", param.payload)
            const {user} = param.payload
            return {
                ...state,
                ...user
            }
        },
    }
});

const {actions, reducer} = userSlice

export const {
    loginUser,
    clearUserState,
    setLanguage,
    setUserData,
} = actions;

export default reducer;