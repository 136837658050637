import React, { useState } from 'react';
import '../css/DynamicTable.css';
import {renderCellContent, renderTaxiOrderExtendedContent} from "../../lib/tableHelpers";

const DynamicTable = ({ data = [], columnsConfig = {}, rowsPerPage = 10, extendable = false }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [expandedRows, setExpandedRows] = useState({});

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const toggleRowExpansion = (rowIndex) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [rowIndex]: !prevExpandedRows[rowIndex],
        }));
    };

    const sortedData = React.useMemo(() => {
        if (sortConfig.key) {
            return [...data].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return data;
    }, [data, sortConfig]);

    const startRow = (currentPage - 1) * rowsPerPage;
    const endRow = startRow + rowsPerPage;
    const displayedData = sortedData.slice(startRow, endRow);

    const renderHeader = () => (
        <thead>
        <tr>
            {Object.keys(columnsConfig).map((key) => (
                <th key={key} onClick={() => handleSort(key)} style={{ cursor: 'pointer' }}>
                    {sortConfig.key === key && (
                        <span className="sort-arrow">
                                {sortConfig.direction === 'asc' ? '▲' : '▼'}
                            </span>
                    )}
                    {columnsConfig[key]}
                </th>
            ))}
        </tr>
        </thead>
    );


    const renderExtendedContent = (rowIndex, rowData) => {
        if (!extendable || !expandedRows[rowIndex]) return null;

        return (
            <tr key={`${rowIndex}-extended`} className="extended-row">
                <td colSpan={Object.keys(columnsConfig).length}>
                    <div className="extended-content">
                        {renderTaxiOrderExtendedContent(rowData)}
                    </div>
                </td>
            </tr>
        );
    };

    const renderBody = () => {
        if (displayedData.length === 0) {
            return (
                <tbody>
                <tr>
                    <td colSpan={Object.keys(columnsConfig).length} className="empty-data">
                        List is currently empty.
                    </td>
                </tr>
                </tbody>
            );
        }

        return (
            <tbody>
            {displayedData.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    <tr onClick={() => toggleRowExpansion(rowIndex)} className={extendable ? 'extendable-row' : ''}>
                        {Object.keys(columnsConfig).map((key) => (
                            <td key={key}>
                                {renderCellContent(key, row[key])}
                            </td>
                        ))}
                    </tr>
                    {renderExtendedContent(rowIndex, row)}
                </React.Fragment>
            ))}
            </tbody>
        );
    };

    const renderPagination = () => {
        if (totalPages <= 1) return null;

        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }

        return (
            <div className="pagination">
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Prev
                </button>
                {pages.map((page) => (
                    <button
                        key={page}
                        className={`pagination-number ${currentPage === page ? 'active' : ''}`}
                        onClick={() => handlePageChange(page)}
                    >
                        {page}
                    </button>
                ))}
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        );
    };

    return (
        <div className="table-container">
            <table className="dynamic-table">
                {renderHeader()}
                {renderBody()}
            </table>
            {renderPagination()}
        </div>
    );
};

export default DynamicTable;
