import React, { useEffect, useState } from 'react';
import taxiApi from '../../apis/taxiApi';
import DynamicTable from "./DynamicTable";

const DriverCompletedOrders = ({ driverId }) => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchCompletedOrders = async () => {
            try {
                const response = await taxiApi.getCompletedTaxiOrdersByDriverId(driverId);
                if (response && !response.error) {
                    console.log(response, "orders");
                    setOrders(response);
                }
            } catch (error) {
                console.error('Error fetching completed orders:', error);
            }
        };

        fetchCompletedOrders();
    }, [driverId]);

    const columnsConfig = {
        order_id: 'Order ID',
        customer: 'Customer',
        amount: 'Amount',
        status: 'Status',
        date: 'Date',
    };

    const parseOrderData = orders.map(order => ({
        order_id: order.order_id,
        customer: `${order.user.first_name} ${order.user.last_name}`,
        amount: order.total_amount,
        status: order.status,
        date: new Date(order.created_at).toLocaleDateString(),
    }));

    return (
        <div>
            <h3>Completed Orders</h3>
            <DynamicTable
                data={parseOrderData}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />
        </div>
    );
};

export default DriverCompletedOrders;
