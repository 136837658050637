import {SESSION_STORAGE_KEYS} from "../constants/enums";
import {loadFromStorage, parseRestApiResponse, saveToStorage} from "../helpers/helpers";
import {L10N} from "../l10n/l10n";
import {setLanguage} from "../lib/languageHelpers";
import {loadSessionStorageData, setSessionStorageData} from "../lib/storageHelpers";

export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const LOAD_USERS = 'LOAD_USERS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_ACTIVE_EDIT_USER = 'SET_ACTIVE_EDIT_USER';
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';
export const REMEMBER_ME = 'REMEMBER_ME';
export const REMEMBER_ME_CREDENTIALS = 'REMEMBER_ME_CREDENTIALS';

export const SET_CREATING_MENU_PROGRESS = 'SET_CREATING_MENU_PROGRESS';
export const SET_CREATE_MENU = 'SET_CREATE_MENU';
export const LOAD_MENUS_HISTORY = 'LOAD_MENUS_HISTORY';
export const LOAD_BARS = 'LOAD_BARS';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_BY_CATEGORY = 'LOAD_PRODUCTS_BY_CATEGORY';
export const LOAD_COMBO_OFFERS = 'LOAD_COMBO_OFFERS';
export const SET_ACTIVE_BAR = 'SET_ACTIVE_BAR';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_MENU_LIVE = 'LOAD_MENU_LIVE';
export const LOAD_BARS_FOR_OWNER = 'LOAD_BARS_FOR_OWNER';
export const SET_CREATE_MENU_LAST_CREATED = 'SET_CREATE_MENU_LAST_CREATED';
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const ADD_TO_USERS_LIST = 'ADD_TO_USERS_LIST';
export const LOAD_CARETAKERS = 'LOAD_CARETAKERS';
export const SET_ACTIVE_BAR_REPRESENTATIVE = 'SET_ACTIVE_BAR_REPRESENTATIVE';
export const SET_ACTIVE_BAR_CARETAKER = 'SET_ACTIVE_BAR_CARETAKER';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_USER_CARETAKER = 'SET_USER_CARETAKER';
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU';
export const SET_ACTIVE_BAR_OWNER = 'SET_ACTIVE_BAR_OWNER';
export const SET_LAST_CARETAKER_CREATED = 'SET_LAST_CARETAKER_CREATED';
export const LOAD_ONLINE_BAR = 'LOAD_ONLINE_BAR';
export const LOAD_ONLINE_MENU = 'LOAD_ONLINE_MENU';
export const LOAD_ADS = 'LOAD_ADS';
export const LOAD_BAR_ADS = 'LOAD_BAR_ADS';
export const SET_SELECTED_AD = 'SET_SELECTED_AD';
export const LOAD_SELECTED_AD_ADDED_BARS = 'LOAD_SELECTED_AD_ADDED_BARS';
export const SET_NOTIFICATIONS_AVAILABLE = 'SET_NOTIFICATIONS_AVAILABLE';
export const LOAD_PRODUCT_CATEGORY_LABELS = 'LOAD_PRODUCT_CATEGORY_LABELS';
export const SET_DOWNLOAD_PROGRESS = 'SET_DOWNLOAD_PROGRESS';
export const SET_DOWNLOAD_STATUS = 'SET_DOWNLOAD_STATUS';
export const UPDATE_IMPORT_ERRORS = 'UPDATE_IMPORT_ERRORS';
export const SET_IMPORT_ERRORS = 'SET_IMPORT_ERRORS';

//const URL_HOSTNAME = 'https://domain/3001';
const URL_HOSTNAME = 'http://localhost:3001';
const REST_API_ENDPOINT = '/rest'
export const BE_REST_API = URL_HOSTNAME + REST_API_ENDPOINT;

export const setUserToken = (token) => {
    return {type: SET_USER_TOKEN, token: token};
};

export const setUserData = (user) => {
    return {type: SET_USER_DATA, user: user};
};

export const setCredentials = (email, password) => {
    return {type: SET_CREDENTIALS, email: email, password: password};
};

export const setUsers = (users) => {
    return {type: LOAD_USERS, users: users};
};

export const setActiveEditUser = (activeEditUser) => {
    return {type: SET_ACTIVE_EDIT_USER, activeEditUser: activeEditUser};
};

export const setShowSidebar = (showSidebar) => {
    return {type: SHOW_SIDEBAR, showSidebar: showSidebar};
};

export const setSidebarWidth = (sidebarWidth) => {
    return {type: SET_SIDEBAR_WIDTH, sidebarWidth: sidebarWidth};
};


export const setRememberMe = (state) => {
    saveRememberMe(state);
    return {type: REMEMBER_ME, rememberMe: state};
};

export const setSignInCredentials = (email, password, shouldWrite) => {
    if (shouldWrite) {
        saveCredentials(email, password);
    }
    return {type: REMEMBER_ME_CREDENTIALS, email: email, password: password};
};

export const setDownloadProgress = (file_id, progress) => {
    setSessionDownloadProgress(file_id, progress);

    return {type: SET_DOWNLOAD_PROGRESS, file_id: file_id, downloadProgress: progress};
};

export const setDownloadStatusAction = (file_id, status) => {

    return {type: SET_DOWNLOAD_STATUS, file_id: file_id, status: status};
};

export const setCategories = (categories) => {
    setSessionCategories(JSON.stringify(categories));
    return {type: LOAD_CATEGORIES, categories: categories};
};

export const setProducts = (products) => {
    return {type: LOAD_PRODUCTS, products: products};
};

export const setMenuLastCreated = (menu) => {
    return {type: SET_CREATE_MENU_LAST_CREATED, menu: menu}
};
export const setProductCategoryLabels = (categoryLabels) => {
    return {type: LOAD_PRODUCT_CATEGORY_LABELS, categoryLabels: categoryLabels}
};
export const setProductsByCategory = (products) => {
    return {type: LOAD_PRODUCTS_BY_CATEGORY, products: products}
};
export const setNotificationsAvailable = (state) => {
    return {type: SET_NOTIFICATIONS_AVAILABLE, state: state}
};
export const setSelectedAdAddedBars = (bars) => {
    return {type: LOAD_SELECTED_AD_ADDED_BARS, bars: bars}
};
export const setBarAds = (ads) => {
    return {type: LOAD_BAR_ADS, ads: ads}
};
export const setAds = (ads) => {
    return {type: LOAD_ADS, ads: ads}
};

export const loadOnlineMenu = (menu) => {
    return {type: LOAD_ONLINE_MENU, menu: menu}
};
export const loadOnlineBar = (bar) => {
    return {type: LOAD_ONLINE_BAR, bar: bar}
};

export const setCreateMenuActive = (menu) => {
    return {type: SET_CREATE_MENU, createMenuActive: menu}
};

export const setIsCreatingMenu = (state) => {
    return {type: SET_CREATING_MENU_PROGRESS, isCreatingMenu: state}
};

export const setCaretaker = (caretaker) => {
    setSessionCaretaker(JSON.stringify(caretaker));
    return {type: SET_USER_CARETAKER, caretaker: caretaker};
};


export const addToUsersList = (user) => {
    return {type: ADD_TO_USERS_LIST, user: user};
};

export const setActiveBar = (bar) => {
    setSessionActiveBar(JSON.stringify(bar));
    return {type: SET_ACTIVE_BAR, bar: bar};
};

export const setActiveBarRepresentative = (user) => {
    setSessionActiveBarUser(JSON.stringify(user));
    return {type: SET_ACTIVE_BAR_REPRESENTATIVE, user: user};
};

export const setActiveBarCaretaker = (user) => {
    setSessionActiveBarCaretaker(JSON.stringify(user));
    return {type: SET_ACTIVE_BAR_CARETAKER, user: user};
};

export const setActiveBarOwner = (user) => {
    setSessionActiveBarOwner(JSON.stringify(user));
    return {type: SET_ACTIVE_BAR_OWNER, user: user};
};

export const setLastCaretakerCreated = (caretaker) => {
    //setSessionActiveBarOwner(JSON.stringify(user));
    return {type: SET_LAST_CARETAKER_CREATED, caretaker: caretaker};
};

export const setActiveProduct = (product) => {
    setSessionActiveProduct(JSON.stringify(product));
    return {type: SET_SELECTED_PRODUCT, product: product};
};

export const setActiveCategory = (category) => {
    setSessionActiveCategory(JSON.stringify(category));
    return {type: SET_SELECTED_CATEGORY, category: category};
};

export const setActiveAd = (ad) => {
    setSessionActiveAd(JSON.stringify(ad));
    return {type: SET_SELECTED_AD, ad: ad};
};

export const setSessionActiveAd = (ad) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_AD, ad);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_AD}:`, JSON.parse(ad));
    };
    setSessionStorageData(onSetSessionAction);
};

export const setNotifications = (notifications) => {
    return {type: LOAD_NOTIFICATIONS, notifications: notifications};
};

export const setCaretakers = (users) => {
    return {type: LOAD_CARETAKERS, users: users};
};

export const setBars = (bars) => {
    return {type: LOAD_BARS, bars: bars};
};

export const updateImportErrors = (error) => {
    return {type: UPDATE_IMPORT_ERRORS, error: error};
};
export const setImportErrors = (importErrors) => {
    return {type: SET_IMPORT_ERRORS, importErrors: importErrors};
};

// Wrapper functions for specific cases
export const loadCredentials = () => {
    return async dispatch => {
        try {
            const email = localStorage.getItem('_saved_email');
            const pass = localStorage.getItem('_saved_password');
            console.log("LOAD [STORAGE] REMEMBER ME CREDENTIALS:", email, pass);
            if (email && pass) {
                dispatch(setSignInCredentials(email, pass));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveCredentials = (email, password) => {
    try {
        localStorage.setItem('_saved_email', email);
        localStorage.setItem('_saved_password', password);
        console.log("SAVE [STORAGE] REMEMBER ME CREDENTIALS:", email, password);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const loadSearchTerm = (search_input_field, action) => {
    return loadSessionStorageData('_search_term' + search_input_field, action)
};

export const saveSearchTerm = (search_input_field, search_term) => {
    try {
        sessionStorage.setItem('_search_term' + search_input_field, search_term);
        console.log("SAVE [STORAGE] SEARCH TERM:", search_term);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};


export const loadLastPageOpened = (action) => {
    return loadSessionStorageData('_last_page', action)
};

export const saveLastPageOpened = (last_page) => {
    try {
        sessionStorage.setItem('_last_page', last_page);
        console.log("SAVE [STORAGE] LAST PAGE VISITED:", last_page);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};

export const isRememberMeEnabled = () => {
    return async dispatch => {
        try {
            const value = localStorage.getItem('_remember_me_sign_in');
            console.log("LOAD [STORAGE] REMEMBER ME:", value);
            if (!value || Number(value) === 0) {
                dispatch(setRememberMe(false));
            } else {
                dispatch(setRememberMe(true));
            }
        } catch (error) {
            console.log("Error getting data! Data key does not yet exist" + error);
        }
    };
};

export const saveRememberMe = (state) => {
    try {
        localStorage.setItem('_remember_me_sign_in', state ? '1' : '0');
        console.log("SAVE [STORAGE]  REMEMBER ME:", state);
    } catch (error) {
        throw Error("Error saving data!" + error);
    }
};


export const setSessionUser = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER, user);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER}:`, JSON.parse(user));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserData(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};


export const setSessionUserToken = (token) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_TOKEN, token);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER_TOKEN}:`, token);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUserToken = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUserToken(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionUsers = (users) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USERS, users);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USERS}:`, JSON.parse(users));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionUsers = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setUsers(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveEditUser = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_EDIT_USER, user);
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveEditUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveEditUser(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionCaretaker = (user) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.USER_CARETAKER, user);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.USER_CARETAKER}:`, JSON.parse(user));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionCaretaker = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setCaretaker(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveBarUser = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.BAR_REPRESENTATIVE, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BAR_REPRESENTATIVE}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBarUser = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBarRepresentative(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveBarCaretaker = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.BAR_CARETAKER, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BAR_CARETAKER}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBarCaretaker = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBarCaretaker(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};


export const setSessionActiveBarOwner = (owner) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.BAR_OWNER, owner);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BAR_OWNER}:`, JSON.parse(owner));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBarOwner = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBarOwner(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionCategories = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.CATEGORIES, products);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.CATEGORIES}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionCategories = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setCategories(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};


export const loadDownloadProgress = (file_id, action) => {
    return loadSessionStorageData(SESSION_STORAGE_KEYS.DOWNLOAD_PROGRESS  + file_id, action)
};


export const setSessionDownloadProgress = (file_id, progress) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.DOWNLOAD_PROGRESS + file_id, progress);
        console.log("SAVE [STORAGE] DOWNLOAD PROGRESS:", progress);
    };
    setSessionStorageData(onSetSessionAction);
};

export const setSessionActiveBar = (products) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_BAR, products);
        //console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_BAR}:`, JSON.parse(products));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveBar = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveBar(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveProduct = (product) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_PRODUCT, product);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_PRODUCT}:`, JSON.parse(product));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveProduct = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveProduct(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionActiveCategory = (category) => {
    const onSetSessionAction = () => {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.ACTIVE_CATEGORY, category);
        console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.ACTIVE_CATEGORY}:`, JSON.parse(category));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionActiveCategory = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveCategory(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};


export const loadSessionActiveAd = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setActiveAd(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};

export const setSessionBars = (bars) => {
    const onSetSessionAction = () => {
        //sessionStorage.setItem(SESSION_STORAGE_KEYS.BARS, bars);
        //console.log(`SET [SESSION STORAGE] ${SESSION_STORAGE_KEYS.BARS}:`, JSON.parse(bars));
    };
    setSessionStorageData(onSetSessionAction);
};

export const loadSessionBars = (key, useDispatch) => {
    if (useDispatch) {
        return async dispatch => {
            const onLoadSessionAction = (value) => {
                if (value) {
                    dispatch(setBars(value));
                }
            };
            loadSessionStorageData(key, (value) => onLoadSessionAction(JSON.parse(JSON.stringify(value))));
        };
    } else {
        return loadSessionStorageData(key);
    }
};