import { combineReducers } from 'redux';
import { configureStore  } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userSlice from './slices/userSlice';
import adminSlice from "./slices/adminSlice";

const persistConfig = {
    key: 'root',
    storage,
}

const reducers = combineReducers({
    user: userSlice,
    admin: adminSlice
});
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)