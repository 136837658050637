import axios from "../lib/axios";

export const createScheduledUser = async (data, addresses) => {
  try {
    const response = await axios.post("/user/auth/create/scheduled_user", {
      data: data,
      addresses: addresses
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const getScheduledUsers = async () => {
  try {
    const response = await axios.get("/user/auth/scheduled_users");
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const updateScheduledUser = async (user_id, data, addresses) => {
  try {
    const response = await axios.post("/user/auth/update/scheduled_user", {
      user_id: user_id,
      data: data,
      addresses: addresses
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}


export const refreshAccessTokens = async (refresh_token) => {
    try {
        const response = await axios.post("/auth/refresh", {
            refresh_token
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const userLogin = async (username, password, onResponse) => {
  try {
    const resp = await axios.post("/auth/login", {
      email: username,
      password
    }, {
      onResponse
    });

    return resp.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getActiveUser = async () => {
  try {
    const resp = await axios.get("/users/me");
    return resp.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const logoutUser = async () => {};

export const changePassword = async (token, password) => {
  try {
    await axios.post(`/reset-password/${token}`, {
      password,
    });
    return true;
  } catch (err) {
    return false;
  }
};

export const userSignUp = async (
  email,
  password,
  firstName,
  lastName,
  fullPhoneNumber,
  phoneNumberCode,
  phoneNumber,
  dateOfBirth,
  onResponse = {}
) => {
  try {
    const resp = await axios.post("/auth/register", {
      email,
      password,
      confirm_password: password,
      first_name: firstName,
      last_name: lastName,
      telephone: fullPhoneNumber,
      telephone_code: phoneNumberCode,
      telephone_number: phoneNumber,
      date_of_birth: dateOfBirth,
      user_role: 'DISPATCHER'
    }, {
      onResponse
    });
    return resp.data;
  } catch (err) {
    console.log(err)
    return err;
  }
};


export const userRequestSMSCode = async (phoneFull) => {
  try {
    const resp = await axios.get("/users/me/verify/phone");
    return resp.data;
  } catch (error) {
    return error;
  }
}

export const userVerifySMSCode = async (token) => {
  try {
    const resp = await axios.post("/users/me/verify/phone", { token });
    console.log("userVerifySMSCode", resp)
    return resp;
  } catch (error) {
    return error;
  }
}

export const requestPasswordReset = async (email) => {
  try {
    const response = await axios.post('/auth/reset-password', { email });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}
export const getReviewsByUserId = async (userId) => {
  try {
    const response = await axios.get(`/users/${userId}/reviews`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
const UserApi = {
  refreshAccessTokens,
  userLogin,
  logoutUser,
  changePassword,
  getActiveUser,
  userSignUp,
  userRequestSMSCode,
  userVerifySMSCode,
  requestPasswordReset,
  createScheduledUser,
  getScheduledUsers,
  updateScheduledUser,
  getReviewsByUserId
};

export default UserApi;
