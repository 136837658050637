import React from "react";
import '../css/Header.css'

function Header(props) {
    return <>
        <div className={'header-default'}>
            <p className={'header-title'}>{props.title}</p>
            <div style={{position: 'absolute', right: 0}}>
                {props?.createAction ? props.createAction() : <></>}
            </div>
        </div>
    </>

}

export default Header;