import React, {useEffect, useState} from 'react';
import {getLocalisedTexts} from "../../l10n/localisations/languages";
import {renderDashboardTaxiDataSquares, renderDriversEarningsListDataSquares} from "../../components/js/DataSquare";
import driverApi from "../../apis/driverApi";
import taxiApi from "../../apis/taxiApi";
import {TAXI_ORDER_STATUS} from "../../constants/enums";

const TaxiDashboardTab = () => {
    const l10nText = getLocalisedTexts('DASHBOARD');

    const [activeDrivers, setActiveDrivers] = useState(0);
    const [minimumActiveDrivers, setMinimumActiveDrivers] = useState(0);
    const [totalRegisteredDrivers, setTotalRegisteredDrivers] = useState(0);
    const [completedRidesToday, setCompletedRidesToday] = useState(0);
    const [todaysEarnings, setTodaysEarnings] = useState(0);
    const [weeklyEarnings, setWeeklyEarnings] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [totalEarnings, setTotalEarnings] = useState(0);

    useEffect(() => {
        async function fetchActiveDrivers() {
            try {
                const drivers = await driverApi.listOnlineDrivers();
                if (drivers && !drivers.error) {
                    setActiveDrivers(drivers.length);
                    if (minimumActiveDrivers < activeDrivers) {
                        setMinimumActiveDrivers(drivers.length)
                    }
                } else {
                    setActiveDrivers(0);
                }
            } catch (e) {
                console.error('Error fetching drivers:', e);
                setActiveDrivers(0);
            }
        }

        const fetchRegisteredDrivers = async () => {
            try {
                const drivers = await driverApi.listDrivers();
                if (drivers && !drivers.error) {
                    setTotalRegisteredDrivers(drivers.length);
                } else {
                    setTotalRegisteredDrivers(0);
                }
            } catch (e) {
                console.error(e);
                setTotalRegisteredDrivers(0);
            }
        };

        const fetchTaxiOrders = async () => {
            try {
                const orders = await taxiApi.getTaxiOrders();
                if (orders && !orders.error) {
                    const completedOrders = orders.filter(order => order.status === TAXI_ORDER_STATUS.COMPLETED)

                    const now = new Date();

                    let todaysEarnings = 0;
                    let weeklyEarnings = 0;
                    let monthlyEarnings = 0;
                    let totalEarnings = 0;
                    let completedRidesToday = 0;

                    completedOrders.forEach(order => {
                        const timeline = order.timeline.find(item => item.status === TAXI_ORDER_STATUS.COMPLETED);
                        const timestamp = timeline?.location?.timestamp;

                        if (timestamp) {
                            const orderDate = new Date(timestamp);
                            const daysDiff = Math.floor((now - orderDate) / (1000 * 60 * 60 * 24));
                            const weeksDiff = Math.floor(daysDiff / 7);
                            const monthsDiff = Math.floor(daysDiff / 30);

                            const orderAmount = parseFloat(order.payment.price) || 0;

                            totalEarnings += orderAmount;

                            if (daysDiff === 0) {
                                completedRidesToday += 1;
                                todaysEarnings += orderAmount;
                            }

                            if (weeksDiff === 0) {
                                weeklyEarnings += orderAmount;
                            }

                            if (monthsDiff === 0) {
                                monthlyEarnings += orderAmount;
                            }
                        }
                    });

                    setTodaysEarnings(todaysEarnings.toFixed(2));
                    setWeeklyEarnings(weeklyEarnings.toFixed(2));
                    setMonthlyEarnings(monthlyEarnings.toFixed(2));
                    setTotalEarnings(totalEarnings.toFixed(2));
                    setCompletedRidesToday(completedRidesToday);
                }
            } catch (error) {
                console.error('Error fetching taxi orders:', error);
            }
        };

        fetchTaxiOrders()
        fetchRegisteredDrivers()
        fetchActiveDrivers();

        const intervalId = setInterval(() => {
            fetchActiveDrivers();
            fetchRegisteredDrivers();
            fetchTaxiOrders()
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <div>
            <div className="list-header">
                {renderDashboardTaxiDataSquares(
                    l10nText,
                    {margin: 0, zoom: 0.9, marginRight: -4},
                    minimumActiveDrivers,
                    activeDrivers,
                    totalRegisteredDrivers,
                    completedRidesToday)}
                |
                {renderDriversEarningsListDataSquares(
                    l10nText,
                    {margin: 0, zoom: 0.9, marginLeft: 8},
                    todaysEarnings, weeklyEarnings, monthlyEarnings, totalEarnings
                )}
            </div>
            <div className="list-header">
            </div>
        </div>
    );
};

export default TaxiDashboardTab;
