import React from "react";

import '../css/Button.css';

function Button(props) {

    let className = ' button-container button-default button-primary';
    if (props.secondary) {
        className += ' button-secondary';
    }
    if (props.add) {
        className += ' button-add';
    }
    if (props.delete) {
        className += ' button-delete';
    }
    if (props.login) {
        className += ' button-login';
    }
    if (props.confirm) {
        className += ' button-confirm';
    }
    if (props.reject) {
        className += ' button-reject';
    }
    if (props.upload) {
        className += ' button-upload';
    }
    if (props.selection) {
        className += ' button-selection'
    }
    if (props.selected) {
        className += ' button-selected'
    }
    if (props.confirm) {
        className += ' button-confirm';
    }
    if (props.disabled) {
        className += ' button-disabled'
    }
    if (props.info) {
        className += ' button-info';
    }

    return <div className={props.className + ' ' + className} style={props.style}
                onClick={() => {
                    if (!props.disabled) {
                        props.action();
                    }
                }}>
        <div className={'button'}><p style={props?.textStyle}>{props.text}</p></div>
    </div>
}

export default Button;