export default {
    background: '#F0F0F0',
    primary: '#b4aa00',
    secondary: '#000E52',
    yellow: "#FCCB1F",
    yellowDark: "#f5c213",
    green: "#7BAE37",
    redDark: "rgba(211,41,1,0.69)",
    red: "#d32901",
    title: "#09051C",
    secondaryFaded: "#f6ba9c",
    tertiary: "#b0b0b0",
    quaternary: "#949494",
    quinary: "#bebebe",
    senary: "#efefef",
    gray: "#B4B4B4",
    grayLight: "#dcdcdc",
    almostWhite: "#d9d9d9",
    white: "#fff",
    backgroundWhite: "#F5F5F5",
    semiBlack: "#333",
    black: "#000",
    disabled: "#dbdbdb",
    text: "#3E4958",
    info: "#97ADB6",
    inputBorder: "#A5A5A5",
    transparent: "transparent",
    darkSubtle: '#4B545A'
}