import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // or from '@react-navigation/native' if using React Navigation

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  const navigation = {
    onNavigateTo: (path, options) => {
      navigate(path, options);
    },
    // Add other navigation methods if needed
  };

  return (
    <NavigationContext.Provider value={navigation}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => { 
    const context = useContext(NavigationContext);
    if (!context) {
        throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return context;
};