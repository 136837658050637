import axios from "axios";
import {store} from "../store/configureStore";

const API_URL = process.env.REACT_APP_API_URL;
//const API_URL = "https://api.klikni-web.eu/api";
console.log("API URL:", API_URL)
let client = axios.create({
	baseURL: API_URL,
	timeout: 25000,
	maxBodyLength: 1000000000000,
});

client.interceptors.request.use(function (config) {
	logRequest(config);
	const state = store.getState();


	const token = state.user && state.user.access_token ? state.user.access_token : null;
	console.log("request token", token);
	if (token) {
		config.headers.Authorization = "Bearer " + token;
	}

	config.headers.Accept = "*/*";
	config.headers["Content-Type"] = "application/json";

	return config;
}, function (error) {
	return Promise.reject(error);
});

client.interceptors.response.use(
	function (response) {
		logResponse(response);
		return handleCallbacks(response.config, response, null);
	},
	function (error) {
		console.log(error)
		if (error.response) {
			logResponse(error.response);
			return handleCallbacks(error.config, null, error);
		} else if (error.request) {
			console.error("Error request:", error.request);
		} else {
			console.error("Error message:", error.message);
		}
		return Promise.reject(error);
	}
);

function logRequest(config) {
	try {
		const {
			transitional,
			adapter,
			transformRequest,
			transformResponse,
			xsrfCookieName,
			xsrfHeaderName,
			maxContentLength,
			maxBodyLength,
			env,
			...filteredConfig
		} = config;
		// console.log("REQUEST", JSON.stringify(filteredConfig, null, 2));
	} catch (error) {
		console.error("Error logging request:", error);
	}
	return config;
}

function logResponse(response) {
	try {
		// console.log("RESPONSE: ", JSON.stringify(response?.data ? response?.data : response, null, 4));
	} catch (error) {
		console.error("Error logging response:", error);
	}
	return response;
}

function handleCallbacks(config, response, error) {
	if (config && config.onResponse) {
		const { onSuccess, onError } = config.onResponse;

		if (response) {
			if (onSuccess) {
				onSuccess(response);
			}
			return response;
		} else if (error) {
			const status = error.response.status;

			if (status >= 400 && status < 500 && onError) {
				onError(error.response.data);
			} else if (status >= 500 && onError) {
				onError(error.response.data);
			} else if (onError) {
				onError(error.response.data);
			} else {
				console.error("Unhandled error status:", status);
			}
			return Promise.reject(error.response.data);
		}
	} else {
		if (response) {
			return response;
		} else if (error) {
			return Promise.reject(error.response.data);
		}
	}
}

export default client;