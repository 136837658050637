import React, {useState, useEffect, useRef} from 'react';
import {MapContainer, TileLayer, useMapEvents, Polygon, Marker, useMap} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

function Zones() {
    const [currentPoints, setCurrentPoints] = useState([]);
    const [zones, setZones] = useState([]);
    const [editingName, setEditingName] = useState(null);
    const [inputEnabled, setInputEnabled] = useState(false);
    const [isInputDisabled, setIsInputDisabled] = useState(true); // added for editing polygon name
    const [labelMarkers, setLabelMarkers] = useState(new Map());

    const mapRef = useRef(null); // Create a ref for the map object

    const [editingIndex, setEditingIndex] = useState(null);

    useEffect(() => {
        const savedZones = localStorage.getItem('zones');
        if (savedZones) {
            setZones(JSON.parse(savedZones));
        }
    }, []);

    useEffect(() => {
        updateLabelMarkers(zones);
    }, [zones]);


    const SetMapRef = () => {
        const map = useMap();
        useEffect(() => {
            mapRef.current = map;
        }, [map]);

        return null;
    };


    const saveCurrentZone = () => {
        if (currentPoints.length < 2) {
            alert("A zone must have at least two markers to be saved.");
            return;
        }

        if (editingIndex !== null) {
            const updatedZones = [...zones];
            updatedZones.splice(editingIndex, 0, {name: editingName, points: currentPoints});
            setZones(updatedZones);
        } else {
            const newName = `TAXI ZONE ${zones.length + 1}`;
            setZones([...zones, {name: newName, points: currentPoints}]);
        }
        setCurrentPoints([]);
        setEditingName(null); // Reset editing name
        setEditingIndex(null); // Reset editing index
    };


    const convertPolygonToMarkers = (polygonPoints, index) => {
        setCurrentPoints(polygonPoints);
        setEditingIndex(index);  // Set the index of the zone being edited
        setEditingName(zones[index].name); // Make sure it's fetching the name of the zone being edited
    };

    const saveCurrentZoneAndReset = () => {
        saveCurrentZone();
        setEditingName(null);
    };

    const startNewZone = () => {
        setCurrentPoints([]);
        setEditingIndex(null);
    };

    const saveZonesToFile = () => {
        const blob = new Blob([JSON.stringify(zones)], {type: 'application/json'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'zones.json';
        link.click();
    };

    const loadZonesFromFile = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            setZones(JSON.parse(e.target.result));
            localStorage.setItem('zones', e.target.result);
        };
        reader.readAsText(file);
    };

    useEffect(() => {
        localStorage.setItem('zones', JSON.stringify(zones));
    }, [zones]);

    useEffect(() => {
        const handleDeleteKey = (e) => {
            if (e.key === "Delete" && currentPoints.length > 0) {
                setCurrentPoints([]); // Clear the current points
            }
        };
        window.addEventListener('keydown', handleDeleteKey);
        return () => {
            window.removeEventListener('keydown', handleDeleteKey);
        };
    }, [currentPoints]);


    const handleNameClick = (zoneName) => {
        setEditingName(zoneName);
        setInputEnabled(true);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;

        // Update zones with the new name
        const updatedZones = zones.map(zone =>
            zone.name === editingName ? {...zone, name: newName} : zone
        );
        setZones(updatedZones);

        // Update editingName with the new name
        setEditingName(newName);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setInputEnabled(false);
            saveCurrentZone();
        }
    };

    const clearAllZones = () => {
        setZones([]);  // Clear all zones
        localStorage.setItem('zones', JSON.stringify([]));  // Update local storage
    };

    const updateLabelMarkers = (zones) => {
        // Guard clause if map instance is not yet available
        if (!mapRef.current) return;

        // Create a new Map object to hold new label markers
        const newLabelMarkers = new Map();

        // Iterate through each zone to create label markers
        zones.forEach((zone, i) => {
            // Create the label marker using the PolygonLabel function
            const labelMarker = PolygonLabel({
                positions: zone.points,
                label: zone.name,
                map: mapRef.current // pass the map instance here
            });

            // Store the new label marker in the Map
            newLabelMarkers.set(i, labelMarker);
        });

        // Clear old markers from the map
        labelMarkers.forEach((marker) => {
            if(marker) {
                marker.remove();
            }
        });

        // Set the new label markers in the state
        setLabelMarkers(newLabelMarkers);
    };


    return (
        <div className="App">
            <div className="toolbar">
                <div className="title">
                    <span className="taxi">Klikni</span>
                    <span className="zones"> Taxi</span>
                    <span className="zones"> zones</span>
                </div>
                <div className="status" style={{position: 'absolute', right: '12px', top: '55px', padding: "0 10px"}}>
                    {editingName && editingIndex !== null ? (
                        <>
                            <span style={{color: "black"}}>EDITING: </span>
                            {inputEnabled ? (
                                <input
                                    type="text"
                                    value={editingName}
                                    onChange={(event) => setEditingName(event.target.value)}
                                    onSubmit={handleNameChange}
                                    onKeyPress={handleKeyPress}
                                />
                            ) : (
                                <span onClick={() => handleNameClick(editingName)}
                                      style={{color: "gray"}}>{editingName}</span>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <button onClick={saveCurrentZone}>Complete Zone</button>
                <button onClick={saveZonesToFile}>Save Zones to File</button>
                <label htmlFor="fileInput" className="custom-file-button">
                    Upload File
                    <input id="fileInput" type="file" accept=".json" onChange={loadZonesFromFile}/>
                </label>
                <span style={{marginTop: '8px', color: 'goldenrod'}}>|</span>
                <button onClick={startNewZone}>Reset Current Markers</button>
                <span style={{marginTop: '8px', color: 'darkred'}}>|</span>
                <button onClick={clearAllZones}>Delete All Zones</button>
                {/* New Button */}
            </div>

            <div className="map-and-sidebar">
                <MapContainer
                    style={{
                        height: '100%',
                        width: 'calc(100% - 200px)'
                    }} // Adjust the width to make space for the sidebar
                    center={[46.056946, 14.505751]}
                    zoom={13}
                >
                    <SetMapRef />

                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <AddPointToPolygon setCurrentPoints={setCurrentPoints} currentPoints={currentPoints}/>
                    {zones.map((zone, i) => (
                        <>
                            <Polygon
                                key={i}
                                positions={zone.points}
                                color="darkblue"
                                eventHandlers={{
                                    contextmenu: () => {
                                        convertPolygonToMarkers(zone.points, i);
                                        setEditingName(zone.name);
                                        const updatedZones = zones.filter((_, index) => index !== i);
                                        setZones(updatedZones);
                                    }
                                }}
                            />
                            {editingIndex !== i && <PolygonLabel positions={zone.points} label={zone.name}/>}
                        </>
                    ))}
                    {currentPoints.length > 0 && (
                        <Polygon
                            positions={currentPoints}
                            color="darkblue"
                            dashArray="5, 5" // Makes the outline dashed
                            // Add more customization if needed
                            eventHandlers={{
                                contextmenu: () => {
                                    if (editingIndex !== null) {
                                        saveCurrentZone();
                                        setEditingIndex(null);  // exit editing mode
                                    } else {
                                    }
                                }
                            }}
                        />
                    )}
                    {currentPoints.map((point, i) => (
                        <CustomMarker
                            key={i}
                            index={i}
                            position={point}
                            currentPoints={currentPoints}
                            setCurrentPoints={setCurrentPoints}
                        />
                    ))}
                </MapContainer>
                <div className="zone-list">
                    <h3>List of zones ({zones.length}):</h3>
                    <ul>
                        {zones.map((zone, i) => (
                            <li key={i}>{zone.name}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );

}

function AddPointToPolygon({setCurrentPoints, currentPoints}) {
    useMapEvents({
        click: (e) => {
            setCurrentPoints([...currentPoints, e.latlng]);
        },
    });
    return null;
}

function CustomMarker({position, setCurrentPoints, index, currentPoints}) {
    const [draggable, setDraggable] = useState(false);
    const markerRef = useRef(null);
    const map = useMap();

    useEffect(() => {
        setTimeout(() => {
            map.invalidateSize();
        }, 100);
    }, []);

    const onDragEnd = (event) => {
        const newPosition = event.target.getLatLng();
        const updatedPoints = [...currentPoints];
        updatedPoints[index] = newPosition;
        setCurrentPoints(updatedPoints);
    };

    const handleDelete = (e) => {
        if (e.key === 'Delete' && draggable) {
            const updatedPoints = currentPoints.filter((_, i) => i !== index);
            setCurrentPoints(updatedPoints);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleDelete);
        return () => {
            window.removeEventListener('keydown', handleDelete);
        };
    }, [currentPoints, setCurrentPoints, index, draggable]);

    const toggleEditing = () => {
        setDraggable(!draggable);
    };

    return (
        <Marker
            ref={markerRef}
            position={position}
            draggable={draggable}
            eventHandlers={{
                dragend: onDragEnd,
                contextmenu: toggleEditing,
            }}
            icon={L.divIcon({
                className: `custom-icon ${draggable ? 'editing-icon' : ''}`,
                html: `<span style="background-color: ${draggable ? 'purple' : 'darkblue'};"></span>`,
            })}
        ></Marker>
    );
}



function PolygonLabel({positions, label, map}) {
    if (!map) {
        return null; // If map is not available, return null
    }
    // Calculate centroid
    const [lat, lng] = positions.reduce(
        ([sumLat, sumLng], { lat, lng }) => [sumLat + lat, sumLng + lng],
        [0, 0]
    ).map(sum => sum / positions.length);

    // Create custom DivIcon
    const myIcon = L.divIcon({
        className: 'my-div-icon',
        html: `<div style="color: white; background: transparent; font-size: 14px; font-weight: 600; line-height: 1rem; width: max-content; margin-left: -36px; box-shadow: 0px 0px 30px 2px darkblue; height: 2px"><p style="padding-top: 2px; background: transparent">${label}</p></div>`
    });

    // Create Marker at the centroid position
    L.marker([lat, lng], { icon: myIcon }).addTo(map);

    return null;
};




export default Zones;
