export const si = {
    CRM: {
        EDIT: 'uredi',
        SAVE: 'shrani',
        PLACEHOLDER_EMAIL: 'E-pošta',
        PLACEHOLDER_PASSWORD: 'Geslo',
        COPIED: 'Povezava kopirana',
        LOGIN_EMAIL: 'Vnesite svojo e-pošto',
        LOGIN_PASSWORD: 'Vnesite geslo',
        LOGIN_REMEMBER: 'Zapomni si me',
        LOGIN_BUTTON: 'PRIJAVA',
        NEW_PASSWORD: 'Vnesite novo geslo',
        RESET_PASSWORD_BUTTON: 'RESETIRAJ GESLO',
        PASSWORD_SUCCESSFULLY_RESET: 'Geslo je bilo uspešno resetirano',
        PASSWORD_RESET_LINK_EXPIRED: 'Povezava za resetiranje gesla je potekla. Prosimo, zahtevajte novo povezavo.',
        PASSWORD_INVALID: 'Geslo ni veljavno. Vnesite samo črke in številke, brez posebnih znakov.'
    },
    CUSTOM_MODAL: {
        driverRejected: {
            title: 'Naročilo Zavračeno',
            description: {
                default: 'Trenutni taksist je zavrnil naročilo.',
                withReason: 'Trenutni taksist je zavrnil naročilo zaradi:',
            }
        },
        rideRequirements: {
            title: 'Noben voznik ni na voljo',
            description: 'Zaradi specifičnih zahtev vašega naročila ni na voljo nobenega voznika. Ali se strinjate z privzetimi nastavitvami potovanja glede razreda in kategorije vozila?',
        },
        driverCanceled: {
            title: 'Voznik je odpovedal',
            description: {
                default: 'Trenutni taksist je odpovedal. Za vaše naročilo se dodeljuje nov voznik.',
                withReason: 'Trenutni taksist je odpovedal zaradi:',
                newDriver: 'Za vaše naročilo se dodeljuje nov voznik.',
            },
        },
        taxiTypeModal: {
            title: 'Noben voznik ni na voljo',
            description: 'Trenutno ni voznikov, ki bi ustrezali vašim specifičnim preferencam.\nIzberite eno od naslednjih možnosti:\n',
            button1Text: 'Izberi Razpoložljiv Taksij',
            button2Text: 'Ponastavi Nastavitve Vozila',
            or: 'ALI',
        },
        confirmDriverSelection: {
            title: 'Potrdite Izbiro Voznika',
            description: (driver) =>
                driver && driver.driver_id
                    ? `Izbrali ste ${driver.user.first_name} ${driver.user.last_name} z naslednjimi podatki:\n\n` +
                    `• Vozilo: ${driver.vehicles[0]?.make} ${driver.vehicles[0]?.model} (${driver.vehicles[0]?.color}), ${driver.vehicles[0]?.category}\n` +
                    `• Kapaciteta: ${driver.vehicles[0]?.capacity}\n` +
                    `• Ocena Stroškov: $${driver.estimates?.estimated_cost}\n` +
                    `• Ocena Časa: ${driver.estimates?.estimated_time} minut\n\n` +
                    `Ali želite potrditi tega voznika?`
                    : '',
        },
        pleaseWait: {
            title: 'Obdelava Vaše Zahteve',
            description: 'Vaša izbira se obdeluje. Prosimo, da počakate trenutek, medtem ko vas povežemo z voznikom, ki bo kmalu pri vas. Hvala za vaše potrpljenje!',
        },
    },
    COLORS: {
        S0B: "navaden - BELA - SVETLA",
        E0B: "kovinski - BELA - SVETLA",
        S0M: "navaden - BELA - SREDNJA",
        E0M: "kovinski - BELA - SREDNJA",
        S0D: "navaden - BELA - TEMNA",
        E0D: "kovinski - BELA - TEMNA",
        S1B: "navaden - RUMENA - SVETLA",
        E1B: "kovinski - RUMENA - SVETLA",
        S1M: "navaden - RUMENA - SREDNJA",
        E1M: "kovinski - RUMENA - SREDNJA",
        S1D: "navaden - RUMENA - TEMNA",
        E1D: "kovinski - RUMENA - TEMNA",
        S2B: "navaden - ORANŽNA - SVETLA",
        E2B: "kovinski - ORANŽNA - SVETLA",
        S2M: "navaden - ORANŽNA - SREDNJA",
        E2M: "kovinski - ORANŽNA - SREDNJA",
        S2D: "navaden - ORANŽNA - TEMNA",
        E2D: "kovinski - ORANŽNA - TEMNA",
        S3B: "navaden - RDEČA - SVETLA",
        E3B: "kovinski - RDEČA - SVETLA",
        S3M: "navaden - RDEČA - SREDNJA",
        E3M: "kovinski - RDEČA - SREDNJA",
        S3D: "navaden - RDEČA - TEMNA",
        E3D: "kovinski - RDEČA - TEMNA",
        S4B: "navaden - VIJOLIČNA - SVETLA",
        E4B: "kovinski - VIJOLIČNA - SVETLA",
        S4M: "navaden - VIJOLIČNA - SREDNJA",
        E4M: "kovinski - VIJOLIČNA - SREDNJA",
        S4D: "navaden - VIJOLIČNA - TEMNA",
        E4D: "kovinski - VIJOLIČNA - TEMNA",
        S5B: "navaden - MODRA - SVETLA",
        E5B: "kovinski - MODRA - SVETLA",
        S5M: "navaden - MODRA - SREDNJA",
        E5M: "kovinski - MODRA - SREDNJA",
        S5D: "navaden - MODRA - TEMNA",
        E5D: "kovinski - MODRA - TEMNA",
        S6B: "navaden - ZELENA - SVETLA",
        E6B: "kovinski - ZELENA - SVETLA",
        S6M: "navaden - ZELENA - SREDNJA",
        E6M: "kovinski - ZELENA - SREDNJA",
        S6D: "navaden - ZELENA - TEMNA",
        E6D: "kovinski - ZELENA - TEMNA",
        S7B: "navaden - SIVA - SVETLA",
        E7B: "kovinski - SIVA - SVETLA",
        S7M: "navaden - SIVA - SREDNJA",
        E7M: "kovinski - SIVA - SREDNJA",
        S7D: "navaden - SIVA - TEMNA",
        E7D: "kovinski - SIVA - TEMNA",
        S8B: "navaden - RJAVA - SVETLA",
        E8B: "kovinski - RJAVA - SVETLA",
        S8M: "navaden - RJAVA - SREDNJA",
        E8M: "kovinski - RJAVA - SREDNJA",
        S8D: "navaden - RJAVA - TEMNA",
        E8D: "kovinski - RJAVA - TEMNA",
        S9B: "navaden - ČRNA - SVETLA",
        E9B: "kovinski - ČRNA - SVETLA",
        S9M: "navaden - ČRNA - SREDNJA",
        E9M: "kovinski - ČRNA - SREDNJA",
        S9D: "navaden - ČRNA - TEMNA",
        E9D: "kovinski - ČRNA - TEMNA"
    },
    ALLERGENS: {
        1: "Gluten",
        2: "Raki",
        3: "Jajca",
        4: "Ribe",
        5: "Arašidi",
        6: "Soja",
        7: "Mleko",
        8: "Oreščki",
        9: "Zelena",
        10: "Gorčica",
        11: "Sezam",
        12: "Sulfiti",
        13: "Lupina",
        14: "Školjke"
    },
    RESPONSES: {
        errorUnexpected: "An unexpected error occurred. Please try again.",
        passwordRequirements: "Your password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one number.",
        passwordsDoNotMatch: "Passwords do not match.",
    },
    ADD_CONTACT: {
        failedToSendCode: 'Pošiljanje verifikacijske kode ni uspelo. Poskusite znova.',
        invalidVerificationCode: 'Neveljavna verifikacijska koda. Poskusite znova.',
        resetPasswordSuccessText: 'poslali smo vam e-pošto z navodili za ponastavitev gesla.',
        requestPasswordReset: 'Zahtevaj ponastavitev gesla',
        resetPassword: 'Ponastavi geslo',
        failedToVerify: 'Preverjanje kode ni uspelo. Poskusite znova.',
        enterCode: "Vnesite kodo",
        enterPhone: "Vnesite telefonsko številko",
        verifyCode: "Preveri kodo",
        sendCode: 'Pošlji kodo',
        thankYou: 'Hvala!',
        close: 'Zapri',
        verifyPhone: "Preveri telefon",
        vehicleClass: "Izberite razred vozila",
        vehicleCategory: "Izberite kategorijo vozila",
        restaurantTitle: "Dodaj kontaktno osebje",
        taxiTitle: "Dodaj voznike",
        transferTitle: "Dodaj voznike",
        addContact: "Dodaj kontakt",
        addDriver: "Dodaj voznika",
        companyRole: "Vloga v podjetju",
        fName: "Ime",
        lName: "Priimek",
        phoneNumber: "Telefonska številka",
        email: "email",
        operatingAddress: "Naslov delovanja",
        birth: "Datum rojstva",
        day: "Dan",
        month: "Mesec",
        year: "Leto"
    },
    GENERAL: {
        standard: 'standard',
        confirm: "Potrdi",
        continue: "Nadaljuj",
        cancel: "Prekliči",
        save: "Shrani",
        rate: "Oceni",
        skip: "Preskoči",
        finish: "Končaj",
        checkout: "Pojdi na blagajno",
        select: "Izberi",
        addYourAddress: "Dodaj svoj naslov",
        ok: "V redu",
        writeYourMessage: "Napiši sporočilo tukaj...",
        close: "Zapri",
        remove: "Odstrani",
        signOut: "Odjava",
        signIn: "Prijava",
        signUp: "Registracija",
        yes: "Da",
        no: "Ne",
        male: "Moški",
        female: "Ženska",
        request_sms_code: "Zahtevaj Kodo"
    },
    LANGUAGE_SELECT: {
        title: 'Izberite jezik',
        confirm: 'Izberi'
    },
    SIGN_UP: {
        title: "Registracija",
        fName: "Ime",
        lName: "Priimek",
        email: "E-pošta",
        phoneNumber: "Telefonska številka",
        password: "Geslo",
        signUp: "Registriraj se",
        signIn: "Prijavi se",
        existingAccount: "Že imate račun?",
    },
    SIGN_IN: {
        rememberMe: "Zapomni si me",
        validationError: 'Napaka pri validaciji',
        enterValidData: 'Prosimo, vnesite veljaven e-poštni naslov in geslo',
        title: 'Prijava',
        emailOrPhone: 'E-pošta ali telefon',
        password: 'Geslo',
        signIn: 'Prijava',
        signUp: 'Registracija',
        noAccount: "Nimate računa?",
        forgotPassword: "Pozabljeno geslo?"
    },
    REGISTRATION: {
        title: 'Dobrodošli',
        profilePicture: 'Dodaj sliko profila',
        address: 'Naslov (neobvezno)',
        city: 'Mesto (neobvezno)',
        options: 'Izberite možnosti',
        pet: 'Potovanje s hišnim ljubljenčkom',
        luggage: 'Imam prtljago',
        ac: 'Klimatska naprava',
        child: 'Otroški sedeži',
        wheelchair: 'Dostop za invalidske vozičke',
        noRadio: 'Tiho potovanje',
        assistance: 'Pomoč potnikom',
        saveButton: 'Shrani'
    },
    CANCEL_ORDER: {
        title: "Prekliči naročilo",
        description: "Prosimo, izberite razlog za preklic naročila:",
        confirmButton: "Potrdi",
        cancelButton: "Prekliči",
        cancelOrderButton: "Prekliči naročilo",
        cancellationReasonTitle: "Razlog za preklic",
        reasons: {
            changed_mind: "Premislil sem si",
            found_alternative: "Našel sem alternativo",
            driver_too_long: "Voznik predolgo traja",
            incorrect_pickup: "Napačna lokacija prevzema",
            other: "Drugo"
        },
        taxiCanceledTitle: "Naročilo preklicano s strani Klikni Taxi",
    },
    NOTIFICATION_PREFERENCES: {
        titleWhich: "Katere obvestila želite prejemati?",
        requests: "Zahtevki za vožnjo",
        acceptance: "Sprejetje vožnje",
        driverArrival: "Prihod voznika",
        completion: "Končanje vožnje",
        promos: "Promocije in popusti*",
        ratings: "Ocene in povratne informacije",
        news: "Novice in posodobitve",
        titleHow: "Na kakšen način želite biti obveščeni?",
        telephone: "Telefon",
        whatsapp: "WhatsApp",
        viber: "Viber",
        telegram: "Telegram",
        wechat: "WeChat",
        saveButton: "Shrani"
    },
    TAXI_SELECTION_MAP: {
        titleWhere: "Kam želite iti?",
        titleAddStopover: "Dodaj postanek",
        stopover: "Lokacija postanka",
        stopoverPlaceholder: "Nastavite lokacijo postanka",
        pickUp: "Lokacija prevzema",
        pickUpPlaceholder: "Nastavite lokacijo prevzema",
        dropOff: "Lokacija izstopa",
        dropOffPlaceholder: "Nastavite lokacijo izstopa",
        continueButton: "Nadaljuj",
        estimates: "Ocene",
        cca: "cca.",
        travelTime: "Čas potovanja",
        price: "Cena",
        enterpickupLocation: "Vnesite lokacijo prevzema",
        deleteStop: "Ali želite izbrisati izbrani postanek?",
        sureToDelete: "Ali res želite izbrisati ta postanek?",
        deleteText: "Izbriši",
        addressNotRecognized: "Naslov ni prepoznan",
        provideValid: "Prosimo, vnesite veljaven naslov",
        addStop: "Dodaj postanek",
    },
    TAXI_SELECTION_MODAL: {
        title: "Taksi v vašem območju",
        estTime: "Približni čas do prihoda",
        stationWagon: "Kombi",
        sedan: "Limuzina",
        minivan: "Enoprostorec",
        suv: "SUV",
    },
    BOOK_TAXI: {
        taxiOnItsWay: 'Taksi je na poti',
        title: "Izberite zahteve vožnje",
        titleReview: "Preglejte zahteve vožnje",
        numAdults: "Število odraslih",
        numChildrenSmall: "Število otrok (<140cm)",
        numChildrenBig: "Število otrok (>140cm)",
        requirements: "Zahteve",
        scheduleRideLabel: "Ali želite načrtovati vožnjo s taksijem?",
        scheduleRide: "Načrtujte vožnjo",
        findTransferButton: "Poiščite prevoz",
        luggage: "Prtljaga",
        children: "Otroci",
        wheelchair: "Dostop za invalidske vozičke",
        pet: "Hišni ljubljenčki dobrodošli",
        ac: "Klimatska naprava",
        noRequirements: "Ni nastavljenih dodatnih zahtev.",
        departureDate: "Nastavite datum odhoda",
        departureDatePlaceholder: "Datum odhoda",
        departureTime: "Nastavite čas odhoda",
        departureTimePlaceholder: "Čas odhoda",
        repeatRide: "PONAVLJAJTE VOŽNJO",
        doNotRepeat: "Ne ponavljajte",
        monday: "Vsak ponedeljek",
        tuesday: "Vsak torek",
        wednesday: "Vsako sredo",
        thursday: "Vsak četrtek",
        friday: "Vsak petek",
        saturday: "Vsako soboto",
        sunday: "Vsako nedeljo",
        repeatDuration: "Trajanje ponavljanja",
        enaTeden: "1 teden",
        dvaTedna: "2 tedna",
        triTedne: "3 tedne",
        enMesec: "1 mesec",
        backButton: "Nazaj",
        requestRideButton: "Zahtevaj vožnjo s taksijem",
        timeArrival: "Čas do prihoda (cca)",
        travelTime: "Čas potovanja",
        cca: "cca"
    },
    PENDING_RIDE: {
        cca: "cca.",
        travelTime: "Čas potovanja",
        timeArrival: "Čas prihoda",
        cancellation: "V primeru odpovedi vam bomo zaračunali 10 €",
        confirmButton: "Potrdi",
    },
    TAXI_RIDE: {
        labelArrived: "Your taxi has arrived",
        labelThanks: "Thank you for riding with us",
        labelDriving: "Driving to next destination",
        taxiRide: "Taxi ride",
        labelTaxiWaiting: "Your taxi has arrived",
        labelTaxiAccepted: "Taxi is driving to your location. Be ready.",
    },
    TAXI_RIDE_BOTTOM: {
        didYouEnjoy: 'Ste uživali v izkušnji z taksijem?',
        drivingSkills: "Vozniške spretnosti",
        professionalism: "Profesionalnost",
        vehicleCondition: "Stanje vozila",
        knowledgeRoutes: "Poznavanje poti",
        customerService: "Storitve za stranke",
        punctuality: "Točnost",
        opinionPlaceholder: "Napišite svoje mnenje tukaj...",
        rate: "Oceni",
        skip: "Preskoči",
        totalCost: "Skupni stroški",
        tip: "Napitnina",
        pay: "Plačaj",
        cancel: "Prekliči",
        modalTitle: "Če ste uživali v izkušnji, pomislite na napitnino, da pokažete svojo hvaležnost. Vaša velikodušnost je zelo cenjena.?",
    },
    PAYMENT_SELECT: {
        enterExactAmount: 'Vnesite točen znesek',
        selectPayment: "Izberi plačilno metodo",
        cash: "Gotovina",
        exactAmount: 'Plačam z natančnim zneskom.',
        changeNeeded: 'Potrebna menjava.',
        tapToAddValues: 'Dotaknite se, da dodate vrednosti.',
        amountDue: 'Znesek',
        change: 'Znesek vračila:'
    },
    RECEIPT: {
        thankYou: "Hvala, ker ste potovali z nami",
        thankYouDelivery: "Hvala za vaše naročilo",
        pickup: "Prevzem v",
        deliveryFee: "Stroški dostave",
        deliverTo: "Dostava na",
        discount: "Popust",
        subtotal: "Delna vsota",
        total: "Skupaj (z vključenim davkom)",
        payment: "Plačilo",
        delivery: "Dostava",
        tipAmount: "Znesek napitnine",
        vat: "DDV",
        finishButton: "Končaj",
        trackButton: "Sledi svojemu naročilu"
    },
    SEARCHING_DRIVER: {
        searching: "Čakam na odziv razpoložljivega voznika"
    },
    TAXI_INCOMING: 'Taksi je na poti',
    TAXI_ARRIVED: 'Taksi je prispel',
    EN_ROUTE: 'Na poti',
    RIDE_COMPLETE: 'Vožnja zaključena',
    RATE_DRIVE: 'Oceni vožnjo',
    SELECT_PAYMENT: 'Izberi plačilo',
    WELCOME_SCREEN: {
        title: "Dobrodošli",
        services: "Storitve",
        chooseService: "Na začetnem zaslonu lahko izberete želeno storitev tudi sami."
    },
    DELIVERY_START: {
        title: "Dobrodošli, za kaj se odločamo danes?",
        restaurantBars: "Restavracije in bari",
        scheduledMeals: "Načrtovani obroki",
        klikniMarket: "Klikni market",
        catering: "Ketering"
    },
    SCHEDULED_MEALS: {
        title: "Izberite trajanje dostave načrtovanih obrokov",
        optionOne: "7-14 dni",
        optionTwo: "15+ dni",
    },
    DIETARY_GUIDELINES: {
        title: "Sledite kakšnim posebnim prehranskim smernicam?",
        gallbladder: 'Žolčnik',
        glutenFree: 'Brezglutensko',
        fit: 'Fit',
        vegetarian: 'Vegetarijansko',
        vegan: 'Vegansko',
        diabetes: 'Diabetes',
        diverses: 'Različno',
        balanced: 'Uravnoteženo',
        dietaryPlan: 'Prehranski načrt',
    },
    FOOD_SELECTION: {
        searchPlaceholder: "Kaj bi želeli naročiti",
        searchingNear: "Iskanje blizu",
        addAddress: "Dodajte svoj naslov",
        continueButton: "Naprej"
    },
    CATEGORIES: {
        fastFood: "Hitra hrana",
        pizza: "Pizza",
        burger: "Hamburgerji",
        salads: "Solate",
        soups: "Juhe",
        vegetarian: "Vegetarijansko",
        vegan: "Vegansko",
        bakery: "Pekarne",
        sandwiches: "Sendviči",
        bbq: "Žar",
        steak: "Steaki",
        chicken: "Piščanec",
        kebab: "Kebabi",
        fish: "Ribe",
        sushi: "Sushi",
        bowl: "Solate v skledi",
        seafood: "Morska hrana",
        risotto: "Rižote",
        italian: "Italijansko",
        mexican: "Mehiško",
        chinese: "Kitajsko",
        japanese: "Japonsko",
        thai: "Tajsko",
        korean: "Korejsko",
        indian: "Indijsko",
        arabic: "Arabsko",
        kosher: "Košer",
        halal: "Halal",
        breakfast: "Zajtrk",
        lunch: "Kosilo",
        gourmetCuisine: "Gurmanska kuhinja",
        traditional: "Tradicionalno",
        healthy: "Zdravo",
        snacksExtras: "Prigrizki in dodatki",
        desserts: "Sladice",
        iceCream: "Sladoled",
        hotDrinks: "Kava in čaj",
        pubs: "Pube",
        wine: "Vino",
        cocktail: "Koktajli",
        drinks: "Brezalkoholne pijače",
        kidsMeals: "Otroski obroki"
    },
    DELIVERY_HOME: {
        addressAdd: "Dodaj svoj naslov",
        deliverTo: "Dostavite na",
        orderFrom: "Naročite iz svojih najljubših restavracij",
        searchPlaceholder: "Kaj bi želeli naročiti?",
        newRestaurants: "Nove restavracije na Klikni",
        popularNow: "Priljubljeno zdaj",
        favoriteRestaurants: "Moje najljubše",
        restaurants: "Trenutne ponudbe restavracij",
        discountRestaurants: "Dejanske ponudbe",
        scheduledMealRestaurants: "Samo načrtovani obroki"
    },
    RESTAURANT: {
        open: "Odpri",
        closes: "Zapre se ob",
        delivery: "Dostava v",
        seats: "Sedeži",
        people: "ljudje",
        bookTable: "Rezervirajte mizo",
        searchPlaceholder: "Išči...",
        popular: "Priljubljeno",
        new: "Novo",
        discount: "Popust",
        titleOpenOrder: "Želite nadaljevati s svojim naročilom?",
        descriptionOpenOrder: "V košarici imate shranjene izdelke za to restavracijo. Lahko nadaljujete s tem naročilom ali začnete novo.",
        button1: "Nadaljujte z naročilom",
        button2: "Prekliči",
        noMatchFound: "Vaše iskanje ni našlo nobenih jedi v našem meniju. Poskusite znova z drugimi ključnimi besedami."
    },
    FOOD_ITEM_MODAL: {
        ingredients: "Sestavine",
        extras: "Dodatki",
        sides: "Priloge",
        specialRequest: "Posebna zahteva",
        placeholder: "Vaše sporočilo tukaj...",
        addToOrder: "Dodaj v naročilo",
        noExtras: "Ne želim dodatkov"
    },
    ORDER_DETAILS: {
        title: "Podrobnosti naročila",
        addItems: "Dodajte izdelke, da začnete košarico",
        basket: "Ko dodate izdelke iz restavracije ali trgovine, se bo vaša košarica pojavila tukaj.",
        startShopping: "Začni nakupovanje",
        checkoutButton: "Pojdi na blagajno"
    },
    ORDER_ITEM: {
        removeItem: "Odstranite ta izdelek?",
        comment: "Opomba restavraciji",
        extras: "Dodatki",
        sides: "Priloge",
        placeholderDate: "Datum dostave",
        placeholderTime: "Čas dostave",
        addYourAddress: "Dodajte svoj naslov",
        with: "Z",
        added: "dodano"
    },
    VIEW_ORDER: "Pogled naročila",
    ORDER_DETAILS_BOTTOM: {
        subtotal: "Delna vsota",
        deliveryFee: "Stroški dostave",
        total: "Skupna cena",
        discount: "Popust"
    },
    CHECKOUT: {
        title: "Blagajna",
        deliverTo: "Dostavite na",
        addYourAddress: "Dodaj svoj naslov",
        instructions: "Navodila za kurirja",
        placeholder: "Vaše opombe za dostavljavca...",
        placeOrder: "Potrdi naročilo"
    },
    DELIVERY_OPTIONS: {
        deliver: "Dostava",
        pickup: "Prevzem"
    },
    TRACK_ORDER: {
        contactRestaurant: 'Kontaktirajte restavracijo',
        pickup: 'Predviden čas za prevzem',
        track: "Sledi naročilu",
        estimated: "Predviden čas dostave",
        contactDriver: "Kontaktirajte voznika",
        success: "Naročilo uspešno dostavljeno",
        enjoy: "Uživajte ob obroku",
        finish: "Končaj",
        delivered: "Dostavljeno",
        min: "min",
    },
    SERVICES: {
        taxi: "Taksi",
        transfer: "Transfer",
        delivery: "Dostava",
        deposit: "Depozit",
    },
    FOOD_SELECTION_DETAILS: {
        title: "Iskanje",
        placeholder: "Kaj bi želeli naročiti?",
        categories: "Kategorije",
        distance: "Razdalja",
        availableRestaurants: "Razpoložljive restavracije",
        noResult: "Ni relevantnih rezultatov iskanja",
        result: "Najdeni menijski elementi glede na vaše iskanje"
    },
    SCHEDULED_ORDER_DETAILS: {
        title: "Vaše naročilo",
        addMore: "Dodajte več s spodnjim gumbom",
        messageForRestaurant: "Dodajte sporočilo za restavracijo",
        placeholder: "Vaše sporočilo tukaj...",
        checkoutButton: "Pojdi na blagajno",
        addToStart: "Dodajte izdelke, da začnete košarico",
        basket: "Ko dodate izdelke iz restavracije ali trgovine, se bo vaša košarica pojavila tukaj.",
        startShopping: "Začni nakupovanje"
    },
    TRANSFER_BOOKING_BOTTOM: {
        private: 'Zasebno',
        shuttle: 'Prevoz',
        departureDatePlaceholder: "Datum odhoda",
        returnDatePlaceholder: "Datum vrnitve",
        numberOfAdults: "Število odraslih",
        numberOfChildren: "Število otrok (1-7)",
        numberOfChildrenBig: "Število otrok (7-12)",
        findTransfer: "Poišči prenos",
        requirements: "ZAHTEVE",
        wifi: "Wi-Fi",
        outlets: "Vtičnice",
        onBoardRestrooms: "Stranišča na krovu",
        luggage: "Shramba za prtljago",
        ac: "Klimatska naprava",
        multilingual: "Večjezično osebje",
    },
    AVAILABLE_TRANSFERS: "Razpoložljivi prenosi",
    TRANSFER_TICKET: {
        direct: "Direktno",
        stop: "Postanek",
        stops: "Postanki",
        buy: "Kupi",
        modalQRTitle: "Vaša QR vozovnica",
        qrDescription: "Na dan prenosa pokažite svoj QR kodo vozniku.",
        rateTrip: "Ocenite vožnjo",
        modalRateTitle: "Ocenite to vožnjo",
        rateDescription: "Kaj vam je bilo všeč ali ni všeč glede na zagotovljen prenos?",
        noHistory: "Še niste kupili nobenih vozovnic."
    },
    TRANSPORT_DETAILS: {
        buy: "Kupi vozovnico",
        infoMissing: "Na vašem profilu manjka nekaj informacij, preden lahko nadaljujete z nakupom vozovnice."
    },
    TRANSFER_LIST: {
        duration: "Trajanje postanka",
        pet: "Prijazno za hišne ljubljenčke",
        wifi: "Brezplačen Wi-Fi"
    },
    CONTACT_DETAILS: "Kontaktni podatki",
    REVIEW_PAY: {
        title: "Pregled in plačilo",
        subtotal: "Delna vsota",
        discount: "Popust",
        total: "Skupaj",
        pay: "Plačaj",
        success: "Plačilo je bilo uspešno",
        descriptionSuccess: "Svojo vozovnico lahko najdete pod zavihkom Moja potovanja.",
        myTrips: "Moja potovanja",
        failed: "Plačilo ni uspelo!",
        descriptionFail: "Prosimo, posodobite svojo plačilno metodo."
    },
    MY_TRIPS: {
        title: "Moja potovanja",
        upcoming: "Prihajajoče",
        history: "Zgodovina",
    },
    LOCATION_MODAL: {
        location: "Lokacija",
        savedLocations: "Shranjene lokacije",
        current: "Trenutna lokacija",
        addAddress: "Dodaj naslov",
        enterAddress: "Vnesite svoj naslov",
    },
    SIDE_MENU: {
        yourMessageHere: 'Vaše sporočilo tukaj...',
        notifyMe: 'Obvesti me',
        settings: "Nastavitve",
        preferences: "Prednosti",
        notifications: "Obvestila",
        accountCards: "Račun in kartice",
        rideHistory: "Zgodovina vožnje",
        paymentHistory: "Zgodovina plačil",
        defaultService: "Privzeta storitev",
        wallet: "Denarnica",
        family: "Družina",
        business: "Posel",
        support: "Podpora",
        reportLostItem: "Prijavi izgubljen predmet",
        language: "Jezik",
        myProfile: "Moj profil",
        transferHistory: "Moja potovanja",
        chooseTheRadio: "Izberite radio",
        contacts: "Stiki",
        howSpicyTitle: "Kako pekoče bi želeli vaše obroke",
        whichNotificationsTitle: "Katera obvestila bi želeli prejemati?",
        allergiesTitle: "Vaša varnost je pomembna! Povejte nam o morebitnih alergijah, da vam lahko ponudimo primerne možnosti hrane.",
        allergies: "Alergije",
        orderHistory: "Zgodovina naročil",
        selectService: "Izberite storitev",
        languages: "Jeziki",

    },
    SUPPORT: {
        title: "Podpora",
        faq: "Pogosto zastavljena vprašanja",
        contact: "Kontaktiraj nas",
        lostItems: "Izgubljeni predmeti",
        accident: "Vpleten sem bil v nesrečo",
        privacyPolicy: "Pravilnik o zasebnosti in ToS"
    },
    FAQ: {
        title: "Pogosta vprašanja",
        account: "Račun",
        unblock: "Odblokiraj račun",
        changeNumber: "Spremeni telefonsko številko",
        paymentPricing: "Plačilo in cene",
        acceptedPayment: "Sprejeta plačilna sredstva",
        estimation: "Ocenjevanje cene",
        taxiTax: "Taksi kilometrski davek",
        deliveryTax: "Davek za dostavo kilometrov",
        transferTax: "Prevozni kilometrski davek",
        fee: "Prispevek",
        cancellationFee: "Prispevek za preklic vožnje",
        damageFee: "Prispevek za škodo ali čiščenje",
        highwayFee: "Prispevek za avtocesto in predor",
    },
    CONTACT_US: {
        title: "Kontaktiraj nas",
        name: "Ime",
        lName: "Priimek",
        email: "E-naslov",
        subject: "Predmet",
        message: "Sporočilo",
        submit: "Pošlji",
        success: "Sporočilo je bilo uspešno poslano!",
        description: "Hvala vam za stik z nami! Vaše sporočilo je bilo poslano našemu podpornemu oddelku in kmalu boste prejeli odgovor."
    },
    LOST_ITEM: {
        title: "Prijavi izgubljeni predmet",
        item: "Opis predmeta",
        itemDescription: "Prosimo, da zagotovite podroben opis izgubljenega predmeta, da nam pomagate pri identifikaciji. Vključite vse posebne značilnosti, kot so barva, velikost, znamka in kje mislite, da ste ga pustili.",
        placeholder: "Opis vašega izgubljenega predmeta...",
        indicate: "Označite zadnji datum in čas, ko se spomnite, da ste imeli predmet pri sebi. Te informacije so ključne za učinkovito iskanje.",
        lastSeenDate: "Zadnjič viden datum",
        lastSeenTime: "Zadnjič viden čas",
        addPhoto: "Dodaj fotografijo",
        optionally: "Po želji dodajte fotografijo predmeta ali njegove zadnje znane lokacije",
        report: "Prijavi izgubljeni predmet"
    },
    ACCIDENT: {
        title: "Prijavi nesrečo",
        involved: "Bil sem udeležen v nesreči",
        description: "Če ste bili nedavno udeleženi v trčenju med uporabo naših taksi storitev, vas spodbujamo, da incident takoj prijavite. Zagotovite nam kratek opis incidenta. Prosimo, da vključite ustrezne podrobnosti, kot so datum, čas in lokacija nesreče, ter vse dodatne informacije, ki bi nam lahko pomagale pri razumevanju situacije. Naša ekipa bo hitro pregledala vaše poročilo in sprejela ustrezne ukrepe za reševanje zadeve. Vaša varnost in dobro počutje sta nam zelo pomembna, in zavezani smo, da vam nudimo potrebno podporo v tem času.",
        date: "Datum",
        time: "Čas",
        place: "Kraj",
        hurt: "Ste bili poškodovani",
        yes: "Da",
        no: "Ne",
        how: "Kako je prišlo do nesreče?",
        submit: "Pošlji"
    },
    BOOK_TABLE: {
        showQRCode: "Pokažite vašo QR kodo osebju restavracije, da potrdite vašo aktivno rezervacijo.",
        at: "ob",
        on: "na",
        pendingRes: "Imate čakajočo rezervacijo ob",
        acceptedRes: "Imate čakajočo rezervacijo ob",
        thankYouTableReservation: "Hvala za vašo registracijo, kmalu vam bomo odgovorili glede statusa vaše registracije.",
        title: "Rezervacija mize",
        howMany: "Koliko gostov?",
        whatTime: "Ob kateri uri?",
        date: "Kateri datum?",
        submit: "Pošlji"
    },
    SIDE_MENU_OPTIONS: {
        dairyFree: "Brez mleka",
        passengerUpdates: "Posodobitve za potnike",
        inAppMessaging: "Sporočanje v aplikaciji",
        promotionsAndDiscounts: "Promocije in popusti",
        ratingsAndFeedbacks: "Ocene in povratne informacije",
        driverPerformance: "Uspešnost voznika",
        accountAndPaymentNotifications: "Obvestila o računu in plačilih",
        systemUpdatesAndMaintenance: "Posodobitve sistema in vzdrževanje",
        emergencyOrCriticalAlerts: "Nujna ali kritična obvestila",
        spicy: "Začinjeno",
        vegetarian: "Vegetarijansko",
        vegan: "Vegansko",
        nutFree: "Brez oreščkov",
        glutenFree: "Brez glutena",
        diaryFree: "Brez dnevnika",
        organic: "Ekološko",
        notSpicy: "Nezačinjeno",
        mild: "Blago",
        hot: "Pekoče",
        veryHot: "Zelo pekoče",
        orderConfirmation: "Potrditev naročila",
        orderUpdates: "Posodobitve naročila",
        deliveryArrival: "Prihod dostave",
        realTimeTracking: "Sledenje naročilu v realnem času",
        promosDiscounts: "Promocije in popusti",
        paymentConfirmation: "Potrditev plačila",
        ratingFeedback: "Ocene in povratne informacije",
        newsUpdates: "Novice in posodobitve",
        accUpdates: "Posodobitve računa",
        wifi: "Wi-Fi",
        outlets: "Vtičnice",
        onBoardRestrooms: "Stranišča na krovu",
        luggage: "Shramba za prtljago",
        ac: "Klimatizacija",
        multilingual: "Večjezično osebje",
        departureReminders: "Opomniki za odhod",
        realTimeUpdates: "Posodobitve v realnem času",
        bookingConfirmation: "Potrditev rezervacije",
        specialOffers: "Posebne ponudbe in promocije",
        news: "Novice",
        pet: "Potovanje s hišnim ljubljenčkom",
        emptyTrunk: "Prazen prtljažnik",
        nonSmoking: "Nepokajen",
        childSeats: "Otroški sedeži",
        accessibility: "Dostopnost",
        quiet: "Tih prevoz",
        rideRequest: "Zahteve za prevoz",
        rideAcceptance: "Sprejem prevoza",
        driverArrival: "Prihod voznika",
        rideCompletion: "Dokončanje prevoza",
        driversChoice: "Izbira voznika",
        wheelchair: "Dostopnost za invalidske vozičke",
        noRadio: "Tiha vožnja",
        assistance: "Pomoč potnikom"
    },
    PROVIDER_DETAILS: {
        address: "Naslov",
        openingHours: "Odpiralni čas",
        telephone: "Telefonska številka",
        deliveryInfo: "Informacije o dostavi",
        delivery_cost: "Strošek dostave",
        estimated: "Ocenjeni čas dostave",
        contactSupport: "Podpora za stik"
    },
    OPENING_HOURS: {
        mon: "Ponedeljek",
        tue: "Torek",
        wed: "Sreda",
        thu: "Četrtek",
        fri: "Petek",
        sat: "Sobota",
        sun: "Nedelja",
        closed: "Zaprto"
    },
    LEGAL_TERMS: {
        title: "Pravni pogoji",
        tos: "Pogoji uporabe",
        privacy: "Pravilnik o zasebnosti",
        generalTerms: "Splošni pogoji sporazuma"
    },
    MY_PROFILE: {
        title: "Moj profil",
        detailsMustMatch: "Ti podatki morajo ustrezati potnemu listu in fotografiji osebne izkaznice",
        fName: "Ime",
        lName: "Priimek",
        phone: "Telefonska številka",
        email: "E-naslov",
        nationality: "Državljanstvo",
        dob: "Datum rojstva",
        passportNum: "Številka potnega lista",
        issuingCountry: "Država izdajateljica",
        expiryDate: "Datum veljavnosti",
        male: "Moški",
        female: "Ženska"
    },
    PROFILE_SCREEN: {
        fullName: "Polno ime",
        tapToSet: "Tapnite za nastavitev",
        telephone: "Telefon",
        emailNotSet: "E-naslov ni nastavljen",
        homeAddress: "Domači naslov",
        workAddress: "Delovni naslov",
        schoolAddress: "Šolski naslov",
        chosenLanguage: "Izbrani jezik",
        langNotSet: "Jezik ni nastavljen",
        password: "Geslo",
        cards: "Kartice",
        deleteAccount: "Izbriši račun",
        change: "Spremeni",
        email: "E-naslov",
        title: "Moj profil"
    },
    RIDE_HISTORY: {
        title: "Zgodovina voženj",
        noFamilyHistory: "Noben družinski član še ni imel zgodovine voženj.",
        totalSpent: "Skupni znesek"
    },
    PAYMENT_HISTORY: {
        title: "Zgodovina plačil",
        noPaymentHistory: "Noben družinski član še nima zgodovine plačil."
    },
    WALLET: {
        title: "Denarnica",
        currBalance: "Trenutno stanje",
        addFunds: "Dodaj sredstva",
        paymentMethod: "Načini plačila",
        addPaymentMethod: "Dodaj način plačila",
        managePaymentMethod: "Upravljanje načina plačila",
    },
    CAR_TYPES: {
        sedan: "Limuzina",
        minivan: "Minivan",
        suv: "SUV",
        caravan: "Kombi",
        van: "Kombi",
        minibus: "Minibus",
        bus: "Avtobus"
    },
    TAXI_OPTIONS: {
        start: "Začetek",
        perKm: "Na km",
        perMin: "Na min"
    },
    FAMILY: {
        title: "Družina",
        description: "Aplikacija vam omogoča enostavno upravljanje in pomoč vašim bližnjim pri prevozih in dostavah. Dodate lahko družinske člane in prijatelje na vaš račun, kar omogoča enostavno naročanje prevozov ali organiziranje dostav v njihovem imenu."
    },
    BAN_REMOVE_USER: {
        swipeLeft: "Povlecite levo za več možnosti",
        areYouSureRemove: "Ali ste prepričani, da želite odstraniti uporabnika?",
        invitationProcess: "Ponovno boste morali skozi postopek povabila.",
        no: "Ne",
        yes: "Da",
        banUser: "Prepovedati uporabnika?",
        areYouSureBan: "Ali ste prepričani, da želite prepovedati tej osebi dostop do vaše denarnice?",
        addMember: "Dodaj člana"
    },
    ADD_MEMBER: {
        title: "Dodaj člana",
        addPicture: "Dodaj profilno sliko",
        fName: "Ime",
        lName: "Priimek",
        email: "E-naslov",
        phoneNumber: "Telefonska številka",
        signUp: "Registriraj se",
        titleModal: "Nov družinski član dodan",
        descriptionModal: "Nov družinski član uspešno dodan."
    },
    BUSINESS: {
        title: "Poslovanje",
        description: "Podjetja lahko vnesejo svoje stranke v sistem, kar omogoča enostavno organizacijo prevozov v njihovem imenu. Omogoča priročen način upravljanja prevoznih potreb za njihove stranke, kar zagotavlja nemoteno in učinkovito izkušnjo."
    },
    LANGUAGES: {
        en: "Angleščina",
        si: "Slovenščina",
        de: "Nemščina",
        fr: "Francoščina",
        esp: "Španščina",
        it: "Italijanščina",
        srb: "Srbščina",
        bih: "Bosanščina",
        cro: "Hrvaščina",
        ru: "Ruščina"
    },
    SIDE_MENU_NOTIFICATIONS: {},
    ADD_CREDIT_CARD: {
        title: "Dodaj kreditno kartico",
        addCard: "Dodaj kartico",
        cardNumber: "Številka kartice",
        cardExpiry: "Datum veljavnosti",
        cardCvv: "CVV",
        cardHolder: "Ime imetnika kartice",
        scanCard: "Skeniraj kartico",
        addPaymentMethod: "Dodaj način plačila",
        managePaymentMethod: "Upravljanje načina plačila",
        infoMissing: "Manjkajo nekateri podatki. Prosimo, izpolnite vsa polja za dodajanje načina plačila."
    },
    ADD_FUNDS: {
        title: "Dodaj sredstva",
        selectPayment: "Izberite način plačila",
        sendMoney: "Pošlji denar",
        infoMissing: "Manjkajo nekateri podatki v vašem profilu, posodobite jih, preden lahko nadaljujete z nakupom vozovnice.",
        usd: "USD",
        eur: "EUR",
        gbp: "GBP",
        enterAmount: "Vnesite znesek"
    },
    BOOKING_ADDRESS: {
        addStop: "Dodaj postanek",
        destinationUnknown: "Destinacija trenutno še ni znana"
    }
}