import React, {useEffect, useState} from 'react';
import { getLocalisedTexts } from "../../../l10n/localisations/languages";
import DynamicTable from "../DynamicTable";
import {renderDriversEarningsListDataSquares} from "../DataSquare";
import driverApi from "../../../apis/driverApi";
import taxiApi from "../../../apis/taxiApi";
import {TAXI_ORDER_STATUS} from "../../../constants/enums";
import colors from "../../../constants/colors";

const DriversEarningsList = () => {
    const l10nText = getLocalisedTexts('DRIVER_EARNINGS');

    const [todaysEarnings, setTodaysEarnings] = useState(0);
    const [weeklyEarnings, setWeeklyEarnings] = useState(0);
    const [monthlyEarnings, setMonthlyEarnings] = useState(0);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [drivers, setDrivers] = useState([])
    const [taxiOrders, setTaxiOrders] = useState([])
    const [driversEarnings, setDriversEarnings] = useState([])

    const columnsConfig = {
        is_online: l10nText.activity || 'Activity',
        license_number: l10nText.license_number || 'License number',
        driver: l10nText.driver || 'Driver',
        total_earnings: l10nText.total_earnings || 'Total Earnings',
        number_of_rides: l10nText.number_of_rides || 'Number of Rides',
        hourly_earnings: l10nText.hourly_earnings || 'Hourly Earnings',
        distance_time_travelled: l10nText.distance_time_travelled || 'Distance and Time Travelled',
        earnings_fee: l10nText.earnings_fee || 'Earnings Fee',
    };

    useEffect(() => {
        const fetchTaxiOrders = async () => {
            try {
                const orders = await taxiApi.getTaxiOrders();
                if (orders && !orders.error) {
                    setTaxiOrders(orders)
                    const completedOrders = orders.filter(order => order.status === TAXI_ORDER_STATUS.COMPLETED)

                    let todaysEarnings = 0;
                    let weeklyEarnings = 0;
                    let monthlyEarnings = 0;
                    let totalEarnings = 0;

                    const now = new Date();

                    completedOrders.forEach(order => {
                        const timeline = order.timeline.find(item => item.status === TAXI_ORDER_STATUS.COMPLETED);
                        const timestamp = timeline?.location?.timestamp;

                        if (timestamp) {
                            const orderDate = new Date(timestamp);
                            const daysDiff = Math.floor((now - orderDate) / (1000 * 60 * 60 * 24));
                            const weeksDiff = Math.floor(daysDiff / 7);
                            const monthsDiff = Math.floor(daysDiff / 30);

                            const orderAmount = parseFloat(order.payment.price) || 0;

                            totalEarnings += orderAmount;

                            if (daysDiff === 0) {
                                todaysEarnings += orderAmount;
                            }

                            if (weeksDiff === 0) {
                                weeklyEarnings += orderAmount;
                            }

                            if (monthsDiff === 0) {
                                monthlyEarnings += orderAmount;
                            }
                        }
                    });

                    setTodaysEarnings(todaysEarnings);
                    setWeeklyEarnings(weeklyEarnings);
                    setMonthlyEarnings(monthlyEarnings);
                    setTotalEarnings(totalEarnings);
                }
            } catch (error) {
                console.error('Error fetching taxi orders:', error);
            }
        };

        const fetchRegisteredDrivers = async () => {
            try {
                const drivers = await driverApi.listDrivers();
                if (drivers && !drivers.error) {
                    setDrivers(drivers);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchTaxiOrders()
        fetchRegisteredDrivers()
        const intervalId = setInterval(() => {
            fetchTaxiOrders()
            fetchRegisteredDrivers()
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const calculateEarnings = async () => {
            const earnings = await Promise.all(drivers.map(async (driver) => {
                try {
                    const driverOrders = await taxiApi.getCompletedTaxiOrdersByDriverId(driver.driver_id);

                    if (driverOrders && driverOrders.length) {
                        const totalEarnings = driverOrders.reduce((sum, order) => {
                            return sum + parseFloat(order.payment.price || 0);
                        }, 0);

                        const numberOfRides = driverOrders.length;

                        const totalDurationInSeconds = driverOrders.reduce((sum, order) => {
                            return sum + (parseInt(order.payment.duration || 0) || 0);
                        }, 0);

                        const totalDurationInHours = totalDurationInSeconds / 3600;

                        const hourlyEarnings = totalDurationInHours > 0 ? (totalEarnings / totalDurationInHours).toFixed(2) : '0.00';

                        const distance = driverOrders.reduce((totalDistance, order) => {
                            return totalDistance + parseFloat(order.payment.distance || 0);
                        }, 0);

                        const hours = Math.floor(totalDurationInSeconds / 3600);
                        const minutes = Math.floor((totalDurationInSeconds % 3600) / 60);

                        return {
                            is_online: driver?.online,
                            license_number: driver?.vehicles[0]?.license_plate,
                            driver: `${driver?.user?.first_name} ${driver?.user?.last_name}`,
                            total_earnings: `€${totalEarnings.toFixed(2)}`,
                            number_of_rides: numberOfRides,
                            hourly_earnings: `${hourlyEarnings} €/h`,
                            distance_time_travelled: `${distance.toFixed(2)}km/${hours}h${minutes}min`,
                            earnings_fee: `€${totalEarnings.toFixed(2)}`, // Assuming fee is the same as total earnings
                        };
                    } else {
                        return {
                            is_online: driver?.online,
                            license_number: driver?.vehicles[0]?.license_plate,
                            driver: `${driver?.user?.first_name} ${driver?.user?.last_name}`,
                            total_earnings: '€0.00',
                            number_of_rides: 0,
                            hourly_earnings: '0.00 €/h',
                            distance_time_travelled: '0km/0h0min',
                            earnings_fee: '€0.00',
                        };
                    }
                } catch (error) {
                    console.error('Error calculating earnings for driver:', driver.driver_id, error);
                    return {};
                }
            }));

            setDriversEarnings(earnings);
        };

        if (drivers.length > 0 && taxiOrders.length > 0) {
            calculateEarnings();
        }
    }, [drivers, taxiOrders]);

    return (
        <div>
            <div className="list-header">
                <h3>{l10nText.title || 'Driver Earnings'}</h3>
                {renderDriversEarningsListDataSquares(l10nText, {}, todaysEarnings, weeklyEarnings, monthlyEarnings, totalEarnings)}
            </div>
            <DynamicTable
                data={driversEarnings}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />
        </div>
    );
};

export default DriversEarningsList;
