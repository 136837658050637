import React from 'react';
import '../css/TableLegend.css';
import {getLocalisedTexts} from "../../l10n/localisations/languages"; // You can create this CSS file for styling the legend

const TableLegend = () => {
    const l10nText = getLocalisedTexts('TABLE_LEGEND');

    return (
        <div className="table-legend">
            <div className="legend-item">
                <span className="status-dot valid"></span> {l10nText.valid || 'Green: Document is valid and up to date.'}
            </div>
            <div className="legend-item">
                <span className="status-dot expiring-soon"></span> {l10nText.expiring_soon || 'Yellow: Document validity will expire in 30 days.'}
            </div>
            <div className="legend-item">
                <span className="status-dot expiring-very-soon"></span> {l10nText.expiring_very_soon || 'Orange: Valid for 5 more days.'}
            </div>
            <div className="legend-item">
                <span className="status-dot expired"></span> {l10nText.expired || 'Red: Document is invalid and needs immediate attention.'}
            </div>
        </div>
    );
};

export default TableLegend;
