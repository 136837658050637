import {getLocalisedTexts} from "../l10n/localisations/languages";
import {VEHICLE_CATEGORY, VEHICLE_CLASS} from "./enums";

const l10nLanguages = getLocalisedTexts('LANGUAGES')

export const allergiesOptions = () => {
    let sideMenuL10NTexts = getLocalisedTexts('SIDE_MENU_OPTIONS');
    return [
        {value: 'vegetarian', name: sideMenuL10NTexts?.vegetarian},
        {value: 'vegan', name: sideMenuL10NTexts?.vegan},
        {value: 'gluten_free', name: sideMenuL10NTexts?.glutenFree},
        {value: 'nut_free', name: sideMenuL10NTexts?.nutFree},
        {value: 'diary_free', name: sideMenuL10NTexts?.diaryFree},
        {value: 'organic', name: sideMenuL10NTexts?.organic},
    ]
}

export const sideMenuNotifications = () => {
    let sideMenuL10NTexts = getLocalisedTexts('SIDE_MENU_OPTIONS');
    return [
        {value: 'Ride Requests', name: sideMenuL10NTexts?.rideRequest},
        {value: 'Ride Acceptance', name: sideMenuL10NTexts?.rideAcceptance},
        {value: 'Passenger Updates', name: sideMenuL10NTexts?.passengerUpdates},
        {value: 'In-App Messaging', name: sideMenuL10NTexts?.inAppMessaging},
        {value: 'Promotions And Discounts', name: sideMenuL10NTexts?.promotionsAndDiscounts},
        {value: 'Ratings and Feedbacks', name: sideMenuL10NTexts?.ratingsAndFeedbacks},
        {value: 'Driver Performance', name: sideMenuL10NTexts?.driverPerformance},
        {value: 'Account And Payment Notifications', name: sideMenuL10NTexts?.accountAndPaymentNotifications},
        {value: 'System Updates And Maintenance', name: sideMenuL10NTexts?.systemUpdatesAndMaintenance},
        {value: 'Emergency Or Critical Alerts', name: sideMenuL10NTexts?.emergencyOrCriticalAlerts}
    ]
}
export const getTaxiPushNotificationsOptions = () => {
    let sideMenuL10NTexts = getLocalisedTexts('SIDE_MENU_OPTIONS');

    return [
        {value: "ride_requests", langKey: "requests", name: sideMenuL10NTexts.rideRequest},
        {value: "ride_acceptance", langKey: "acceptance", name: sideMenuL10NTexts.rideAcceptance},
        {value: "driver_arrival", langKey: "driverArrival", name: sideMenuL10NTexts.driverArrival},
        {value: "ride_completion", langKey: "completion", name: sideMenuL10NTexts.rideCompletion},
        {value: "promotions_and_discounts", langKey: "promos", name: sideMenuL10NTexts.specialOffers},
        {value: "ratings_and_feedback", langKey: "ratings", name: sideMenuL10NTexts.ratingFeedback},
        {value: "news_and_updates", langKey: "news", name: sideMenuL10NTexts.news},
    ];
}

export const getNotificationOptions = () => {
    let notificationsL10NTexts = getLocalisedTexts('NOTIFICATION_PREFERENCES');

    return [
        {value: "telephone", langKey: "telephone", name: notificationsL10NTexts.telephone},
        {value: "whatsapp", langKey: "whatsapp", name: notificationsL10NTexts.whatsapp},
        {value: "viber", langKey: "viber", name: notificationsL10NTexts.viber},
        {value: "telegram", langKey: "telegram", name: notificationsL10NTexts.telegram},
        {value: "wechat", langKey: "wechat", name: notificationsL10NTexts.wechat},
    ];
};


export const getTaxiPreferencesOptions = () => {
    let sideMenuL10NTexts = getLocalisedTexts('SIDE_MENU_OPTIONS');
    return [
        {
            value: "luggage",
            langKey: "luggage",
            name: sideMenuL10NTexts.luggage,
            image: require('../assets/icons/briefcase.png')
        },
        {
            value: "traveling_with_pet",
            langKey: "pet",
            name: sideMenuL10NTexts.pet,
            image: require('../assets/icons/icon_pet_friendly.png')
        },
        {
            value: "air_conditioning",
            langKey: "ac",
            name: sideMenuL10NTexts.ac,
            image: require('../assets/icons/icon_AC.png')
        },
        {
            value: "child_seats",
            langKey: "childSeats",
            name: sideMenuL10NTexts.childSeats,
            image: require('../assets/icons/icon_children.png')
        },
        {
            value: "wheelchair_accessibility",
            langKey: "wheelchair",
            name: sideMenuL10NTexts.wheelchair,
            image: require('../assets/icons/icon_wheelchair.png')
        },
        {
            value: "quiet_ride",
            langKey: "silentRide",
            name: sideMenuL10NTexts.noRadio,
            image: require('../assets/icons/shh.png')
        },
    ];
};

export const getTransferPreferencesOptions = () => {
    let l10nTexts = getLocalisedTexts('SIDE_MENU_OPTIONS');

    return [
        {
            value: "luggage",
            langKey: "luggage",
            name: l10nTexts.luggage,
            image: require('../assets/icons/icon-luggage.png')
        },
        {
            value: "traveling_with_pet",
            langKey: "pet",
            name: l10nTexts.pet,
            image: require('../assets/icons/icon_pet_friendly.png')
        },
        {
            value: "wifi",
            langKey: "wifi",
            name: l10nTexts.wifi,
            image: require('../assets/icons/wifi.png')
        },
        {
            value: "outlets",
            langKey: "outlets",
            name: l10nTexts.outlets,
            image: require('../assets/icons/outlet.png')
        },
        {
            value: "onboard_restrooms",
            langKey: "restroom",
            name: l10nTexts.onBoardRestrooms,
            image: require('../assets/icons/toilet.png')
        },
        {
            value: "air_conditioning",
            langKey: "ac",
            name: l10nTexts.ac,
            image: require('../assets/icons/icon_AC.png')
        },
        {
            value: "multilingual_staff",
            langKey: "multilingual",
            name: l10nTexts.multilingual,
            image: require('../assets/icons/language.png')
        },
    ]
};

export const getTaxiVehicleOptions = () => {
    let vehicleL10NTexts = getLocalisedTexts('CAR_TYPES');

    return {
        [VEHICLE_CATEGORY.STANDARD]: [
            {
                image: require('../assets/icons/cars/outlined/012-taxi.png'),
                imageSelected: require('../assets/icons/cars/black/012-taxi.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/012-taxi.png'),
                image_small: require('../assets/icons/cars/outlined_small/012-taxi.png'),
                title: VEHICLE_CLASS.SEDAN,
                name: vehicleL10NTexts.sedan,
                people: "3",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
            {
                image: require('../assets/icons/cars/outlined/018-suv_car.png'),
                imageSelected: require('../assets/icons/cars/black/018-suv_car.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/018-suv_car.png'),
                image_small: require('../assets/icons/cars/outlined_small/018-suv_car.png'),
                title: VEHICLE_CLASS.CARAVAN,
                name: vehicleL10NTexts.caravan,
                people: "3",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
            {
                image: require('../assets/icons/cars/outlined/021-suv.png'),
                imageSelected: require('../assets/icons/cars/black/021-suv.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/021-suv.png'),
                image_small: require('../assets/icons/cars/outlined_small/021-suv.png'),
                title: VEHICLE_CLASS.SUV,
                name: vehicleL10NTexts.suv,
                people: "4",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
            {
                image: require('../assets/icons/cars/outlined/030-minivan.png'),
                imageSelected: require('../assets/icons/cars/black/030-mini-van.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/030-mini-van.png'),
                image_small: require('../assets/icons/cars/outlined_small/030-minivan.png'),
                title: VEHICLE_CLASS.VAN,
                name: vehicleL10NTexts.van,
                people: "8",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
        ],
        [VEHICLE_CATEGORY.PREMIUM]: [
            {
                image: require('../assets/icons/cars/outlined/015-luxury_vehicle.png'),
                imageSelected: require('../assets/icons/cars/black/015-luxury_vehicle.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/015-luxury_vehicle.png'),
                image_small: require('../assets/icons/cars/outlined_small/015-luxury_vehicle.png'),
                title: VEHICLE_CLASS.SEDAN,
                name: vehicleL10NTexts.sedan,
                people: "3",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
            {
                image: require('../assets/icons/cars/outlined/016-porsche.png'),
                imageSelected: require('../assets/icons/cars/black/016-porsche.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/016-porsche.png'),
                image_small: require('../assets/icons/cars/outlined_small/016-porsche.png'),
                title: VEHICLE_CLASS.CARAVAN,
                name: vehicleL10NTexts.caravan,
                people: "4",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
            {
                image: require('../assets/icons/cars/outlined/001-Land_Rover.png'),
                imageSelected: require('../assets/icons/cars/black/001-Land_Rover.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/001-Land_Rover.png'),
                image_small: require('../assets/icons/cars/outlined_small/001-Land_Rover.png'),
                title: VEHICLE_CLASS.SUV,
                name: vehicleL10NTexts.suv,
                people: "5",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
            {
                image: require('../assets/icons/cars/outlined/030-minivan.png'),
                imageSelected: require('../assets/icons/cars/black/030-mini-van.png'),
                imageSelected_small: require('../assets/icons/cars/black_small/030-mini-van.png'),
                image_small: require('../assets/icons/cars/outlined_small/030-minivan.png'),
                title: VEHICLE_CLASS.VAN,
                name: vehicleL10NTexts.van,
                people: "8",
                start_cost: '5.36',
                per_kilometre: '2.90',
                per_minute: '1.20'
            },
        ]
    };
}

export const getTransferVehicleOptions = () => {
    let vehicleL10NTexts = getLocalisedTexts('CAR_TYPES');
    return [
        {
            image: require('../assets/icons/sedan.png'),
            imageSelected: require('../assets/icons/cars/black/012-taxi.png'),
            title: VEHICLE_CLASS.SEDAN,
            name: vehicleL10NTexts.sedan,
            people: "3",
            start_cost: '5.36',
            per_kilometre: '2.90',
            per_minute: '1.20'
        },
        {
            image: require('../assets/icons/minivan.png'),
            imageSelected: require('../assets/icons/cars/black/012-taxi.png'),
            title: VEHICLE_CLASS.MINIVAN,
            name: vehicleL10NTexts.minivan,
            people: "7",
            start_cost: '5.36',
            per_kilometre: '2.90',
            per_minute: '1.20'
        },
        {
            image: require('../assets/icons/green_van.png'),
            imageSelected: require('../assets/icons/cars/black/012-taxi.png'),
            title: VEHICLE_CLASS.VAN,
            name: vehicleL10NTexts.van,
            people: "8",
            start_cost: '5.36',
            per_kilometre: '2.90',
            per_minute: '1.20'
        },
        {
            image: require('../assets/icons/green_bus.png'),
            imageSelected: require('../assets/icons/cars/black/012-taxi.png'),
            title: VEHICLE_CLASS.MINIVAN,
            name: vehicleL10NTexts.minibus,
            people: "8-18",
            start_cost: '5.36',
            per_kilometre: '2.90',
            per_minute: '1.20'
        },
        {
            image: require('../assets/icons/green_bus.png'),
            imageSelected: require('../assets/icons/cars/black/012-taxi.png'),
            title: VEHICLE_CLASS.BUS,
            name: vehicleL10NTexts.bus,
            people: "18+",
            start_cost: '5.36',
            per_kilometre: '2.90',
            per_minute: '1.20'
        },
    ];
};

export const getRepeatDays = () => {
    let repeatL10NTexts = getLocalisedTexts('BOOK_TAXI');

    return [
        {value: "do_not_repeat", name: repeatL10NTexts?.doNotRepeat},
        {value: "every_monday", name: repeatL10NTexts?.monday},
        {value: "every_tuesday", name: repeatL10NTexts?.tuesday},
        {value: "every_wednesday", name: repeatL10NTexts?.wednesday},
        {value: "every_thursday", name: repeatL10NTexts?.thursday},
        {value: "every_friday", name: repeatL10NTexts?.friday},
        {value: "every_saturday", name: repeatL10NTexts?.saturday},
        {value: "every_sunday", name: repeatL10NTexts?.sunday}
    ];
}

export const getRepeatWeeks = () => {
    let repeatL10NTexts = getLocalisedTexts('BOOK_TAXI');

    return [
        {value: "1 week", name: repeatL10NTexts?.oneWeek},
        {value: "2 weeks", name: repeatL10NTexts?.twoWeeks},
        {value: "3 weeks", name: repeatL10NTexts?.threeWeeks},
        {value: "1 month", name: repeatL10NTexts?.oneMonth}
    ];
};

export const spicyLevelOptions = [
    {value: "0", name: "Non-Spicy", image: require('../assets/icons/chilli.png')},
    {value: "1", name: "Light", image: require('../assets/icons/chilli.png')},
    {value: "2", name: "Medium", image: require('../assets/icons/chilli.png')},
    {value: "3", name: "Heavy", image: require('../assets/icons/chilli.png')},
]

export const getAllergens = () => {
    const l10nAllergens = getLocalisedTexts("ALLERGENS");

    return [
        {tag: '1', name: l10nAllergens[1], source: require('../assets/icons/allergens/1-gluten.png')},
        {tag: '2', name: l10nAllergens[2], source: require('../assets/icons/allergens/2-crustaceans.png')},
        {tag: '3', name: l10nAllergens[3], source: require('../assets/icons/allergens/3-eggs.png')},
        {tag: '4', name: l10nAllergens[4], source: require('../assets/icons/allergens/4-fish.png')},
        {tag: '5', name: l10nAllergens[5], source: require('../assets/icons/allergens/5-peanut.png')},
        {tag: '6', name: l10nAllergens[6], source: require('../assets/icons/allergens/6-soy.png')},
        {tag: '7', name: l10nAllergens[7], source: require('../assets/icons/allergens/7-milk.png')},
        {tag: '8', name: l10nAllergens[8], source: require('../assets/icons/allergens/8-nut.png')},
        {tag: '9', name: l10nAllergens[9], source: require('../assets/icons/allergens/9-celery.png')},
        {tag: '10', name: l10nAllergens[10], source: require('../assets/icons/allergens/10-mustard.png')},
        {tag: '11', name: l10nAllergens[11], source: require('../assets/icons/allergens/11-sesame.png')},
        {tag: '12', name: l10nAllergens[12], source: require('../assets/icons/allergens/12-sulphite.png')},
        {tag: '13', name: l10nAllergens[13], source: require('../assets/icons/allergens/13-lupin.png')},
        {tag: '14', name: l10nAllergens[14], source: require('../assets/icons/allergens/14-molluscs.png')}
    ];
};


export const radioPreferences = () => {
    const l10nTextOptions = getLocalisedTexts('SIDE_MENU_OPTIONS');
    return [
        {value: 'drivers_choice', name: l10nTextOptions?.driversChoice},
        {value: 'radio_bob', name: 'Radio Bob'},
        {value: 'rtvslo_radio', name: 'RTVSlo Radio'},
        {value: 'radio_student', name: 'Radio Študent'},
        {value: 'radio_hit', name: 'Radio Hit'},
        {value: 'radio_1', name: 'Radio 1'},
        {value: 'radio_gorenc', name: 'Radio Gorenc'},
        {value: 'hit', name: 'Hit'},
        {value: 'rock_radio', name: 'Rock Radio'},
        {value: 'radio_kranj', name: 'Radio Kranj'},
        {value: 'aktual', name: 'Aktual'},

    ]
}

export const spicyPreferences = () => {
    const l10nTextOptions = getLocalisedTexts('SIDE_MENU_OPTIONS');
    return [
        {value: 'not_spicy', name: l10nTextOptions?.notSpicy},
        {value: 'mild', name: `${l10nTextOptions?.mild} 🌶️`},
        {value: "hot", name: `${l10nTextOptions?.hot} 🌶️🌶️`},
        {value: "very_hot", name: `${l10nTextOptions?.veryHot} 🌶️🌶️🌶️`},
    ]
}

export const transferNotifications = () => {
    const l10nTextOptions = getLocalisedTexts('SIDE_MENU_OPTIONS');
    return [
        {
            value: 'departure_reminders',
            name: l10nTextOptions?.departureReminders
        },
        {
            value: 'real_time_updates',
            name: l10nTextOptions?.realTimeUpdates
        },
        {
            value: 'booking_confirmation',
            name: l10nTextOptions?.bookingConfirmation
        },
        {
            value: 'special_offers_and_promotions',
            name: l10nTextOptions?.specialOffers
        },
        {
            value: 'ratings_and_feedback',
            name: l10nTextOptions?.ratingFeedback
        },
        {
            value: 'news',
            name: l10nTextOptions?.news
        },
    ]
}

export const deliveryNotifications = () => {
    const l10nTextOptions = getLocalisedTexts('SIDE_MENU_OPTIONS');
    return [
        {value: 'order_confirmation', name: l10nTextOptions?.orderConfirmation},
        {value: 'order_updates', name: l10nTextOptions?.orderUpdates},
        {value: 'delivery_arrival', name: l10nTextOptions?.deliveryArrival},
        {value: 'order_tracking', name: l10nTextOptions?.realTimeTracking},
        {value: 'promotion_discounts', name: l10nTextOptions?.promosDiscounts},
        {value: 'payment_confirmation', name: l10nTextOptions?.paymentConfirmation},
        {value: 'ratings_feedback', name: l10nTextOptions?.ratingFeedback},
        {value: 'news_updates', name: l10nTextOptions?.newsUpdates},
        {value: 'account_updates', name: l10nTextOptions?.accUpdates},
    ]
}

export const languageOptions = [
    {value: 'EN', name: l10nLanguages?.en},
    {value: 'SI', name: l10nLanguages?.sl},
    {value: 'IT', name: l10nLanguages?.it},
    {value: 'DE', name: l10nLanguages?.de},
    {value: 'ES', name: l10nLanguages?.esp},
    {value: 'SRB', name: l10nLanguages?.srb},
    {value: 'BIH', name: l10nLanguages?.bih},
    {value: 'CRO', name: l10nLanguages?.cro},
    {value: 'FR', name: l10nLanguages?.fr},
    {value: 'RU', name: l10nLanguages?.ru},
]
export const getOrderCancellationReasons = () => {
    let cancellationL10NTexts = getLocalisedTexts('CANCEL_ORDER');

    return [
        {value: "changed_mind", langKey: "changed_mind", name: cancellationL10NTexts.reasons.changed_mind},
        {
            value: "found_alternative",
            langKey: "found_alternative",
            name: cancellationL10NTexts.reasons.found_alternative
        },
        {value: "driver_too_long", langKey: "driver_too_long", name: cancellationL10NTexts.reasons.driver_too_long},
        {value: "incorrect_pickup", langKey: "incorrect_pickup", name: cancellationL10NTexts.reasons.incorrect_pickup},
        {value: "other", langKey: "other", name: cancellationL10NTexts.reasons.other}
    ];
};

export const parseMainColorName = (colorName) => {
    // Assuming the main color name is the second word in the localized text
    return colorName.split(' ')[2];
};

export const getShortColorName = (value) => {
    // Assuming the short name is the first two characters of the value
    return value.substring(0, 2);
};

export const getVehicleColors = () => {
    let colorL10NTexts = getLocalisedTexts('COLORS');

    return [
        {value: "S0B", name: colorL10NTexts.S0B, color: "#FFFFFF", mainColor: parseMainColorName(colorL10NTexts.S0B), shortName: getShortColorName("S0B")},
        {value: "E0B", name: colorL10NTexts.E0B, color: "#F5F5F5", mainColor: parseMainColorName(colorL10NTexts.E0B), shortName: getShortColorName("E0B")},
        {value: "S0M", name: colorL10NTexts.S0M, color: "#E0E0E0", mainColor: parseMainColorName(colorL10NTexts.S0M), shortName: getShortColorName("S0M")},
        {value: "E0M", name: colorL10NTexts.E0M, color: "#D3D3D3", mainColor: parseMainColorName(colorL10NTexts.E0M), shortName: getShortColorName("E0M")},
        {value: "S0D", name: colorL10NTexts.S0D, color: "#BEBEBE", mainColor: parseMainColorName(colorL10NTexts.S0D), shortName: getShortColorName("S0D")},
        {value: "E0D", name: colorL10NTexts.E0D, color: "#A9A9A9", mainColor: parseMainColorName(colorL10NTexts.E0D), shortName: getShortColorName("E0D")},
        {value: "S1B", name: colorL10NTexts.S1B, color: "#FFFFE0", mainColor: parseMainColorName(colorL10NTexts.S1B), shortName: getShortColorName("S1B")},
        {value: "E1B", name: colorL10NTexts.E1B, color: "#FFFACD", mainColor: parseMainColorName(colorL10NTexts.E1B), shortName: getShortColorName("E1B")},
        {value: "S1M", name: colorL10NTexts.S1M, color: "#FFD700", mainColor: parseMainColorName(colorL10NTexts.S1M), shortName: getShortColorName("S1M")},
        {value: "E1M", name: colorL10NTexts.E1M, color: "#FFC700", mainColor: parseMainColorName(colorL10NTexts.E1M), shortName: getShortColorName("E1M")},
        {value: "S1D", name: colorL10NTexts.S1D, color: "#FFA500", mainColor: parseMainColorName(colorL10NTexts.S1D), shortName: getShortColorName("S1D")},
        {value: "E1D", name: colorL10NTexts.E1D, color: "#FF8C00", mainColor: parseMainColorName(colorL10NTexts.E1D), shortName: getShortColorName("E1D")},
        {value: "S2B", name: colorL10NTexts.S2B, color: "#FFA07A", mainColor: parseMainColorName(colorL10NTexts.S2B), shortName: getShortColorName("S2B")},
        {value: "E2B", name: colorL10NTexts.E2B, color: "#FF7F50", mainColor: parseMainColorName(colorL10NTexts.E2B), shortName: getShortColorName("E2B")},
        {value: "S2M", name: colorL10NTexts.S2M, color: "#FF6347", mainColor: parseMainColorName(colorL10NTexts.S2M), shortName: getShortColorName("S2M")},
        {value: "E2M", name: colorL10NTexts.E2M, color: "#FF4500", mainColor: parseMainColorName(colorL10NTexts.E2M), shortName: getShortColorName("E2M")},
        {value: "S2D", name: colorL10NTexts.S2D, color: "#FF4500", mainColor: parseMainColorName(colorL10NTexts.S2D), shortName: getShortColorName("S2D")},
        {value: "E2D", name: colorL10NTexts.E2D, color: "#FF4500", mainColor: parseMainColorName(colorL10NTexts.E2D), shortName: getShortColorName("E2D")},
        {value: "S3B", name: colorL10NTexts.S3B, color: "#FFB6C1", mainColor: parseMainColorName(colorL10NTexts.S3B), shortName: getShortColorName("S3B")},
        {value: "E3B", name: colorL10NTexts.E3B, color: "#FF69B4", mainColor: parseMainColorName(colorL10NTexts.E3B), shortName: getShortColorName("E3B")},
        {value: "S3M", name: colorL10NTexts.S3M, color: "#FF1493", mainColor: parseMainColorName(colorL10NTexts.S3M), shortName: getShortColorName("S3M")},
        {value: "E3M", name: colorL10NTexts.E3M, color: "#DB7093", mainColor: parseMainColorName(colorL10NTexts.E3M), shortName: getShortColorName("E3M")},
        {value: "S3D", name: colorL10NTexts.S3D, color: "#C71585", mainColor: parseMainColorName(colorL10NTexts.S3D), shortName: getShortColorName("S3D")},
        {value: "E3D", name: colorL10NTexts.E3D, color: "#8B008B", mainColor: parseMainColorName(colorL10NTexts.E3D), shortName: getShortColorName("E3D")},
        {value: "S4B", name: colorL10NTexts.S4B, color: "#E6E6FA", mainColor: parseMainColorName(colorL10NTexts.S4B), shortName: getShortColorName("S4B")},
        {value: "E4B", name: colorL10NTexts.E4B, color: "#D8BFD8", mainColor: parseMainColorName(colorL10NTexts.E4B), shortName: getShortColorName("E4B")},
        {value: "S4M", name: colorL10NTexts.S4M, color: "#DDA0DD", mainColor: parseMainColorName(colorL10NTexts.S4M), shortName: getShortColorName("S4M")},
        {value: "E4M", name: colorL10NTexts.E4M, color: "#DA70D6", mainColor: parseMainColorName(colorL10NTexts.E4M), shortName: getShortColorName("E4M")},
        {value: "S4D", name: colorL10NTexts.S4D, color: "#BA55D3", mainColor: parseMainColorName(colorL10NTexts.S4D), shortName: getShortColorName("S4D")},
        {value: "E4D", name: colorL10NTexts.E4D, color: "#9932CC", mainColor: parseMainColorName(colorL10NTexts.E4D), shortName: getShortColorName("E4D")},
        {value: "S5B", name: colorL10NTexts.S5B, color: "#8A2BE2", mainColor: parseMainColorName(colorL10NTexts.S5B), shortName: getShortColorName("S5B")},
        {value: "E5B", name: colorL10NTexts.E5B, color: "#9400D3", mainColor: parseMainColorName(colorL10NTexts.E5B), shortName: getShortColorName("E5B")},
        {value: "S5M", name: colorL10NTexts.S5M, color: "#8B0000", mainColor: parseMainColorName(colorL10NTexts.S5M), shortName: getShortColorName("S5M")},
        {value: "E5M", name: colorL10NTexts.E5M, color: "#800000", mainColor: parseMainColorName(colorL10NTexts.E5M), shortName: getShortColorName("E5M")},
        {value: "S5D", name: colorL10NTexts.S5D, color: "#A52A2A", mainColor: parseMainColorName(colorL10NTexts.S5D), shortName: getShortColorName("S5D")},
        {value: "E5D", name: colorL10NTexts.E5D, color: "#B22222", mainColor: parseMainColorName(colorL10NTexts.E5D), shortName: getShortColorName("E5D")},
        {value: "S6B", name: colorL10NTexts.S6B, color: "#FF0000", mainColor: parseMainColorName(colorL10NTexts.S6B), shortName: getShortColorName("S6B")},
        {value: "E6B", name: colorL10NTexts.E6B, color: "#DC143C", mainColor: parseMainColorName(colorL10NTexts.E6B), shortName: getShortColorName("E6B")},
        {value: "S6M", name: colorL10NTexts.S6M, color: "#B22222", mainColor: parseMainColorName(colorL10NTexts.S6M), shortName: getShortColorName("S6M")},
        {value: "E6M", name: colorL10NTexts.E6M, color: "#8B0000", mainColor: parseMainColorName(colorL10NTexts.E6M), shortName: getShortColorName("E6M")},
        {value: "S6D", name: colorL10NTexts.S6D, color: "#800000", mainColor: parseMainColorName(colorL10NTexts.S6D), shortName: getShortColorName("S6D")},
        {value: "E6D", name: colorL10NTexts.E6D, color: "#A52A2A", mainColor: parseMainColorName(colorL10NTexts.E6D), shortName: getShortColorName("E6D")},
        {value: "S7B", name: colorL10NTexts.S7B, color: "#FA8072", mainColor: parseMainColorName(colorL10NTexts.S7B), shortName: getShortColorName("S7B")},
        {value: "E7B", name: colorL10NTexts.E7B, color: "#E9967A", mainColor: parseMainColorName(colorL10NTexts.E7B), shortName: getShortColorName("E7B")},
        {value: "S7M", name: colorL10NTexts.S7M, color: "#FF6347", mainColor: parseMainColorName(colorL10NTexts.S7M), shortName: getShortColorName("S7M")},
        {value: "E7M", name: colorL10NTexts.E7M, color: "#FF4500", mainColor: parseMainColorName(colorL10NTexts.E7M), shortName: getShortColorName("E7M")},
        {value: "S7D", name: colorL10NTexts.S7D, color: "#FF0000", mainColor: parseMainColorName(colorL10NTexts.S7D), shortName: getShortColorName("S7D")},
        {value: "E7D", name: colorL10NTexts.E7D, color: "#DC143C", mainColor: parseMainColorName(colorL10NTexts.E7D), shortName: getShortColorName("E7D")},
        {value: "S8B", name: colorL10NTexts.S8B, color: "#FF4500", mainColor: parseMainColorName(colorL10NTexts.S8B), shortName: getShortColorName("S8B")},
        {value: "E8B", name: colorL10NTexts.E8B, color: "#FF6347", mainColor: parseMainColorName(colorL10NTexts.E8B), shortName: getShortColorName("E8B")},
        {value: "S8M", name: colorL10NTexts.S8M, color: "#FF7F50", mainColor: parseMainColorName(colorL10NTexts.S8M), shortName: getShortColorName("S8M")},
        {value: "E8M", name: colorL10NTexts.E8M, color: "#FF8C00", mainColor: parseMainColorName(colorL10NTexts.E8M), shortName: getShortColorName("E8M")},
        {value: "S8D", name: colorL10NTexts.S8D, color: "#FFA500", mainColor: parseMainColorName(colorL10NTexts.S8D), shortName: getShortColorName("S8D")},
        {value: "E8D", name: colorL10NTexts.E8D, color: "#FFD700", mainColor: parseMainColorName(colorL10NTexts.E8D), shortName: getShortColorName("E8D")},
        {value: "S9B", name: colorL10NTexts.S9B, color: "#FFFF00", mainColor: parseMainColorName(colorL10NTexts.S9B), shortName: getShortColorName("S9B")},
        {value: "E9B", name: colorL10NTexts.E9B, color: "#FFFFE0", mainColor: parseMainColorName(colorL10NTexts.E9B), shortName: getShortColorName("E9B")},
        {value: "S9M", name: colorL10NTexts.S9M, color: "#FFFACD", mainColor: parseMainColorName(colorL10NTexts.S9M), shortName: getShortColorName("S9M")},
        {value: "E9M", name: colorL10NTexts.E9M, color: "#FAFAD2", mainColor: parseMainColorName(colorL10NTexts.E9M), shortName: getShortColorName("E9M")},
        {value: "S9D", name: colorL10NTexts.S9D, color: "#FFE4B5", mainColor: parseMainColorName(colorL10NTexts.S9D), shortName: getShortColorName("S9D")},
        {value: "E9D", name: colorL10NTexts.E9D, color: "#FFDAB9", mainColor: parseMainColorName(colorL10NTexts.E9D), shortName: getShortColorName("E9D")}
    ];
};
