import {
    addToUsersList,
    BE_REST_API,
    LOAD_BARS,
    LOAD_CATEGORIES, LOAD_PRODUCTS, setActiveBar, setActiveBarOwner, setActiveBarRepresentative, setActiveCategory,
    setActiveEditUser,
    setActiveProduct,
    setDownloadProgress, setLastCaretakerCreated,
    setProductsByCategory,
    setSessionBars,
    setSessionCategories,
    setSessionUser,
    setSessionUsers,
    setSessionUserToken,
    setUserData,
    setUsers,
    setUserToken,
    updateImportErrors
} from "./actions";
import {
    isUserAdmin,
    isUserBarRepresentative,
    isUserCaretaker,
    isUserOwner,
    parseRestApiResponse,
    parseUserRole
} from "../helpers/helpers";
import {USER_ROLE} from "../constants/enums";



export const userLogin = (email, password, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            email: email,
            password: password
        }
        console.log(postdata);
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/users/login';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET USER LOGIN: " + JSON.stringify(resData, null, 4));


            const onDispatchData = async () => {
                setSessionUser(JSON.stringify(resData));
                setSessionUserToken(resData["user_id"]);
                dispatch(setUserData(resData));
                dispatch(setUserToken(resData["user_id"]));

                let parsedUserRole = parseUserRole(resData["type"]);
                if (parsedUserRole === USER_ROLE.ADMIN) {

                    await dispatch(loadUsers(resData['user_id'], () => {
                        console.log("Users successfully loaded..");
                    }, () => {
                        console.warn("Error loading users..");
                    }));
                } else if (parsedUserRole === USER_ROLE.ENTITIES_MANAGER) {
                    await dispatch(loadUsers(resData['user_id'], () => {
                        console.log("Users successfully loaded..");
                    }, () => {
                        console.warn("Error loading users..");
                    }));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);


        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};

export const loadUsers = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET USERS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionUsers(JSON.stringify(resData));
                dispatch(setUsers(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading objects..', e);
        }
    };
};


export const updateUser = (user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveEditUser(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const resetUserPassword = (user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user/reset/password';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("RESET PASSWORD: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const updateUserPassword = (data, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            data: data,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/user/update/password';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE USER PASS.: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed resetting user password..', e);
        }
    };
};


/* USER actions */
export const createNewUser = (admin_caretaker_id, user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user: user
        }
        postdata = JSON.stringify(postdata);
        if (!admin_caretaker_id) {
            return;
        }
        try {
            let uri = BE_REST_API + '/users/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("CREATE NEW USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionUser(JSON.stringify(resData));
                dispatch(addToUsersList(resData));
                if (isUserCaretaker(user) || isUserOwner(user) || isUserAdmin(user) || isUserBarRepresentative(user)) {
                    let caretaker = {
                        full_name: user.full_name,
                        user_id: resData.user_id,
                        parent_id: admin_caretaker_id,
                    }
                    await dispatch(createCaretaker(caretaker, () => {
                        console.log("Caretaker user successfully created!")
                    }, () => {
                        console.warn("Error creating new caretaker!")
                    }));
                }
                if (isUserBarRepresentative(user)) {
                    dispatch(setActiveBarRepresentative(resData)); //user
                } else if (isUserOwner(user)) {
                    dispatch(setActiveBarOwner(resData)); //user
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating user..', e);
        }
    };
};

export const createCaretaker = (caretaker, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            caretaker: caretaker
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/users/create/caretaker';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET CREATE CARETAKER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setLastCaretakerCreated(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading user..', e);
        }
    };
};

export const loadAllBars = (userId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            user_id: userId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/all';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET ALL BARS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionBars(JSON.stringify(resData));
                dispatch({type: LOAD_BARS, bars: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading all bars..', e);
        }
    };
};

export const loadCategories = (barId, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("GET CATEGORIES: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                setSessionCategories(JSON.stringify(resData));
                dispatch({type: LOAD_CATEGORIES, categories: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading combo offers..', e);
        }
    };
};

export const previewFile = (file_id, user_id, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/storage/file/download';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                responseType: "blob",
                body: postdata
            });
            console.log(response);
            let fileName;
            let mimeType;
            let resData;

            let headers = response.headers.get('content-disposition')
            fileName = headers.substring(headers.indexOf('filename=') + 'filename='.length).trim().replace(/^"(.+(?="$))"$/, '$1'); // remove quotes at the ends

            mimeType = response.headers.get('content-type');
            console.log(headers, response.headers.get('content-disposition'), fileName, mimeType)


            const contentLength = response.headers.get('content-length');
            const total = parseInt(contentLength, 10);
            let loaded = 0;
            let percentage = 0;

            const res = new Response(new ReadableStream({
                async start(controller) {
                    const reader = response.body.getReader();
                    let loadFilePercentage = 0;

                    dispatch(setDownloadProgress(file_id, loadFilePercentage));

                    for (; ;) {
                        const {done, value} = await reader.read();
                        if (done) break;
                        loaded += value.byteLength;
                        //progress({loaded, total})
                        loadFilePercentage = ((loaded / total) * 100).toFixed(0);

                        controller.enqueue(value);

                        dispatch(setDownloadProgress(file_id, loadFilePercentage));

                        console.log(loaded, total, loadFilePercentage);
                    }
                    controller.close();
                },
            }));
            const blob = await res.blob();

            console.log('download completed')


            // let blob = await response.blob();

            /*
            let resData = await response.json();
            console.log(resData);

            const str = JSON.stringify(resData);
            const bytes = new TextEncoder().encode(resData);
            const blob = new Blob([bytes], {
                type: "application/json;charset=utf-8"
            });
            console.log(blob);

             */
            let parsedMime = mimeType;
            if (parsedMime.includes('pdf')) {
                parsedMime = "application/pdf";
            } else if (parsedMime.includes('gif')) {
                parsedMime = "image/gif";
            } else if (parsedMime.includes('png')) {
                parsedMime = "image/png";
            } else if (parsedMime.includes('jpg') || parsedMime.includes('jpeg')) {
                parsedMime = "image/jpeg";
            } else if (parsedMime.includes('mp4')) {
                parsedMime = "video/mp4";
            } else if (parsedMime.includes('avi')) {
                parsedMime = "video/avi";
            } else if (parsedMime.includes('mov')) {
                parsedMime = "video/quicktime";
            } else if (parsedMime.includes('ogg')) {
                parsedMime = "video/ogg";
            } else if (parsedMime.includes('wmv')) {
                parsedMime = "video/x-ms-wmv";
            } else if (parsedMime.includes('wmv')) {
                parsedMime = "video/x-ms-wmv";
            } else if (parsedMime.includes('webm')) {
                parsedMime = "video/webm";
            } else if (parsedMime.includes('mp3')) {
                parsedMime = "video/mpeg";
            } else if (parsedMime.includes('doc')) {
                parsedMime = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            } else if (parsedMime.includes('rtf')) {
                parsedMime = "text/rtf";
            } else if (parsedMime.includes('webp')) {
                parsedMime = "image/webp";
            } else if (parsedMime.includes('svg')) {
                parsedMime = "image/svg+xml";
            } else if (parsedMime.includes('tiff')) {
                parsedMime = "image/tiff";
            } else if (parsedMime.includes('ico')) {
                parsedMime = "image/x-icon";
            } else if (parsedMime.includes('xml')) {
                parsedMime = "text/xml";
            } else if (parsedMime.includes('html')) {
                parsedMime = "text/html";
            } else if (parsedMime.includes('url')) {
                parsedMime = "url";
            }

            if (parsedMime === "url") {
                let urlFile = await blob.text();
                urlFile = urlFile.trim();
                let url = urlFile.substring(urlFile.lastIndexOf('URL=') + 4);
                url = url.trim();
                console.log("RAW FILE:", urlFile, "URL:", url);

                const openInNewTab = (url) => {
                    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
                    if (newWindow) newWindow.opener = null
                }
                openInNewTab(url);

            } else {
                //Create a Blob from the PDF Stream
                const file = new Blob([blob], {type: parsedMime});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;

                /*
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //with any browser supported extension
                document.body.appendChild(link);
                link.click();
                */
            }

            console.log("FILE PREVIEW [RESPONSE]: " + JSON.stringify("File (preview) successfully..", null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed previewing file', e);
        }
    };
};

export const sendFileToEmail = (file_id, user_id, username, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            file_id: file_id,
            username: username,
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/storage/file/send';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("SEND FILE [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(loadOnlineBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed sending file..', e);
        }
    };
};



/* PRODUCT actions */
export const loadProducts = (barId, user, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized, shouldParse) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata)
        try {
            let uri = BE_REST_API + '/products';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("GET PRODUCTS: " + JSON.stringify(resData, null, 4));

            let p = [];
            let parseCondition = parseUserRole(user.type) === USER_ROLE.OWNER || parseUserRole(user.type) === USER_ROLE.BAR_REPRESENTATIVE;
            shouldParse = shouldParse && parseCondition;
            const onDispatchData = async () => {

                if (!shouldParse) {
                    //setSessionProducts(JSON.stringify(resData));
                    dispatch({type: LOAD_PRODUCTS, products: resData});
                } else {
                    if (parseCondition) {
                        for (let i = 0; i < resData.length; i++) {
                            if (!resData[i].bar_id) {
                                continue;
                            }
                            p.push(resData[i]);
                        }
                        //setSessionProducts(JSON.stringify(p));
                        dispatch({type: LOAD_PRODUCTS, products: p});
                        console.log(p)

                    } else {
                        //setSessionProducts(JSON.stringify(resData));
                        dispatch({type: LOAD_PRODUCTS, products: resData});
                        console.log(resData)

                    }
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(!shouldParse ? resData : p), onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading products..', e);
        }
    };
};

export const loadProductsByCategory = (barId, category_label, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
            category_label: category_label
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/products/by/category';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log(`GET PRODUCTS BY CATEGORY [${category_label}]:` + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //setSessionProducts(JSON.stringify(resData));
                dispatch(setProductsByCategory(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading bars..', e);
        }
    };
};

export const createNewProduct = (product, isComboOffer, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let restCall = isComboOffer ? '/comboOffers/create' : '/products/create';
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata)
        try {
            let uri = BE_REST_API + restCall;
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("CREATE PRODUCT [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
                console.log("No need to write to app state, since get data is obtained after write to db operations");
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new product..', e);
        }
    };
};

export const updateProduct = (product, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata);

        console.log("PROD TO UPDATE", product);
        try {
            let uri = BE_REST_API + '/products/update';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE PRODUCT: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveProduct(resData));

                console.log("No need to write to app state, since get data is obtained after write to db operations");
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading product..', e);
        }
    };
};

export const updateProductExcel = (product, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/products/update/excel';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("UPDATE PRODUCT: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData.error), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading product..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                name: product.name,
                external_id: product.external_id,
            }))
        }
    };
};

export const updateProductActiveStatus = (product, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            product: product
        }
        postdata = JSON.stringify(postdata);
        console.log(postdata);
        try {
            let uri = BE_REST_API + '/products/update/active';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata

            });
            const resData = await response.json();
            console.log("UPDATE PRODUCT STATUS: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
                if (!product.active) {
                    dispatch(setActiveProduct(null));
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading product..', e);
        }
    };
};

export const updateBar = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar..', e);
        }
    };
};

export const updateBarExcel = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/excel';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            /*
            if (resData.error) {
                onErrorErrorFetchingData(resData.error);
            }
            */
            const onDispatchData = async () => {
                if (!resData.error) {
                    dispatch(setActiveBar(resData))
                }
            };
            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData.error), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                external_id: bar.external_id,
                name: bar.name
            }))
        }
    };
};

export const updateCategoryExcel = (category, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            category: category
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories/category/update/excel';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            //console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            /*
            if (resData.error) {
                onErrorErrorFetchingData(resData.error);
            }
            */

            const onDispatchData = async () => {
                if (!resData.error) {
                    //dispatch(setActiveBar(resData))
                }
            };

            parseRestApiResponse(response, resData, onDispatchData, () => onSuccessAction(resData), () => onErrorErrorFetchingData(resData.error), onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating category..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                name: category.name,
            }))
        }
    };
};


export const createNewCategory = (barId, category, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar_id: barId,
            name: category.name,
            position: category.position,
            eng_name: category.eng_name,
            de_name: category.de_name,
            it_name: category.it_name,
            hr_name: category.hr_name
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/categories/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("CREATE CATEGORY [RESPONSE]: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating new name..', e);
        }
    };
};

export const updateCategory = (category, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            category: category
        }
        postdata = JSON.stringify(postdata);

        console.log("CAT TO UPDATE", category);
        try {
            let uri = BE_REST_API + '/categories/category/update';
            const response = await fetch(uri, {
                "method": 'POST',
                "headers": {
                    "Accept": "*/*",
                    "Content-Type": "application/json",
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE CATEGORY: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                dispatch(setActiveCategory(resData));
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed loading category..', e);
        }
    };
};

export const createNewBar = (bar, admin_email, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar,
            admin_email: admin_email,
        }
        try {
            postdata = JSON.stringify(postdata);
            console.log(postdata)
        } catch (e) {
            console.log(e)
        }
        try {
            let uri = BE_REST_API + '/bars/create';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            //console.log("CREATE BAR: " + JSON.stringify(resData.bar_id + ' ' + resData.external_id + ' ' + resData.name, null, 4));

            if (resData.error) {
                onErrorErrorFetchingData(resData.error);
            }
            const onDispatchData = async () => {
                //dispatch({type: ADD_NEW_CATEGORY, name: resData});
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed creating bar..', e);
            dispatch(updateImportErrors({
                error: 'Napaka, napačen zapis podatkov',
                external_id: bar.external_id,
                name: bar.name
            }))
        }
    };
};


export const updateBarUser = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/user';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE BAR USER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar user..', e);
        }
    };
};

export const updateBarOwner = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/owner';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                },
                body: postdata
            });
            const resData = await response.json();
            console.log("UPDATE BAR OWNER: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar owner..', e);
        }
    };
};


export const updateBarActiveStatus = (bar, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized) => {
    return async dispatch => {
        let postdata = {
            bar: bar
        }
        postdata = JSON.stringify(postdata);
        try {
            let uri = BE_REST_API + '/bars/update/active';
            const response = await fetch(uri, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    postData: postdata
                },
            });
            const resData = await response.json();
            console.log("UPDATE BAR: " + JSON.stringify(resData, null, 4));

            const onDispatchData = async () => {
                //dispatch(setActiveBar(resData))
            };
            parseRestApiResponse(response, resData, onDispatchData, onSuccessAction, onErrorErrorFetchingData, onErrorUnauthorized);

        } catch (e) {
            console.warn('Failed updating bar..', e);
        }
    };
};
