import React, {useEffect, useState} from 'react';
import '../css/DataSquare.css';
import driverApi from "../../apis/driverApi";
import taxiApi from "../../apis/taxiApi";
import {TAXI_ORDER_STATUS} from "../../constants/enums"; // Assuming you have a CSS file for styling

export const formatCurrency = (amount) => `${parseFloat(amount).toFixed(2)}€`;

export const renderDriversListDataSquares = (l10nText, activeDrivers, totalDrivers, onlinePercentage, completedDrives, amountEarnedToday) => {

    return (
        <div className="data-squares-row">
            <DataSquare value={`${activeDrivers}/${totalDrivers}`} label={l10nText.active || "Active"} />
            <DataSquare value={`${onlinePercentage}%`} label={l10nText.online || "Online/Offline"} />
            <DataSquare value={completedDrives} label={l10nText.completed_drives || "Today's Completed Drives"} />
            <DataSquare value={formatCurrency(amountEarnedToday)} label={l10nText.earned_today || "Earned Today"} />
        </div>
    );
};

export const renderDriversEarningsListDataSquares = (l10nText, style, todaysEarnings, weeklyEarnings, monthlyEarnings, totalEarnings) => {

    return (
        <div className="data-squares-row" style={{...style}}>
            <DataSquare value={formatCurrency(todaysEarnings)} label={l10nText.todays_earnings || "Today's Earnings"} />
            <DataSquare value={formatCurrency(weeklyEarnings)} label={l10nText.weekly_earnings || "Weekly Earnings"} />
            <DataSquare value={formatCurrency(monthlyEarnings)} label={l10nText.monthly_earnings || "Monthly Earnings"} />
            <DataSquare value={formatCurrency(totalEarnings)} label={l10nText.total_earnings || "Total Earnings"} />
        </div>
    );
};

export const renderDashboardTaxiDataSquares = (l10nText, style, minimumActiveDrivers, activeDrivers, totalRegisteredDrivers, completedRidesToday) => {

    return (
        <div className="data-squares-row" style={{...style}}>
            <DataSquare value={minimumActiveDrivers}
                        label={l10nText.minimum_active_drivers || "Minimum active Drivers"}/>
            <DataSquare value={activeDrivers} label={l10nText.active_drivers || "Active Drivers"}/>
            <DataSquare value={totalRegisteredDrivers}
                        label={l10nText.total_registered_drivers || "Total Nmb. Of Drivers"}/>
            <DataSquare value={completedRidesToday} label={l10nText.completed_rides_today || "Completed Rides Today"}/>
        </div>
    );
};

const DataSquare = ({ value, label }) => {
    return (
        <div className="data-square">
            <div className="data-value">{value}</div>
            <div className="data-label">{label}</div>
        </div>
    );
};



export default DataSquare;
