// createSlice
import { createSlice } from '@reduxjs/toolkit';
import {SIDEBAR_WIDTH} from "../../components/js/Page";

const _clearState = (state) => ({
    ...state,
    showSidebar: true,
    sidebarWidth: SIDEBAR_WIDTH,
});

const defaultState = {
    showSidebar: true,
    sidebarWidth: SIDEBAR_WIDTH,
};

const adminSlice = createSlice({
    name: "admin",
    initialState: defaultState,
    reducers: {
        clearState: (state) => {
            return _clearState(state);
        },
        setShowSidebar: (state, action) => {
            state.showSidebar = action.payload;
        },
        setSidebarWidth: (state, action) => {
            state.sidebarWidth = action.payload;
        },
    }
});

const { actions, reducer } = adminSlice;

export const {
    clearState,
    setShowSidebar,
    setSidebarWidth, // Export the new action
} = actions;

export default reducer;