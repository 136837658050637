import React from "react";

import '../css/Reviews.css';
import Review from "./Review";
function Reviews(props) {
    const reviews = props.reviews;
    let leftReviews = [];
    let rightReviews = [];

    for (let i = 0; i < reviews.length; i++) {
        if (i % 2 === 0) {
            leftReviews.push(reviews[i])
        } else {
            rightReviews.push(reviews[i])
        }
    }

    return <div className={"reviews-list"} style={props.style}>
        <div className="row">
            <div className={"column reviews-column"}>
                {leftReviews.map((review, index) => {
                    return <Review key={index} review={review}/>
                })}
            </div>
            <div className={"column reviews-column"}>
                {rightReviews.map((review, index) => {
                    return <Review key={index} review={review}/>
                })}
            </div>
        </div>
       
    </div>
}

export default Reviews;