import React, { useState, useEffect } from 'react';
import DynamicTable from '../DynamicTable';
import { APP_SERVICES, DELIVERY_ORDER_STATUS } from "../../../constants/enums";
import deliveryApi from "../../../apis/deliveryApi";
import deliveryDriverApi from "../../../apis/deliveryDriverApi";
import { getColorByValue } from "../../../lib/dataParsingHelpers";

const DeliveryOrdersOverwatch = () => {
    const [deliveryOrdersList, setDeliveryOrdersList] = useState([]);
    const [filteredDeliveryOrders, setFilteredDeliveryOrders] = useState([]);
    const [completedOrdersToday, setCompletedOrdersToday] = useState(0);
    const [totalDailyEarnings, setTotalDailyEarnings] = useState(0);

    const fetchDeliveryOrders = async () => {
        try {
            let orders = await deliveryApi.getDeliveryOrders();

            if (orders && !orders.error) {
                orders = orders.filter(order => !order?.is_daily_meal);

                const ordersWithDriverData = await Promise.all(
                    orders.map(async (order) => {
                        if (order.delivery_driver?.user_id) {
                            const driverData = await deliveryDriverApi.getDeliveryDriverByUserId(order.delivery_driver.user_id);
                            if (driverData) {
                                return {
                                    ...order,
                                    type: APP_SERVICES.DELIVERY,
                                    delivery_driver: {
                                        ...order.delivery_driver,
                                        user: driverData.user,
                                        vehicles: driverData.vehicles,
                                    },
                                };
                            }
                        }
                        return { ...order, type: APP_SERVICES.DELIVERY };
                    })
                );

                setDeliveryOrdersList(ordersWithDriverData);
                setFilteredDeliveryOrders(ordersWithDriverData);

                const today = new Date();
                const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);

                const completedTodayOrders = orders.filter(order => {
                    const completedTimeline = order.timeline.find(event => event.status === DELIVERY_ORDER_STATUS.COMPLETED);
                    if (completedTimeline) {
                        const completedDate = new Date(completedTimeline.location.timestamp);
                        return completedDate >= startOfDay && completedDate < endOfDay;
                    }
                    return false;
                });

                const totalPrice = completedTodayOrders.reduce((acc, order) => {
                    const deliveryCost = parseFloat(order.details?.delivery_cost || 0);
                    const deliveryEarnings = parseFloat(order.details?.delivery_earnings || 0);
                    return acc + deliveryCost + deliveryEarnings;
                }, 0);

                setTotalDailyEarnings(totalPrice);
                setCompletedOrdersToday(completedTodayOrders.length);
            }
        } catch (e) {
            console.error('Error fetching delivery orders:', e);
        }
    };

    useEffect(() => {
        fetchDeliveryOrders();
        const intervalId = setInterval(fetchDeliveryOrders, 10000);
        return () => clearInterval(intervalId);
    }, []);

    const columnsConfig = {
        order_id: 'Order ID',
        driver_name: 'Driver',
        vehicle: 'Vehicle',
        vehicle_color: 'Vehicle Color',
        status: 'Status',
        created_at: 'Created',
        pickup_location: 'Pickup Location',
        delivery_location: 'Delivery Location',
    };

    const parseOrderData = (orders) =>
        orders.map((order) => ({
            order_id: order.order_id.slice(0, 6),
            driver_name: `${order?.delivery_driver?.user?.first_name ?? 'N/A'} ${order?.delivery_driver?.user?.last_name ?? ''}`,
            vehicle: `${order?.delivery_driver?.vehicles[0]?.make ?? 'N/A'} ${order?.delivery_driver?.vehicles[0]?.model ?? 'N/A'}`,
            vehicle_color: getColorByValue(order?.delivery_driver?.vehicles[0]?.color),
            status: order.status,
            created_at: new Date(order.created_at).toLocaleString('sl-SI', { timeZone: 'Europe/Ljubljana' }),
            pickup_location: order?.pickup_location?.address ?? 'N/A',
            delivery_location: order?.delivery_location?.address ?? 'N/A',
        }));

    return (
        <div>
            <h3>Pending Delivery Orders</h3>
            <DynamicTable
                data={parseOrderData(deliveryOrdersList.filter(order => order.status === DELIVERY_ORDER_STATUS.PENDING))}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />

            <h3>Active Delivery Orders</h3>
            <DynamicTable
                data={parseOrderData(
                    deliveryOrdersList.filter(
                        order =>
                            order.status !== DELIVERY_ORDER_STATUS.PENDING &&
                            order.status !== DELIVERY_ORDER_STATUS.CANCELED &&
                            order.status !== DELIVERY_ORDER_STATUS.REJECTED &&
                            order.status !== DELIVERY_ORDER_STATUS.COMPLETED &&
                            order.status !== DELIVERY_ORDER_STATUS.ARRIVED &&
                            order.status !== DELIVERY_ORDER_STATUS.DELIVERED
                    )
                )}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />

            <h3>Completed Delivery Orders</h3>
            <DynamicTable
                data={parseOrderData(
                    deliveryOrdersList.filter(order => order.status === DELIVERY_ORDER_STATUS.COMPLETED)
                )}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />

            <h3>Canceled or Rejected Delivery Orders</h3>
            <DynamicTable
                data={parseOrderData(
                    deliveryOrdersList.filter(
                        order =>
                            order.status === DELIVERY_ORDER_STATUS.CANCELED ||
                            order.status === DELIVERY_ORDER_STATUS.REJECTED
                    )
                )}
                columnsConfig={columnsConfig}
                rowsPerPage={5}
            />
        </div>
    );
};

export default DeliveryOrdersOverwatch;
