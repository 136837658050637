import React from "react";
import {getColorByValue} from "./dataParsingHelpers";

export const getExpirationStatus = (date) => {
    const currentDate = new Date();
    const expirationDate = new Date(date);

    const differenceInDays = Math.ceil((expirationDate - currentDate) / (1000 * 60 * 60 * 24));

    if (differenceInDays > 30) {
        return 'valid'; // Green
    } else if (differenceInDays <= 30 && differenceInDays > 5) {
        return 'expiring-soon'; // Yellow
    } else if (differenceInDays <= 5 && differenceInDays >= 0) {
        return 'expiring-very-soon'; // Orange
    } else {
        return 'expired'; // Red
    }
};

export const renderTaxiOrderExtendedContent = (order) => {
    return (
        <div className="order-extended-content" style={{display: 'flex', justifyContent: 'space-between'}}>
            {/* Left Column */}
            <div style={{flex: 1, paddingRight: '20px'}}>
                <strong>Order details:</strong>

                <div style={{padding: '12px 0'}}>
                    <div><strong>Driver:</strong> {order.driver_name} | {order.driver?.user?.telephone}</div>
                    <div><strong>Vehicle:</strong> {order.vehicle} | [{order.driver?.vehicles[0]?.license_plate}]</div>

                    <div style={{padding: '8px 0'}}>
                        {order.route.map((point, index) => (
                            <div key={index}>
                                {index === 0 && <div><strong>Pickup Location:</strong> {point.address}</div>}
                                {index > 0 && index < order.route.length - 1 && order.route.length > 2 && (
                                    <div><strong>Stop {index}:</strong> {point.address}</div>
                                )}
                                {index === order.route.length - 1 &&
                                    <div><strong>Destination Location:</strong> {point.address}</div>}
                            </div>
                        ))}
                    </div>
                    <div>
                        <strong>Passenger:</strong> {order.user.first_name} {order.user.last_name} | {order.user.telephone}
                    </div>
                    <div><strong>Payment:</strong> <strong>{order.payment?.price}€</strong> via {order.payment?.type}
                    </div>
                </div>

                <div style={{padding: '8px 0'}}>
                    <strong>Ride Preferences:</strong>
                    <div>Adults: <strong>{order.preferences?.adults || 0}</strong></div>
                    <div>Children (above 140 cm): <strong>{order.preferences?.children_above_140 || 0}</strong></div>
                    <div>Children (under 140 cm): <strong>{order.preferences?.children_under_140 || 0}</strong></div>
                    {order?.scheduled_order && (
                        <>
                            <div>Departure Date: {order.preferences?.departure_date || 'N/A'}</div>
                            <div>Departure Time: {order.preferences?.departure_time || 'N/A'}</div>
                        </>
                    )}
                </div>

                <div style={{padding: '8px 0'}}>
                    <strong>Ride Requirements:</strong>
                    <div>Air conditioning: {order.preferences?.ride_requirements?.air_conditioning ?
                        <strong>Yes</strong> : 'No'}</div>
                    <div>Child seats: {order.preferences?.ride_requirements?.child_seats ?
                        <strong>Yes</strong> : 'No'}</div>
                    <div>Luggage: {order.preferences?.ride_requirements?.luggage ? <strong>Yes</strong> : 'No'}</div>
                    <div>Quiet ride: {order.preferences?.ride_requirements?.quiet_ride ?
                        <strong>'Yes'</strong> : 'No'}</div>
                    <div>Traveling with a pet: {order.preferences?.ride_requirements?.traveling_with_pet ?
                        <strong>Yes</strong> : 'No'}</div>
                    <div>Wheelchair accessibility: {order.preferences?.ride_requirements?.wheelchair_accessibility ?
                        <strong>Yes</strong> : 'No'}</div>
                </div>
            </div>

            {/* Right Column */}
            <div style={{flex: 1, paddingLeft: '24px', borderLeft: '1px solid #ccc'}}>
                <div style={{padding: '0px 0'}}>
                    <strong>Order Timeline:</strong>
                    <ul style={{listStyleType: 'none', paddingLeft: '0'}}>
                        {order.timeline.map((event, index) => (
                            <li key={index} style={{marginBottom: '6px'}}>
                                <div style={{fontSize: "0.93em"}}><strong>{event.status}:</strong></div>
                                <div>{event.location?.address || 'Location not available'}</div>
                                <div>{new Date(event.location.timestamp).toLocaleString('sl-SI', {timeZone: 'Europe/Ljubljana'})}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};


export const renderCellContent = (key, value) => {
    if (key === 'is_scheduled' && value === true) {
        return <span className="scheduled-tag">Scheduled</span>;
    }
    if (key.includes("_id")) {
        return value.slice(0, 6) + "...";
    }
    if (key.endsWith('_expiration_date')) {
        const status = getExpirationStatus(value);
        return <span className={`status-dot ${status}`}/>;
    } else if (key.toLowerCase().includes('telephone') || key.toLowerCase().includes('phone_number')) {
        return <a href={`tel:${value}`} style={{color: 'inherit', textDecoration: 'none'}}>{value}</a>;
    } else if (key === 'is_online') {
        return <span className={`status-dot ${value ? 'online' : 'offline'}`}/>;
    } else if (key === 'vehicle_color') {
        const colorValue = getColorByValue(value);
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '10%',
                        backgroundColor: colorValue,
                        marginRight: '8px',
                        border: "1px solid black"
                    }}
                />
                {value}
            </div>
        );
    } else {
        return value;
    }
};