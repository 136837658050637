import React, {createContext, useContext, useEffect, useState} from 'react';
import io from 'socket.io-client';
import {useSelector, useDispatch} from 'react-redux';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({children}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
   
    const [socket, setSocket] = useState(null);
    const token = user && user.access_token ? user.access_token : null;

    useEffect(() => {
        const newSocket = io(SOCKET_URL, {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
        newSocket.on('connect', () => {
            console.log('connected to socket server');
        });

        newSocket.on('disconnect', () => {
            console.log('disconnected from socket server');
        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, [token]);

    const joinRoom = (roomName) => {
        if (socket) {
            socket.emit('joinRoom', roomName);
        }
    };

    const leaveRoom = (roomName) => {
        if (socket) {
            socket.emit('leaveRoom', roomName);
        }
    };

    return (
        <SocketContext.Provider value={{socket, joinRoom, leaveRoom}}>
            {children}
        </SocketContext.Provider>
    );
};